/**
 * Checks if a given URL is a valid HTTPS URL.
 *
 * @param {string} url - The URL to be checked.
 * @return {boolean} Returns true if the URL is a valid HTTPS URL, false otherwise.
 */
function isValidHttpsUrl(url) {
    try {
        // Create a new URL object
        let parsedUrl = new URL(url);

        // Check if the protocol is 'https:'
        if (parsedUrl.protocol === 'https:') {
            return true;
        } else {
            return false;
        }
    } catch (error) {
        // If the URL constructor throws an error, the URL is invalid
        return false;
    }
}

export default isValidHttpsUrl