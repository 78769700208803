import { Card, Typography } from "antd";

export default function ComplaintItem({data}) {
    return (
        <Card size='small'
            className="complaint-item"
            title={data.ip}
        >
            <Typography
                className="message"
            >{data.message}</Typography>
            <Typography className="date">{data.at.formatted}</Typography>
        </Card>
    )
}