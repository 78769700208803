import conf from "conf";
import HomeContainer from "element/HomeContainer";
import { Helmet } from "react-helmet";
import './index.scss'
import { Alert, Button, Card, Checkbox, Input, Modal, Radio, Typography, message } from "antd";
import { useEffect, useState } from "react";
import request from "utils/request";
import SuccessAlert from "./SuccessAlert";

export default function Aduan() {
    const [errors, setErrors] = useState({})
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState('')
    const [error, setError] = useState(null)
    const [successAlertOpen, setSuccessAlertOpen] = useState(false)

    const process = () => {
        setErrors({})
        setLoading(true)
        request('/complaint/create', 'POST', { message }).then((data) => {
            setErrors(data.errors)
            if(Object.keys(data.errors).length === 0) {
                setSuccessAlertOpen(true)
            }
        }).catch(err => setError(err?.toString()))
            .finally(() => setLoading(false))
    }

    return (
        <HomeContainer
            name='aduan'
        >
            <SuccessAlert 
                open={successAlertOpen}
                onClose={() => setSuccessAlertOpen(false)}
            />
            <Modal
                centered
                width={350}
                title='Error'
                open={error !== null}
                onOk={() => setError(null)}
                onCancel={() => setError(null)}
                okText='Tutup'
                cancelButtonProps={{
                    style: {
                        display: 'none'
                    }
                }}
            >
                <Typography>{error}</Typography>
            </Modal>
            <Helmet>
                <title>Kritik dan Saran Pelanggan - {conf.appName}</title>
            </Helmet>
            <div className="top">
                <Typography className="title">{conf.appName} Mengecewakan?<br />Laporkan di sini!</Typography>
                <Typography className="subtitle">Kami sangat membutuhkan kirik, saran, dan masukan dari Anda! Laporkan kekecewaan Anda di sini.</Typography>
            </div>
            <div className="bottom">
                <Card className="form-card" title={(
                    <div className="title-card">
                        <Alert type='warning'
                            showIcon
                            message='Data diri Anda sebagai anonim'
                        />
                    </div>
                )}>
                    <div className="forms">
                        <div className="form-control">
                            <Typography className="title">Kritik/Saran</Typography>
                            <Input.TextArea
                                value={message}
                                onChange={e => setMessage(e.target.value)}
                                autoSize={{
                                    minRows: 6,
                                    maxRows: 10
                                }}
                                showCount
                                count={message.trim().length}
                            />
                            {errors?.message && (
                                <Typography className="error-msg">{errors?.message}</Typography>
                            )}
                        </div>
                        <Button
                            type='primary'
                            className="send-btn"
                            disabled={loading}
                            onClick={process}
                        >Kirim</Button>
                    </div>
                </Card>
            </div>
        </HomeContainer>
    )
}