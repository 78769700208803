import { Alert, Button, Card, Checkbox, Input, Radio, Select, Typography } from "antd";
import conf from "conf";
import MainAppContainer from "element/MainAppContainer";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import './index.scss'
import OrderAutoForm from './OrderAutoForm'
import OrderCustomForm from "./OrderCustomForm";
import { useLocation, useNavigate } from "react-router";
import { decode } from "urlencode";
import request from "utils/request";
import HasAddressAlert from "./HasAddressAlert";
import SuccessOrderAlert from "./SuccessOrderAlert";
import FormData from 'form-data'
import rupiah from "utils/rupiah";
import HasPhoneAlert from "./HasPhoneAlert";


let voucerCodeTimeout

export default function NewOrder() {
    const [type, setType] = useState('auto') // auto | custom
    const [loading, setLoading] = useState(false)
    const [itemId, setItemId] = useState(null)
    const [url, setUrl] = useState(null)
    const [quantity, setQuantity] = useState(1)
    const [images, setImages] = useState([])
    const [productName, setProductName] = useState('')
    const [props, setProps] = useState([])
    const [error, setError] = useState(false)
    const [hasOrderAlert, setHasOrderAlert] = useState(false)
    const [note, setNote] = useState('')
    const [addressId, setAddressid] = useState(null)
    const [addresses, setAddresses] = useState([])
    const [errorMsg, setErrorMsg] = useState(false)
    const [currentInvoiceCode, setCurrentInvoiceCode] = useState(null)
    const [isPrivate, setIsPrivate] = useState(false)
    const [file, setFile] = useState(null)
    const [hargaTokped, setHargaTokped] = useState(0)
    const [voucerCode, setVoucerCode] = useState(null)
    const [voucerCodeValid, setVoucerCodeValid] = useState(null) 
    const [voucerCodeMessage, setVoucerCodeMessage] = useState('')
    const [repacking, setRepacking] = useState(false)
    const [hasPhoneAlertOpen, setHasPhoneAlertOpen] = useState(false)

    useEffect(() => {
        if(repacking) {
            const addr = addresses.find(x => x.type === 'WAREHOUSE')
            if(addr) { setAddressid(addr.id) }
        }
    }, [repacking])

    useEffect(() => {
        if(voucerCodeTimeout) { clearTimeout(voucerCodeTimeout) }

        voucerCodeTimeout = setTimeout(() => {
            if(voucerCode === null) { setVoucerCodeValid(null) } else {
                request('/voucer/valid', 'POST',{
                    code: voucerCode
                }).then((data) => {
                    setVoucerCodeValid(data.valid)
                    setVoucerCodeMessage(data.msg)
                }).catch(err => {
                    console.error(err.message || err)
                    setVoucerCodeValid(null)
                    setVoucerCodeMessage('')
                })
            }
        }, 1000);
    }, [voucerCode])


    useEffect(() => {
        request('/app/isPrivate').then(d => setIsPrivate(d)).catch(err => { console.error(err.message || err) })
    }, [])
    
    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        const search = new URLSearchParams(location.search)
        if(search.has('type')) {
            setType(search.get('type'))
            search.delete('type')
            navigate('/new-order?' + search.toString())
        }
    }, [location])

    useEffect(() => { // Validate the error 
        // make sure the props is not have a empty value may value or name property
        if(props.findIndex(x => x.name.trim().length < 1) >= 0 || props.findIndex(x => x.value.trim().length < 1) >= 0) {
            return setError('Terdapat properti yang kosong di dalam varian')
        }

        // Make sure the quantity is can parse to the integer
        const q = parseInt(quantity)
        if(isNaN(q) || q < 1) {
            return setError('Jumlah varian harus lebih dari 0')
        }
        // Make sure the url is valid
        if(type === 'auto' && !itemId) {
            return setError('Item ID tidak boleh kosong')
        }
        if(type === 'custom' && !url) {
            return setError('URL tidak boleh kosong')
        }
        if(productName.trim().length < 1) {
            return setError('Nama produk tidak boleh kosong')
        }
        
        if(addressId === null) {
            return setError('Silahkan pilih alamat pengiriman')
        }

        if(isPrivate && !file) {
            return setError('Pilih file produk terlebih dahulu')
        }
        
        if(hargaTokped <= 0 && isPrivate) {
            return setError('Silahkan isi harga tokped terlebih dahulu')
        }

        setError(false)
    }, [props, productName, quantity, url, itemId, type, addressId, file, hargaTokped, isPrivate])
    
    useEffect(() => {
        setUrl(null)
        setItemId(null)
        setImages([])
        setProductName('')
        setProps([])
        setQuantity(1)
    }, [type])

    useEffect(() => {
        request('/user/has-address').then(({hasAddress}) => {
            setHasOrderAlert(!hasAddress)
            if(hasAddress) {
                request('/user/hasPhone').then(state => {
                    setHasPhoneAlertOpen(!state)
                }).catch(err => console.error(err.message || err))
            }
        }).catch(err => console.error(err.message || err))

        request('/user/addresses').then(data => {
            setAddresses(data)

            if(data[0]) {
                setAddressid(data[0].id)
            }
        }).catch(err => console.error(`Failed for get the addresses: ${err.message || err}`))
        
        return () => {
            setAddressid(null)
            setAddresses([])    
        }
    }, [])

    // Make order
    const makeOrder = () => {
        setLoading(true)
        setErrorMsg(false)
        const fd = new FormData()
        fd.append('itemid', itemId)
        fd.append('quantity', parseInt(quantity))
        fd.append('props', JSON.stringify(props))
        fd.append('addressId', addressId)
        fd.append('note', note.trim())
        fd.append('url', url)
        fd.append('type', type)
        fd.append('productName', productName)
        fd.append('productImage', file)
        fd.append('priceInTokped', hargaTokped)
        fd.append('voucerCode', voucerCode ? voucerCode : '')
        fd.append('repacking', repacking ? 1 : 0)

        request('/order/make', 'POST', fd).then(data => {
            setCurrentInvoiceCode(data)
        }).catch(err => {
            setErrorMsg(err.message || err)
        }).finally(() => setLoading(false))
    }


    return (
        <MainAppContainer name='new-order'>
            {/* <Alert style={{
                marginBottom: '20px'
            }} message="Karena adanya redline dari pemerintah, maka orderan yang sudah terproses akan mengalami keterlambatan pengiriman." type='warning' showIcon /> */}
            <HasAddressAlert 
                open={hasOrderAlert}
            />
            <HasPhoneAlert 
                open={hasPhoneAlertOpen}
            />
            <SuccessOrderAlert 
                invoiceCode={currentInvoiceCode}
                onClose={() => setCurrentInvoiceCode(null)}
            />
            <Helmet>
                <title>{conf.appName} - Pembelian Baru</title>
            </Helmet>
            {errorMsg && (
                <Alert
                    type='error'
                    message={errorMsg}
                    showIcon
                    style={{
                        marginBottom: '20px'
                    }}
                />
            )}
            <Radio.Group value={type} onChange={e => setType(e.target.value)}>
                <Radio value='auto'>Otomatis</Radio>
                <Radio value='custom'>Custom</Radio>
            </Radio.Group>
            {type === 'auto' && <OrderAutoForm
                onItemId={itemId => setItemId(itemId)}
                onQuantity={q => setQuantity(q)}
                onImages={images => setImages(images)}
                onProductName={name => setProductName(name)}
                onProps={props => setProps(props)}
            />}
            {type === 'custom' && (
                <OrderCustomForm 
                    onUrl={url => setUrl(url)}
                    onProps={props => setProps(props)}
                    onQuantity={(q) => setQuantity(q)}
                    onProductName={(name) => setProductName(name)}
                />
            )}

            <div className="footer-actions">
                <div className="start-side">
                    <div className="form-control note">
                        <Typography className="title">Catatan</Typography>
                        <Input.TextArea className="note-field"
                            placeholder='___'
                            value={note}
                            autoSize={{
                                maxRows: 10,
                                minRows: 7
                            }}
                            onChange={e => setNote(e.target.value)}
                        >

                        </Input.TextArea>
                    </div>
                </div>
                <div className="end-side">
                    <div className="form-control select-address-container">
                        <Typography className="title">Alamat Pengiriman</Typography>
                        <Select value={addressId} className="select-address"
                            onChange={e => setAddressid(e)}
                        >
                            {addresses.map((address, i) => (
                                <Select.Option key={i} value={address.id}>{address.name}</Select.Option>
                            ))}
                        </Select>
                    </div>
                    {isPrivate && (
                        <div className="form-control select-address-container">
                            <Typography className="title">Gambar Produk</Typography>
                            <Input 
                                type='file'
                                className="select-product-image"
                                onChange={e => setFile(e.target.files[0] || null)}
                            />
                        </div>
                    )}
                    {isPrivate && (
                        <div className="form-control select-address-container" style={{
                            marginTop: "-10px"
                        }}>
                            <Typography className="title">Harga Tokped</Typography>
                            <Input 
                                type='text'
                                value={rupiah.encode(hargaTokped)}
                                onChange={e => {
                                    const v = parseInt(rupiah.decode(e.target.value.split('.').join('')))
                                    setHargaTokped(isNaN(v) ? 0 : v)
                                }}
                            />
                        </div>
                    )}
                    <div className="form-control select-address-container">
                        <Typography className="title">Kode Voucer</Typography>
                        <Input 
                            type='text'
                            value={voucerCode ? voucerCode : ''}
                            onChange={e => {
                                const v = e.target.value.toUpperCase().trim()
                                setVoucerCode(v.length < 1 ? null : v)
                            }}
                        />
                        {voucerCodeValid !== null && (
                            <Typography className="message" style={{
                                fontSize: '11px',
                                color: voucerCodeValid ? 'green' :'red'
                            }}>{voucerCodeMessage}</Typography>
                        )}
                    </div>
                    <div className="form-control select-address-container">
                        <Checkbox
                            checked={repacking}
                            onChange={e => setRepacking(e.target.checked)}
                            className="repacking-check"
                        >Repacking</Checkbox>
                    </div>
                    <Button className="make-order-btn"
                        size='large'
                        type='primary'
                        loading={loading}
                        disabled={typeof error === 'string'}
                        onClick={makeOrder}
                    >Buat Pesanan</Button>
                    {error && (
                        <Typography className="error-msg"
                            type='danger'
                        >{error}</Typography>
                    )}
                </div>
            </div>
        </MainAppContainer>
    )
}