import HomeContainer from "element/HomeContainer";
import { Collapse, Typography, theme } from 'antd'
import conf from "conf";
import './index.scss'
import { useEffect, useState } from 'react'
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { useLocation, useNavigate } from "react-router";
import { Helmet } from "react-helmet";



export default function FAQ() {
    const [panelStyle, setPanelStyle] = useState({})
    const token = theme.useToken()
    const [active, setActive] = useState(undefined)
    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        const search = new URLSearchParams(window.location.search)
        if(search.has('c')) { setActive(search.get('c')) }
    }, [])
    
    useEffect(() => {
        if(active) {
            const search = new URLSearchParams(location.search)
            search.set('c', active)
            navigate(window.location.pathname + '?' + search.toString())
        }
    }, [active])    

    useEffect(() => {
        const panelStyle = {
            marginBottom: 24,
            // background: token.colorFillAlter,
            borderRadius: '15px',
            border: 'none',
            background: '#ECF6FF'
        }
        setPanelStyle(panelStyle)
    }, [])

    
    return (
        <HomeContainer name='faq'>
            <Helmet>
                <title>FAQ - {conf.appName}</title>
            </Helmet>
            <div className="faq-head">
                <div className="container">
                    <Typography className="title">Frequently Asked Question</Typography>
                    <Typography className="desc">Semua tentang hal-hal dasar yang akan membantu Anda untuk memahami dan memanfaatkan layanan yang ditawarkan oleh <b>{conf.appName}</b>.</Typography>
                </div>
            </div>

            <div className="faq-body">
                <Collapse
                    activeKey={active ? [active] : []}
                    bordered={false}
                    expandIcon={({isActive}) => <ArrowRightIcon style={{
                        transform: isActive ? 'rotate(90deg)' : '',
                        fontSize: '30px'
                    }} />}
                    onChange={e => {
                        setActive(e.length > 0 ? e.pop() : undefined)
                    }}
                    className="collapse"
                    items={[
                        {
                            key: "working-hours",
                            label: <Typography className="collapse-title">Jam Kerja</Typography>,
                            children: <Typography className="collapse-text">
                                Kami menjalankan jam kerja dari pukul 8.30 pagi hingga 4.00 sore setiap <b>Senin</b> hingga <b>Jumat</b>. Kami berkomitmen untuk memberikan pelayanan terbaik di dalam jam kerja ini.
                                <br />
                                <br />
                                Harap diperhatikan bahwa kami tutup pada <b>hari libur</b> nasional dan tanggal merah. Ini termasuk libur nasional resmi di mana kantor kami tidak dapat beroperasi. Kami berterimakasih atas pengertian dan dukungan Anda.
                            </Typography>,
                            style: panelStyle
                        },
                    ]}
                />
            </div>
        </HomeContainer>
    )
}