import { Button, Empty, Input, Typography } from "antd";
import conf from "conf";
import MainAppContainer from "element/MainAppContainer";
import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import EmptyConversation from "./EmptyConversation";
import './index.scss'
import NewConversation from "element/NewConversation";
import request from "utils/request";
import ConversationItem from './ConversationItem'
import AddCommentOutlinedIcon from '@mui/icons-material/AddCommentOutlined';
import ChatData from './Chat'
import { io } from "socket.io-client";
import cookies from 'react-cookies'
import AvailableCheck from './AvailableCheck'
import { Navigate, useLocation, useNavigate } from "react-router";
let socket

let searchTimeout

export default function Chat({data}) {
    const [conversations, setConversations] = useState([]) 
    const [newConversationOpen, setNewConversationOpen] = useState(false)
    const [conversationsPage, setConversationsPage] = useState(1)
    const [search, setSearch] = useState('')
    const [active, setActive] = useState(undefined)
    const [hasMore, setHasMore] = useState(false)
    const [loading, setLoading] = useState(false)
    const [isAdmin, setIsAdmin] = useState(false)

    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        const search = new URLSearchParams(location.search)
        if(search.has('i')) {
            const v = search.get('i')
            const vl = parseInt(v)
            if(!isNaN(vl)) { setActive(vl) }
            search.delete('i')
            navigate('/chat?' + search.toString())
        }
    }, [location])

    useEffect(() => {
        request('/user/isAdmin').then(data => {
            setIsAdmin(data)
        }).catch(err => console.error(err.message || err))
    }, [])

    useEffect(() => {
        loadConversations()
    }, [conversationsPage])

    useEffect(() => {
        if(!socket) {return}
        socket.on('update-conversation', conf => {
            let _ = [...conversations]
            const i = _.findIndex(x => x.id === conf.id)
            if(i<0) {
                _.push(conf)
            } else {
                _[i] = conf
            }
            setConversations(_)
        })

        return () => {
            socket.removeAllListeners('update-conversation')
        }
    }, [conversations])

    useEffect(() => {
        return () => {
            if(socket) {
                socket.disconnect()
            }
        }
    }, [])

    useEffect(() => {
        const url = conf.backendUrl
        socket = io(url, {
            query: `session=${cookies.load('session')}&conversationId=0&type=conversation`,
            secure: window.location.href.includes('.com')
        })
        socket.connect()

        return () => {
            socket.disconnect()
        }
    }, [])

    useEffect(() => {
        setConversations([])
        if(searchTimeout) { clearTimeout(searchTimeout) }
        
        searchTimeout = setTimeout(() => {
            setConversationsPage(1)
            loadConversations()
        }, 400);
    }, [search])

    const loadConversations = (clear=false, page) => {
        setLoading(true)
        request('/chat/conversations', 'POST', {
            page: page ? page : (clear ? 1 : conversationsPage),
            search: search.trim()
        }).then((data) => {
            let _ = clear ? [] : [...conversations]
            for(const conversation of data.conversations) {
                const i = _.findIndex(x => x.id === conversation.id)
                if(i<0) {
                    _.push(conversation)
                } else {
                    _[i] = conversation
                }
            }
            setHasMore(data.conversations.length > 0)
            setConversations(_)
            console.log(_.length)
        }).catch(err => {
            console.error(`Failed for load the conversations: ${err.message || err}`)
        }).finally(() => {
            setLoading(false)
        })
    }

    return (
        <MainAppContainer name='chat'>
            <NewConversation 
                open={newConversationOpen}
                onClose={() => setNewConversationOpen(false)}
                onAdded={(id) => {
                    setActive(id)
                    setNewConversationOpen(false)
                    loadConversations(false, 1)
                }}
            />
            <Helmet>
                <title>{conf.appName} - Chat</title>
            </Helmet>
            
            {(
                <div className="chat-container">
                    <div className="conversation-container">
                        <div className="head">
                            <Input
                                type='text'
                                placeholder='Cari'
                                onChange={e => {
                                    setSearch(e.target.value)
                                }}
                                className="search-field"
                                value={search}
                            />
                            {!isAdmin && (
                                <Button
                                    type='primary'
                                    shape='circle'
                                    className="add-chat"
                                    onClick={() => setNewConversationOpen(true)}
                                    icon={<AddCommentOutlinedIcon className="icon" />}
                                />
                            )}
                            {isAdmin && <AvailableCheck />}
                        </div>
                        <div className="conversations">
                            {conversations.sort((a, b) => b.lastMessageEpoch - a.lastMessageEpoch).map((x, i) => (
                                <ConversationItem
                                    key={i}
                                    data={x}
                                    onClick={() => setActive(x.id)}
                                    active={active === x.id}
                                />
                            ))}
                            {hasMore && (
                                <Button className="load-more-btn"
                                    size='small'
                                    type='primary'
                                    loading={loading}
                                    onClick={() => {
                                        setConversationsPage(x => x + 1)
                                    }}
                                >Muat Lagi</Button>
                            )}
                        </div>
                    </div>
                    <div className="chat-main-container">
                        {conversations.length < 1 && (
                            <EmptyConversation
                                onAddRequest={() => setNewConversationOpen(true)}
                            />
                        )}
                        {active && (
                            <ChatData 
                                conversationId={active}
                            />
                        )}
                    </div>
                </div>
            )}
        </MainAppContainer>
    )
}