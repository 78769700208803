import { Modal, Typography } from "antd"
import request from "utils/request"

export default function CancelModal({ orderId, open, onClose, onCancel }) {

    const cancel = () => {
        request('/order/cancelTransaction', 'POST', {
            orderId
        }).then(data => {
            onCancel()
            onClose()
        }).catch(err => console.error(`Failed for cancel the transaction: ${err.message || err}`))
    }
    
    return (
        <Modal
            title="Batalkan Pesanan"
            open={open}
            onOk={cancel}
            onCancel={onClose}
            destroyOnClose
            centered
            width={400}
            okText='Batalkan'
            cancelText='Tutup'
        >
            <Typography>Anda yakin ingin membatalkan pesanan <b>{orderId}</b> ?</Typography>
        </Modal>
    )
}