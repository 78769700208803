import { InboxOutlined } from "@mui/icons-material";
import { Alert, Image, Modal, Upload } from "antd";
import conf from "conf";
import { useEffect, useState } from "react";
import request from "utils/request";

const allowedMimeTypes = [
    'image/jpeg',
    'image/png',
    'image/jpg'
]

const details = conf.banks

export default function PayModal({ orderId, open, onClose, onPaid, paymentMethod }) {

    const [loading, setLoading] = useState(false)
    const [file, setFile] = useState(null)
    const [renderFile, setRenderFile] = useState(null)
    const [error, setError] = useState(null)

    const send = () => {
        setError(null)
        setLoading(true)
        const fd = new FormData()
        fd.append('file', file)
        fd.append('orderId', orderId)
        request('/order/pay', 'POST', fd).then(data => {
            setError(null)
            onPaid()
            onClose()
        }).catch(err => {
            console.error(`Failed for send payment media: ${err.message || err}`)
            setError(err.message || err)
        }).finally(() => {
            setLoading(false)
        })
    }

    useEffect(() => {
        setError(null)
        setFile(null)
    }, [open])

    useEffect(() => {
        if (file) {
            setError(null) 
            const i = allowedMimeTypes.findIndex(x => x === file.type)
            if (i < 0) {
                setFile(null)
                setError('File bukti pembayaran tidak didukung')
                setRenderFile(null)
            } else {
                const reader = new FileReader()
                reader.onloadend = (e) => {
                    setRenderFile(e.target.result)
                }
                reader.readAsDataURL(file)
            }
        } else {
            setRenderFile(null)
        }
    }, [file])

    return (
        <Modal
            open={open}
            onCancel={onClose}
            title="Pembayaran"
            centered={true}
            width={500}
            closable={!loading}
            cancelButtonProps={{
                disabled: loading
            }}
            okButtonProps={{
                loading,
                disabled: !file
            }}
            okText='Kirim'
            cancelText='Batal'
            destroyOnClose
            onOk={send}
        >
            {error && (
                <Alert
                    message={error}
                    showIcon
                    type='error'
                    style={{
                        marginBottom: '20px'
                    }}
                />
            )}
            <Upload.Dragger
                disabled={loading}
                showUploadList={false}
                locale='id'
                beforeUpload={e => {
                    setFile(e)
                    return false
                }}
                accept='image/png, image/jpeg, image/jpg'
                style={{
                    marginBottom: '10px'
                }}
                fileList={file ? [file] : []}
            >
                <p className="ant-upload-text">
                    Klik atau seret file bukti pembayaran di area ini
                </p>
                <p className="ant-upload-hint">Mendukung file dengan format jpg, jpeg, png. max: 5mb</p>
                <p className="ant-upload-hint">Transfer ke: {details.find(x => x.name === paymentMethod)?.detail || "-"}</p>
            </Upload.Dragger>
            {renderFile && (
                <Image 
                    src={renderFile}
                    style={{
                        maxHeight: '90px',
                        marginTop: '10px'
                    }}
                />
            )}
        </Modal>
    )
}