import { Button, DatePicker, Input, Typography } from "antd";
import MainAppContainer from "element/MainAppContainer";
import { Helmet } from "react-helmet";
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat';
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import { useEffect, useState } from 'react'
import request from "utils/request";
import DataPreview from "./DataPreview";
import conf from "conf";
import rupiah from "utils/rupiah";
import moment from 'moment-timezone'

dayjs.extend(customParseFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)

dayjs.locale('id')


export default function PrivateProfitBarang() {

    const [date, setDate] = useState([dayjs().set('date', 1), dayjs()])
    const [loading, setLoading] = useState(false)
    const [orders, setOrders] = useState([])
    const [heavy, setHeavy] = useState(0)
    const [transfer, setTransfer] = useState(0)
    const [selecteds, setSelecteds] = useState([])
    const [markingCode, setMarkingCode] = useState('')

    const fetchData = () => {
        setLoading(true)
        request('/order/privateProfitBarang', 'POST', {
            from: moment(date[0].unix() * 1000).startOf('day').unix(),
            to: moment(date[1].unix() * 1000).endOf('day').unix()
        }).then(res => {
            setOrders(res)
            console.log(res)
        }).catch(err => {
            console.error(err.message || err)
        }).finally(() => {
            setLoading(false)
        })
    }

    useEffect(() => {
        fetchData()
    }, [date])

    return (
        <MainAppContainer name='private-profit-barang'>
            <Helmet>
                <title>{conf.appName} - Profit Barang</title>
            </Helmet>
            <DataPreview
                orders={orders}
                onChanged={fetchData}
                onSelected={setSelecteds}
            />
            <div className="data-footer">
                <div className="left">
                    <DatePicker.RangePicker
                        className="range-pickerofdate"
                        placeholder={['Mulai', 'Sampai']}
                        format={'DD MMM YYYY'}
                        value={date}
                        onChange={setDate}
                        disabled={loading}
                        picker='date'
                    />
                </div>
            </div>
        </MainAppContainer>
    )
}