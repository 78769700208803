import { Alert, Modal, Typography } from "antd";
import { useEffect, useState } from "react";
import request from "utils/request";

export default function RefundDialog({orderId, open, onClose, onRefunded}) {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)

    useEffect(() => {
        setError(false)
    }, [open])

    const refund = () => {
        setError(false)
        setLoading(true)
        request('/order/refund', 'POST', {
            orderId
        }).then(data => {
            onRefunded()
            onClose()
        }).catch(err => setError(err.message || err))
        .finally(() => {
            setLoading(false)
        })
    }

    return (    
        <Modal
            title='Refund'
            centered
            width={400}
            closable={!loading}
            okButtonProps={{
                loading
            }}
            cancelButtonProps={{
                disabled: loading
            }}
            open={open}
            onCancel={onClose}
            cancelText='Batal'
            okText='Refund'
            onOk={refund}
        >
            {error && (
                <Alert 
                    message={error}
                    type='error'
                    showIcon
                    style={{
                        marginBottom: '15px'
                    }}
                />
            )}
            <Typography>Yakin ingin melakukan refund pada pesanan <b>{orderId}</b> ? Hal ini tidak dapat dikembalikan</Typography>
        </Modal>
    )
}