const isPublic = window.location.href.includes('beliexpress.com')

const conf = {
    cookiesSessionName: "session",
    // backendUrl: "http://localhost:3001",
    backendUrl: "https://asistenq.com",
    // backendUrl: "https://beliexpress.com",
    appName: (isPublic ? "BeliExpress" : "AsistenQ"),
    privateUrl: "asistenq.com",
    publicUrl: "beliexpress.com",
    banks: [
        {
            name: "BANK JAGO",
            detail: "Tiara Dewi Fortuna - 107812311468"
        }
    ],
    metas: [
        {
            url: "/register",
            keywords: "Daftar BeliExpress, Registrasi akun BeliExpress, Buat akun BeliExpress, Daftar pengguna baru, Registrasi cepat BeliExpress, Buat akun import, Registrasi mudah BeliExpress, Daftar untuk import dari China, Bergabung dengan BeliExpress, Registrasi gratis BeliExpress",
            description: "Daftar di BeliExpress untuk memulai impor produk dari China ke Indonesia. Registrasi akun cepat dan mudah. Bergabunglah sekarang!"
        },
        {
            url: "/faq",
            keywords: "Pertanyaan Umum BeliExpress, FAQ BeliExpress, Pertanyaan tentang BeliExpress, Bantuan BeliExpress, Informasi BeliExpress, Pertanyaan dan Jawaban BeliExpress, Dukungan Pelanggan BeliExpress, Informasi impor dari China, Panduan pengguna BeliExpress, Pertanyaan seputar impor",
            description: "Temukan jawaban atas pertanyaan umum tentang BeliExpress dan layanan impor dari China ke Indonesia. Dapatkan bantuan dan informasi lengkap di sini."
        },
        {
            url: null, // default
            description: "Import Aliexpress Mudah dan Murah",
            keywords: "Impor dari China ke Indonesia, Beli produk China di Indonesia, BeliExpress Indonesia, Pengiriman China ke Indonesia, Impor Barang China Indonesia, Layanan impor Indonesia, Pasar online Indonesia, Produk China murah Indonesia, Grosir produk China Indonesia"
        },
        {
            url: "not_found",
            description: "Maaf, halaman yang Anda cari tidak dapat ditemukan. Kesalahan 404 terjadi saat sistem tidak dapat menemukan halaman yang dimaksud. Silakan coba lagi nanti atau kembali ke Beranda untuk menelusuri halaman lainnya.",
            keywods: "Halaman tidak ditemukan, Kesalahan 404, Halaman tidak tersedia, Kesalahan URL, Error 404, Tautan rusak, Coba lagi nanti, Kembali ke Beranda, Sistem tidak dapat menemukan halaman yang Anda cari, Temukan halaman lainnya"
        }
    ],
    addressTypes: [
        { name: "Rumah", key: "HOME" },
        { name: "Kantor", key: "OFFICE" },
        { name: "Sekolah", key: "SCHOOL" },
        { key: "BOARDING HOUSE", name: "Kontrakan / KOS" },
        { name: "Gudang", key: "WAREHOUSE" }
    ]
}

export default conf