import HomeContainer from "element/HomeContainer";
import k98th from 'dist/image/front-bg/kampung-songo-8th.jpg'
import ReactImageGallery from "react-image-gallery";
import OrderStarter from './OrderStarter'
import { useEffect, useRef } from "react";
import './index.scss'
import ShippingPriceEstimation from "./ShippingPriceEstimation";
import Helmet from 'react-helmet'
import conf from 'conf'
import pujonHoliday from 'dist/slideshows/pujon-holiday.jpg'
import slide1 from 'dist/slideshows/1.jpeg'
import slide2 from 'dist/slideshows/2.jpeg'
import slide3 from 'dist/slideshows/3.jpeg'
import WhatIsThat from './WhatIsThat'
import TargetMarket from "./TargetMarket";
import Superiority from "./Superiority";
import Review from "./Review";
import { Typography } from "antd";
import LevelUp from "./LevelUp";

const images = window.location.href.includes('asistenq.com') ? [pujonHoliday] : [slide1, slide2, slide3]

export default function Home() {

    const imageSlider = useRef(null)

    useEffect(() => {
        if(imageSlider.current != null) {
            imageSlider.current.imageGallery.current.style.maxHeight = `${(window.innerHeight - 90)}px`
            imageSlider.current.imageGallerySlideWrapper.current.style.maxHeight = `${(window.innerHeight - 90)}px`
        }
    }, [])

    return (
        <HomeContainer
            name='landing-page'
        >
            <Helmet>
                <title>{conf.appName}</title>
            </Helmet>
            <div className="slider-container">
                <ReactImageGallery
                    ref={imageSlider}
                    items={images.map((image) => {
                        return {
                            thumbnail: image,
                            original: image
                        }
                    })}
                    additionalClass="tsoft-gallery"
                    lazyLoad={true}
                    showThumbnails={false}
                    showFullscreenButton={false}
                    showPlayButton={false}
                    showBullets={true}
                    autoPlay={true}
                    slideInterval={3000}
                />
                <OrderStarter />
            </div>
            <ShippingPriceEstimation />
            <WhatIsThat />
            <TargetMarket />
            <Superiority />
            <Review />
            <div className="pancingan">
                <Typography className="title">Belanja Sekarang</Typography>
                <Typography className="desc">- Kirim produk dari AliExpress ke Indonesia</Typography>
            </div>
            <LevelUp />
        </HomeContainer>
    )
}