import { Button } from 'antd'
import conf from 'conf'
import logo from 'dist/image/logo.png'
import AuthLogin from 'element/AuthLogin'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import request from "../../utils/request";
import Profile from "./Profile";
import Notification from "./Notification";


export default function Header() {
    const navigate = useNavigate()
    const location = useLocation()
    const [loginOpen, setLoginOpen] = useState(false)
    const [authenticated, setAuthenticated] = useState(false)
    const [headerStyle, setHeaderStyle] = useState({})

    useEffect(() => {
        const search = new URLSearchParams(window.location.search)
        if (search.has('showLogin') && search.get('showLogin') == 1) {
            setLoginOpen(true)
        } else {
            setLoginOpen(false)
        }

    }, [location]);

    useEffect(() => {
        if (authenticated) {
            // Cek apakah  role nya admin
            if (window.location.href.includes('asistenq.com')) {
                request('/user/roles').then(data => {
                    if (data.roles.includes('general')) {
                        setHeaderStyle({
                            background: " linear-gradient(30deg, rgba(230,46,3,1) 25%, rgba(253,147,0,1) 75%)"
                        })
    
                    } else if (data.roles.includes('admin1') || data.roles.includes('admin2') || data.roles.includes('admin3')) {
                        setHeaderStyle({
                            background: "linear-gradient(30deg, rgba(33,149,241,1) 0%, rgba(17,83,158,1) 25%)"
                        })
                    }
                }).catch(err => {
                    console.error(err.message || err)
                })
            } else {
                setHeaderStyle({
                    background: " linear-gradient(30deg, rgba(230,46,3,1) 25%, rgba(253,147,0,1) 75%)"
                })
            }
        } else {
            setHeaderStyle({
                background: " linear-gradient(30deg, rgba(230,46,3,1) 25%, rgba(253,147,0,1) 75%)"
            })
        }
    }, [location, authenticated])

    // Check authenticated every move the page
    useEffect(() => {
        request('/session/isValid').then(data => {
            setAuthenticated(data.valid)
        }).catch(err => {
            console.error('Failed for detect the login status', err.message || err)
            setAuthenticated(false)
        })
    }, [location])

    return (
        <div className="header"
            style={headerStyle}
        >
            <AuthLogin
                open={loginOpen}
                onClose={() => setLoginOpen(false)}
            />
            <div className="left">
                <div className="app" onClick={() => {
                    navigate('/')
                }}>
                    <img src={logo} alt={conf.appName}
                        title={conf.appName}
                        className="logo" />
                    <div className="info">
                        <div className="title">BELI</div>
                        <div className="subtitle">Express</div>
                    </div>
                </div>
            </div>

            <div className="right">
                {authenticated && (
                    <>
                        <Notification />
                        <Profile />
                    </>
                )}
                {/* Element rendered if not authenticated */}
                {!authenticated && (
                    <>
                        <Button
                            type='dashed'
                            onClick={() => setLoginOpen(true)}
                            className='enter-btn'
                        >Masuk</Button>
                        <Button
                            type='primary'
                            className='register-btn'
                            onClick={() => navigate('/register')}
                        >Daftar</Button>
                    </>
                )}
            </div>
        </div>
    )
}