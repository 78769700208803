import { Flex, Input, Modal, Select, Tag, Typography } from "antd";
import conf from "conf";
import { useEffect, useState } from "react";
import request from "utils/request";

export default function AddAddressModal({ open, onClose, onAdded }) {
    const [name, setName] = useState('')
    const [province, setProvince] = useState(null)
    const [regency, setRegency] = useState(null)
    const [district, setDistrict] = useState(null)
    const [village, setVillage] = useState(null)
    const [additionalAddress, setAdditionalAddress] = useState('')
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [type, setType] = useState('HOME')

    // All variable region
    const [provinces, setProvinces] = useState([])
    const [regencies, setRegencies] = useState([])
    const [districts, setDistricts] = useState([])
    const [villages, setVillages] = useState([])

    const fetchProvinces = () => {
        request('/region/provinces').then(data => setProvinces(data)).catch(err => console.error(`Failed for get the provinces: ${err.message || err}`))
    }

    useEffect(() => {
        setName('')
        setProvince(null)
        setRegency(null)
        setDistrict(null)
        setVillage(null)
        setAdditionalAddress('')

        if (open) {
            fetchProvinces()
        } else {
            setProvinces([])
        }
    }, [open])

    useEffect(() => {
        setVillages([])
        setVillage(null)

        if (typeof district === 'string') {
            const _district = JSON.parse(district)
            const url = _district.url.replace(conf.backendUrl, '')
            request(url).then(data => setVillages(data))
                .catch(err => console.error(`Failed for get the villages: ${err.message || err}`))
        }

        setVillage(null)
    }, [district])

    useEffect(() => {
        setDistricts([])
        setDistrict(null)

        if (typeof regency === 'string') {
            const _regency = JSON.parse(regency)
            const url = _regency.url.replace(conf.backendUrl, '')
            request(url).then(data => setDistricts(data))
                .catch(err => console.error(`Failed for get the districts: ${err.message}`))
        }

        setDistrict(null)
    }, [regency])

    useEffect(() => {
        setRegencies([])
        setRegency(null)

        if (typeof province === 'string') {
            const _province = JSON.parse(province)
            // Get the regency data
            const url = _province.url.replace(conf.backendUrl, '')
            request(url).then(data => setRegencies(data))
                .catch(err => console.error(`Failed for get the regency: ${err.message || err}`))
        }
        setRegency(null)
    }, [province])

    const add = () => {
        setLoading(true)
        setError(null)
        request('/user/address/add', 'POST', {
            name: name.trim(),
            province: JSON.parse(province),
            regency: JSON.parse(regency),
            district: JSON.parse(district),
            village: JSON.parse(village),
            additionalAddress: additionalAddress,
            type
        }).then(() => {
            setError(null)
            onAdded()
            onClose()
        }).catch(err => {
            console.error(`Failed for add address: ${err.message || err}`)
            setError(err.message || err)
        }).finally(() => {
            setLoading(false)
        })
    }

    return (
        <Modal
            title='Tambah Alamat'
            centered
            width={400}
            open={open}
            onCancel={onClose}
            className="add-address-modal"
            cancelText='Batal'
            okText='Tambah'
            okButtonProps={{
                loading: loading,
                disabled: loading || name.trim().length < 1 || 
                    province === null || regency === null || 
                    district === null || village === null
            }}
            onOk={add}
        >
            <div className="forms">
                <div className="form-control">
                    <Typography className="label">Nama</Typography>
                    <Input
                        placeholder='Nama'
                        value={name}
                        onChange={e => setName(e.target.value)}
                        size='large'
                        disabled={loading}
                        className="input"
                    />
                </div>
                <div className="form-control">
                    <Typography className="label">Jenis Alamat</Typography>
                    <Flex wrap='wrap' gap={5}>
                        {conf.addressTypes.map((x, i) => (
                            <Tag key={i}
                                color={type === x.key ? 'red' : 'default'}
                                style={{ cursor: 'pointer' }}
                                onClick={() => setType(x.key)}
                            >{x.name}</Tag>
                        ))}
                    </Flex>
                </div>
                {/* Province */}
                <div className="form-control">
                    <Typography className="label">Provinsi</Typography>
                    <Select
                        value={province}
                        onChange={e => setProvince(e)}
                        size='large'
                        options={provinces.map(x => {
                            return {
                                value: JSON.stringify(x),
                                label: x.name
                            }
                        })}
                        showSearch
                        className="input"
                        loading={loading}
                        disabled={loading}
                    />
                </div>
                {/* Regency / daerah */}
                <div className="form-control">
                    <Typography className="label">Daerah</Typography>
                    <Select
                        value={regency}
                        onChange={e => setRegency(e)}
                        size='large'
                        options={regencies.map((regency) => {
                            return {
                                value: JSON.stringify(regency),
                                label: regency.name
                            }
                        })}
                        showSearch
                        className="input"
                        loading={loading}
                        disabled={loading}
                    />
                </div>
                {/* District */}
                <div className="form-control">
                    <Typography className="label">Distrik</Typography>
                    <Select
                        value={district}
                        onChange={e => setDistrict(e)}
                        size='large'
                        options={districts.map(x => {
                            return {
                                value: JSON.stringify(x),
                                label: x.name
                            }
                        })}
                        showSearch
                        className="input"
                        loading={loading}
                        disabled={loading}
                    />
                </div>
                {/* Village / desa */}
                <div className="form-control">
                    <Typography className="label">Desa</Typography>
                    <Select
                        value={village}
                        onChange={e => setVillage(e)}
                        size='large'
                        options={villages.map(x => {
                            return {
                                value: JSON.stringify(x),
                                label: x.name
                            }
                        })}
                        showSearch
                        className="input"
                        loading={loading}
                        disabled={loading}
                    />
                </div>
                {/* Tambahan */}
                <div className="form-control">
                    <Typography className="label">Tambahan</Typography>
                    <Input.TextArea
                        value={additionalAddress}
                        onChange={e => setAdditionalAddress(e.target.value)}
                        disabled={loading}
                        placeholder='ex: Jl. ketipang ...'
                        autoSize={{
                            minRows: 2,
                            maxRows: 7
                        }}
                        showCount
                        maxLength={300}
                        status={additionalAddress.length > 300 ? "error" : undefined}
                    />
                </div>
            </div>
        </Modal>
    )
}