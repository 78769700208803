import { Flex, Input, Space, Typography } from "antd";
import { useEffect, useState } from "react";
import request from "utils/request";
import rupiah from "utils/rupiah";

let updateTimeout

export default function ShippingCapitalInput({roles, currentCapitalPrice, currentShippingPrice, orderId, order, onCapital}) {
    const [capitalPrice, setCapitalPrice] = useState(currentCapitalPrice)
    const [shippingPrice, setShippingPrice] = useState(currentShippingPrice)

    useEffect(() => {
        try {
            onCapital(parseInt(capitalPrice))
        } catch(err) {}
    }, [capitalPrice])

    useEffect(() => {
        setShippingPrice(currentShippingPrice)
        setCapitalPrice(currentCapitalPrice)
    }, [currentShippingPrice, currentCapitalPrice])

    useEffect(() => {
        if(updateTimeout) { clearTimeout(updateTimeout) }

        updateTimeout = setTimeout(() => {
            request('/order/changeInternPrice', 'POST', {
                orderId: orderId,
                shippingPrice: parseInt(shippingPrice),
                capitalPrice: parseInt(capitalPrice),
                from: roles.join(',')
            }).then(data => {}).catch(err => console.error(`Failed for update intern price: ${err.message || err}`))
        }, 500);

        return () => clearTimeout(updateTimeout)
    }, [capitalPrice, shippingPrice])

    return (roles.includes('admin2') || roles.includes('admin3')) && (
        <Flex wrap='wrap' className="shipping-capital-input" gap={15}>
            {(roles.includes('admin2') || roles.includes('admin3')) && (
                <div className="form-control">
                    <Typography className="label">Harga Modal</Typography>
                    <Input 
                        value={rupiah.encode(capitalPrice)}
                        size='small'
                        readOnly={roles.length <= 1 && order.admin2.finished}
                        onChange={e => setCapitalPrice(rupiah.decode(e.target.value.split('.').join('')))}
                    />
                </div>
            )}
            {roles.includes('admin3') && (
                <div className="form-control">
                    <Typography className="label">Harga Pengiriman</Typography>
                    <Input 
                        value={rupiah.encode(shippingPrice)}
                        size='small'
                        readOnly={roles.length <= 1 && order.admin3.finished}
                        onChange={e => setShippingPrice(rupiah.decode(e.target.value.split('.').join('')))}
                    />
                </div>
            )}
        </Flex>
    )
}