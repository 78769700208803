import conf from "conf";
import MainAppContainer from "element/MainAppContainer";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import request from "utils/request";
import ComplaintItem from './ComplaintItem'
import { Button } from "antd";
import './index.scss'

export default function KritikDanSaran() {
    const [page, setPage] = useState(1)
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [hasMore, setHasMore] = useState(true)

    useEffect(() => {
        if(page > 0) {
            setLoading(true)
            request(`/complaint/list?page=${page}`).then(response => {
                let x = [...data]
                for(const item of response) {
                    const i = x.findIndex(x => x.id === item.id)
                    if(i<0) { x.push(item) }
                }
                setData(x)
                setHasMore(response.length > 0)
            }).catch(err => console.error(err.message || err)).finally(() => {
                setLoading(false)
            })
        }
    }, [page])

    return (
        <MainAppContainer name='kritik-dan-saran'>
            <Helmet>
                <title>{conf.appName} - Kritik dan Saran</title>
            </Helmet>
            <div className="complaint-list">
                {data.map((complaint, i) => (
                    <ComplaintItem
                        key={i}
                        data={complaint}
                    />
                ))}
                {data.length > 0 && hasMore && (
                    <Button 
                        type='primary'
                        size='small'
                        onClick={() => setPage(x => x + 1)}
                        disabled={loading}
                        className="load-more-btn"
                    >Muat Lainnya...</Button>
                )}
            </div>
        </MainAppContainer>
    )
}