import conf from "conf";
import HomeContainer from "element/HomeContainer";
import { Helmet } from "react-helmet";
import './index.scss'

export default function SyaratDanKetentuan() {
    return (
        <HomeContainer name='syarat-dan-ketentuan'>
            <Helmet>
                <title>Syarat Dan Ketentuan - {conf.appName}</title>
                <meta name='description' content='Pelajari lebih lanjut tentang syarat dan ketentuan BeliExpress. Dapatkan informasi lengkap mengenai kebijakan pengiriman, pengembalian, dan perlindungan privasi untuk pengalaman belanja yang aman dan nyaman.' />
            </Helmet>
            <div className="top-header">
                <div className="container">
                    <div className="title">Kebijakan Layanan</div>
                    <div className="desc">Pengaturan {conf.appName} dibuat untuk kenyamanan yang berlaku untuk semua pelanggan. Dengan menggunakan layanan dari {conf.appName} berarti Anda telah setuju untuk aturan ini.</div>
                </div>
            </div>
            <div className="card">
                <section>
                    <div className="title">Pendahuluan</div>
                    <p className="l-text">
                        Terms of Service and Acceptable Use Policies ("Terms of Service") merupakan hal utama dalam
                        hubungan Anda dengan {conf.appName}. Dengan menggunakan layanan {conf.appName}, Anda setuju terhadap seluruh Syarat dan Ketentuan Layanan yang tertera pada dokumen ini.<br/><br/>

                        Layanan disediakan untuk perorangan atau organisasi resmi (dalam dokumen ini disebutkan sebagai Pelanggan, Subscriber, atau Anda). Dengan menggunakan Layanan {conf.appName}, Anda setuju untuk mematuhi seluruh kebijakan layanan.
                        Jika Anda tidak setuju untuk mematuhi kebijakan layanan, Anda diharuskan untuk membatalkan akun Anda secepatnya dan tidak diperkenankan menggunakan atau mencoba menggunakan layanan {conf.appName}.<br/><br/>

                        Pengguna dan Pelanggan Layanan yang disediakan oleh {conf.appName} harus menerima Syarat dan Ketentuan yang ditetapkan dibawah ini:<br/>
                        <ul>
                            <li>
                                <b>Penulisan Alamat Pengiriman</b>
                                <ul>
                                    <li>Kesalahan penulisan alamat pengiriman menjadi tanggung jawab customer. Pastikan alamat yang diberikan sudah benar dan lengkap untuk menghindari masalah pengiriman.</li>
                                </ul>
                            </li>
                            <li>
                                <b>Kerusakan/Kekurangan/Kesalahan Produk</b>
                                <ul>
                                    <li>Segala kerusakan, kekurangan, atau kesalahan produk yang dipesan merupakan tanggung jawab customer. Namun, kini tetap dapat membantu customer untuk mengajukan komplain ke seller.</li>
                                </ul>
                            </li>
                            <li>
                                <b>Prosedur Komplain dan Tanggung Jawab Seller</b>
                                <ul>
                                    <li>Jika customer sudah melakukan prosedur komplain atass produk yang diterima dan pihak seller tidak bersedia bertanggung jawab atas barang rusak atau kurang, maka tanggung jawab sepenuhnya berada pada customer. Kami hanya bertindak sebagai penyedia jasa titip, bukan supplier, sehingga jawaban yang diberikan oleh seller merupakan jawaban final.</li>
                                </ul>
                            </li>
                        </ul>
                    </p>
                </section>
                <section>
                    <div className="title">Perubahan</div>
                    <p className="l-text">Kebijakan ini dapat diubah dalam bentuk apa saja dan kapan saja oleh {conf.appName}. Penggunaan Layanan yang tetap Anda lakukan secara berlanjut, maka akan dianggap setuju terhadap perubahan tersebut. Jika Anda tidak ingin melanjutkan menggunakan Layanan kami karena perubahan tersebut, Anda dapat melakukan permintaan untuk mengakhiri penggunaan layanan {conf.appName}.</p>
                </section>
            </div>
        </HomeContainer>
    )
}