import { Card } from "antd";
import conf from "conf";
import container from 'dist/ilustration/container.png'

export default function Review() {
    return (
        <div className="review">
            <img src={container} alt="Review" className="container-img" />
            <Card className="review-card" >
                <div className="quote">
                    Di {conf.appName}, belanja jadi lebih gampang buat gue. Biaya pengiriman yang murah banget, jadi ga usah khawatirin kantong jebol. Enak banget bisa belanja dari AliExpress tanpa mikirin ongkir mahal!
                </div>
                <div className="writer">
                    <div className="name">Arief</div>
                    , Jakarta.
                </div>
            </Card>
        </div>
    )
}