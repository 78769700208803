import {Modal, Typography} from "antd";
import {useNavigate} from "react-router";

export default function SuccessAlert({open, onClose, hash}) {
    const navigate = useNavigate()

    return (
        <Modal
            title={'Berhasil'}
            okText={'Masuk'}
            cancelText={'Tutup'}
            open={open}
            onCancel={onClose}
            centered
            width={350}
            onOk={() => {
                if(typeof hash === 'string') {
                    navigate(`/login?newSession=${hash}`)
                }
                onClose()
            }}
        >
            <Typography.Paragraph>Pendaftaran berhasil, klik <b>Masuk</b> untuk melanjutkan</Typography.Paragraph>
        </Modal>
    )
}