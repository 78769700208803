import { Typography } from "antd";
import cargoAirPlane from 'dist/ilustration/cargo-airplane.png'
import conf from "conf";

export default function WhatIsThat() {
    return (
        <div className="what-is-that">
            <div className="left">
                <Typography className="title">Solusi terbaik order satuan dari AliExpress</Typography>
                <Typography className="desc">
                    {conf.appName} adalah platform yang dikembangkan oleh <a href="https://ziqva.com" target="_blank">Ziqva Labs </a>
                    pada tahun 2024, berpusat di Jakarta. {conf.appName} menawarkan solusi pengiriman mudah, cepat, dan aman dari AliExpress ke Indonesia. Kami memungkinkan pelanggan untuk memesan produk satuan dengan lancar
                    tanpa hambatan.
                </Typography>
            </div>
            <div className="right">
                <img src={cargoAirPlane} 
                    alt='Apa itu BeliExpress'
                />
            </div>
        </div>
    )
}