import { Modal, Typography } from "antd";
import conf from "conf";
import { useEffect, useState } from "react";
import { io } from "socket.io-client";
import request from 'utils/request'
import cookies from 'react-cookies'

export default function NotificationPopupListener() {
    const [open, setOpen] = useState(false)
    const [title, setTitle] = useState('')
    const [message, setMessage] = useState('')
    const [link, setLink] = useState('/')
    const [authenticated, setAuthenticated] = useState(false)

    useEffect(() => {
        request('/session/isValid', 'GET').then(data => {
            setAuthenticated(data.valid)
        }).catch(err => console.error(err.message || err))
    }, [])

    useEffect(() => {
        if(!open) {
            setTitle('')
            setMessage('')
        }
    }, [open])

    useEffect(() => {
        if(authenticated) {
            const socket = io(conf.backendUrl, {
                query: `session=${cookies.load('session')}&type=notification-popup-listener`,
                secure: window.location.href.includes('.com')
            })
            
            socket.on('popup-notification', data => {
                setOpen(true)
                setTitle(data.title)
                setMessage(data.description)
                setLink(data.url)
            })
        }
    }, [authenticated])

    return (
        <Modal
            open={open}
            onCancel={() => setOpen(false)}
            title={title}
            centered
            width={400}
            okText='Buka'
            onOk={() => window.location.href = link}
            cancelText='Abaikan'
        >
            <Typography>{message}</Typography>
        </Modal>
    )
}