function formatRupiah(amount) {
    // Check if the amount is a valid number
    if (isNaN(amount)) {
        return "Invalid amount";
    }

    // Convert the number to string and add commas for every thousand separator
    let formattedAmount = amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Add "Rp" prefix and return the formatted string
    return "Rp " + formattedAmount;
}

export default formatRupiah