const navigations = [
    ['Informasi', [
        {
            name: "FAQ",
            url: "/faq"
        },
        {
            name: "Kecewa? Laporkan disini",
            url: '/aduan'
        },
        { name: "Syarat Dan Ketentuan", url: '/syarat-dan-ketentuan' }
    ]],
]

export default navigations