import { DatePicker } from "antd";
import MainAppContainer from "element/MainAppContainer";
import { Helmet } from "react-helmet";
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat';
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import { useEffect, useState } from 'react'
import request from "utils/request";
import DataPreview from "./DataPreview";
import conf from "conf";

dayjs.extend(customParseFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)

dayjs.locale('id')


export default function PrivatePembukuan() {

    const [date, setDate] = useState([dayjs(), dayjs()])
    const [loading, setLoading] = useState(false)
    const [orders, setOrders] = useState([])
    const [isPrivate, setIsPrivate] = useState(false)
    
    useEffect(() => {
        request('/app/isPrivate').then((data => {
            setIsPrivate(data)
        })).catch(err => console.error(err.message || err))
    }, [])

    const fetchData = () => {
        setLoading(true)
        request('/order/privatePembukuanData', 'POST', {
            from: date[0].unix(),
            to: date[1].unix()
        }).then(res => {
            setOrders(res)
            console.log(res)
        }).catch(err => {
            console.error(err.message || err)
        }).finally(() => {
            setLoading(false)
        })
    }

    useEffect(() => {
        fetchData()
    }, [date])

    return (
        <MainAppContainer name='private-pembukuan'>
            <Helmet>
                <title>{conf.appName} - Pembukuan</title>
            </Helmet>
            <DataPreview
                orders={orders}
                onChanged={fetchData}
                isPrivate={isPrivate}
            />
        </MainAppContainer>
    )
}