import {Button, Divider, Popover, Spin, Tooltip} from "antd";
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import {useEffect, useState} from "react";
import request from "../../utils/request";
import LogoutIcon from '@mui/icons-material/Logout';
import {useLocation, useNavigate} from "react-router";

export default function Profile() {

    const [open, setOpen] = useState(false)
    const location = useLocation()

    useEffect(() => {
        setOpen(false)
    }, [location])

    return (
        <Popover
            trigger={'click'}
            open={open}
            onOpenChange={x => setOpen(x)}
            arrow={false}
            content={<ProfileDetail />}
            placement={'bottomLeft'}
        >
            <Button icon={<AccountCircleOutlinedIcon className={'icon'} />}
                    className={'profile-toggle-btn'}
                    type={'text'}
            />
        </Popover>
    )
}


function ProfileDetail() {
    const [profile, setProfile] = useState(null)
    const navigate = useNavigate()

    useEffect(() => {
            request('/user/get/profile', 'GET').then(data => {
                setProfile(data)
            }).catch(err => console.error(`Failed for get profile: ${err.message || err}`))
    }, []);

    return (
        <div className='header-profile-detail'>
            {profile === null && (
                <Spin className={'spinner'}  />
            )}

            {profile !== null && (
                <>
                    <div className="menus">
                        <Button
                            size={'large'}
                            type={'text'}
                            className={'menu-btn'}
                            onClick={() => navigate('/profile')}
                        >Profil Saya</Button>
                        <Button
                            size={'large'}
                            type={'text'}
                            className={'menu-btn'}
                            onClick={() => navigate('/order')}
                        >Pesanan Saya</Button>
                        <Divider style={{
                            marginBlock: '5px'
                        }} />
                        <Button
                            size={'large'}
                            type={'text'}
                            className={'menu-btn'}
                            danger
                            icon={<LogoutIcon />}
                            iconPosition={'end'}
                            onClick={() => navigate('/logout')}
                        >Keluar</Button>
                    </div>
                </>
            )}
        </div>
    )
}