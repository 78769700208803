import { Alert, Modal, Typography } from "antd";
import { useEffect, useState } from "react";
import request from "utils/request";

export default function SendToAdmin3Dialog({open, orderId, adminName, onClose, onChanged}) {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)

    const send = () => {
        setError(false)
        setLoading(true)
        request('/order/sendToAdmin3', 'POST', {
            orderId
        }).then(() => {
            onClose()
            setError(false)
            window.location.reload()
        }).catch(err => {
            setError(err.message || err)
        }).finally(() => {
            setLoading(false)
            onChanged()
        })
    }

    useEffect(() => {
        setError(null)
    }, [open])

    return (
        <Modal
            open={open}
            onCancel={onClose}
            width={350}
            closable={!loading}
            centered
            title='Lanjutkan Pesanan'
            cancelText='Batal'
            okText='Kirim'
            cancelButtonProps={{
                disabled: loading
            }}
            okButtonProps={{
                loading
            }}
            onOk={send}
        >
            {error && (
                <Alert
                    type='error'
                    message={error}
                    showIcon
                    style={{
                        marginBottom: '15px'
                    }}
                />
            )}
            <Typography>Anda yakin ingin mengirimkan pesanan ke <b>{adminName}</b> ?</Typography>
        </Modal>    
    )
}