function decodeRupiah(rupiahString) {
    const regex = /\d+/g;
    // Menggunakan match() untuk mencari semua kecocokan dengan regular expression di paragraf
    const hasil = rupiahString.match(regex);
    // Jika ada hasil, mengembalikan array angka, jika tidak, mengembalikan null
    if(!hasil) return 0
    return hasil !== 0 ? parseInt(hasil.map(Number).join('')) : 0;
}

// Fungsi untuk mengubah number menjadi string rupiah
function encodeRupiah(number) {
    if(!number) {
        return 'Rp. 0'
    }
    return number.toLocaleString('id-ID', {
        style: 'currency',
        currency: 'IDR'
    }).split(',')[0];
}


export default {
    decode: decodeRupiah,
    encode: encodeRupiah
}