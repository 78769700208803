import HomeContainer from "element/HomeContainer";
import { Helmet } from "react-helmet";
import conf from 'conf'
import './index.scss'
import { Button, Typography } from "antd";
import { useNavigate } from "react-router";

export default function NotFound() {

    const navigate = useNavigate()

    return (
        <HomeContainer name='not-found'>
            <Helmet>
                <title>404 Page not found - {conf.appName}</title>
                <meta name="description" content={conf.metas.find(x => x.url === 'not_found').description} />
                <meta name="keywords" content={conf.metas.find(x => x.url === 'not_found').keywords} />
            </Helmet>
            <div className="head-404">
                <Typography className="title">Oops, Halaman Tidak Ditemukan</Typography>
                <Typography className="description">Tenang... ini hanyalah kesalahan teknis yang tidak disengaja. Apabila ini adalah halaman yang Anda cari, kami mohon maaf atas ketidaknyamanannya.</Typography>

                <Button className="back-to-homepage"
                type='dashed'
                onClick={() => navigate('/')}
                >Kembali ke Homepage</Button>
            </div>
        </HomeContainer>
    )
}