import navigations from "./navigations"
import { Link } from "react-router-dom"
import './index.scss'
import { Divider } from "antd"
import medias from "./medias"
import conf from "conf"

export default function Footer() {
    return (
        <>
            <div className="footer element">
                <div className="footer-top">
                    <div className="footer-top-container">
                        <div className="navigations">
                            {navigations.map((navigation, i) => (
                                <div className="navigation" key={i} data-name={navigation[0]}>
                                    <div className="title">{navigation[0]}</div>
                                    <div className="links">
                                        {navigation[1].map((link, i) => (
                                            <Link className="link" key={i} to={link.url}>{link.name}</Link>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <Divider className="divider" />
                </div>
                <div className="footer-bottom">
                    <div className="left">
                        <div className="follow-us">
                            <div className="title">Ikuti Kami</div>
                            <div className="medias">
                                {medias.map((media, i) => (
                                    <a href={media.url} className="media" key={i} target="_blank">{media.icon}</a>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-copyright">
                {conf.appName} All Rights Reserved © 2024
            </div>
        </>
    )
}