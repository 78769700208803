import {
    Modal,
    Input,
    Button,
    Typography,
    Card,
    Checkbox
} from 'antd'
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { useState, useEffect } from 'react';
import request from 'utils/request';
import rupiah from 'utils/rupiah';


let pricesDefault = [
    {
        for: "Biaya Pengiriman",
        amount: 150000,
        min: false
    },
]

if(window.location.href.includes('beliexpress.com')) {
    pricesDefault.push( {
        for: "Biaya Layanan",
        amount: 12000,
        min: false,
    })
}

export default function SetHarga({ open, onClose, orderId, onReload, order }) {

    const [loading, setLoading] = useState(false)
    const [prices, setPrices] = useState([])
    const [error, setError] = useState(null)
    const [productPricesSatuan, setProductPriceSatuan] = useState(0)
    const [total, setTotal] = useState(0)


    useEffect(() => {
        setError(prices.filter(x => x.for.trim().length < 1).length > 0)
    }, [prices])

    useEffect(() => {
        let _price = productPricesSatuan * order.quantity
        for (const price of prices) {
            if (price.min) {
                _price -= price.amount
            } else {
                _price += price.amount
            }
        }
        setTotal(_price)
    }, [productPricesSatuan, prices])

    useEffect(() => {
        if (open) {
            request('/order/prices?orderId=' + orderId).then(data => {
                setPrices(data)
                if(data.length < 1) {
                    let x = [...pricesDefault]
                    if(order.repacking) {
                        x.push({
                            for: 'Biaya Repacking',
                            amount: 12500,
                            min: false
                        })
                    }
                    setPrices(x)
                }
            }).catch(err => {
                console.error(`Failed for get the prices: ${err.toString()}`)
            }).finally(() => {
                
            })
            

            request('/order/unitPrice?orderId='+orderId).then(data => {
                setProductPriceSatuan(data.price)
            }).catch(err => console.error(`Failed for get the unit price: ${err.toString()}`))
        } else {
            
            setPrices([])
        }
        setProductPriceSatuan(0)
        setError(false)
        
    }, [open])

    const savePrices = () => {
        setLoading(true)
        request('/order/changePrices', 'POST', {
            prices,
            orderId,
            unitPrice: productPricesSatuan,
        }).then(() => {
            onClose()
            onReload()
        }).catch(err => {
            console.error(`Failed for save the prices: ${err.message || err}`)
        }).finally(() => setLoading(false))
    }
    
    return (
        <Modal title='Atur Harga' open={open} destroyOnClose
            centered
            className='set-harga-new-order-modal'
            width={600}
            cancelText='Batal'
            cancelButtonProps={{
                danger: true,
                loading: loading
            }}
            okText='Simpan'
            onCancel={onClose}
            okButtonProps={{
                disabled: error,
                loading: loading
            }}
            onOk={savePrices}
        >
            {/* <Alert type='info' message='Masukkan detail tanpa kode voucer, pengurangan kode voucer dilakukan secara otomatis' showIcon /> */}
            <div className="prices">
                {prices.map((price, i) => (
                    <PriceItem price={price} key={i} onChange={value => {
                        let newPrices = [...prices]
                        newPrices[i] = value
                        setPrices(newPrices)
                    }}
                        onDelete={() => setPrices([...prices.slice(0, i), ...prices.slice(i + 1)])}
                    />
                ))}
            </div>
            <Button className='add-btn'
                type='primary'
                icon={<AddIcon />}
                size='small'
                loading={loading}
                onClick={() => setPrices([...prices, {
                    min: false,
                    amount: 0,
                    for: ''
                }])}
            />
            <div style={{
                marginTop: '10px',
            }}>
                <Typography style={{
                    fontWeight: 500,
                    fontSize: '13px'
                }}>Harga Satuan Produk</Typography>
                <Input
                    loading={loading}
                    value={rupiah.encode(productPricesSatuan)}
                    onChange={e => setProductPriceSatuan(rupiah.decode(e.target.value.split('.').join('')))}
                />
            </div>
            <div style={{ marginTop: '10px' }}>
                <Typography>Total (sementara): {rupiah.encode(total).split('-').join('- ')}</Typography>
            </div>
        </Modal>
    )
}

function PriceItem({ price, onChange, onDelete, loading }) {
    return (
        <Card size='small' className='price-item'>
            <div className="price-item">
                <div className="form-control">
                    <Typography className='title'>Minus</Typography>
                    <Checkbox
                        checked={price.min}
                        disabled={loading}
                        onChange={e => {
                            let _price = price
                            _price.min = e.target.checked
                            onChange(_price)
                        }}
                    />
                </div>
                <div className="form-control">
                    <Typography className='title'>Keterangan</Typography>
                    <Input placeholder='Tulis Keterangan'
                        disabled={loading}
                        className='input'
                        value={price.for}
                        onChange={e => {
                            let _price = price
                            _price.for = e.target.value
                            onChange(_price)
                        }}
                    />
                </div>
                <div className="form-control">
                    <Typography className='title'>Harga</Typography>
                    <Input
                        placeholder=''
                        value={`${price.min ? '- ' : ''}${rupiah.encode(price.amount)}`}
                        disabled={loading}
                        onChange={e => {
                            price.amount = rupiah.decode(e.target.value.split('.').join(''))
                            onChange(price)
                        }}
                    />
                </div>
                <Button loading={loading} className='delete-btn' icon={<DeleteIcon />} type='text' onClick={onDelete} />
            </div>
        </Card>
    )
}