import { Modal, Typography } from "antd";

export default function SuccessAlert({open, onClose}) {
    return (
        <Modal
            open={open}
            centered
            width={400}
            onCancel={onclose}
            onOk={onClose}
            okText='Tutup'
            cancelButtonProps={{
                style: {
                    display: 'none'
                }
            }}
            title='Berhasil'
        >
            <Typography>Terima kasih atas saran / kritik yang Anda berikan. Masukan Anda sangat berharga bagi kami untuk terus meningkatkan layanan.</Typography>
        </Modal>
    )   
}