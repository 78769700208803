import { Modal, Select } from "antd";
import { useEffect, useState } from "react";
import request from "utils/request";

export default function ChangeStatus({ open, onClose, onChanged, orderId, currentStatus, roles }) {
    const [status, setStatus] = useState(currentStatus)
    const [statuses, setStatuses] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (open) {
            // get all statuses by roles and role
            Promise.all(roles.map(role => {
                return new Promise(resolve => {
                    request(`/order/allStatus?from=${role}`).then(data => {
                        resolve(data)
                    }).catch(err => {
                        console.error(err);
                        resolve([])
                    })
                })
            }))
                .then(data => {
                    let _statuses = []
                    // Get status per role and role
                    for (const statuses of data) {
                        for (const status of statuses) {
                            const i = _statuses.findIndex(x => x.key === status.key)
                            if (i < 0) {
                                _statuses.push(status)
                            }
                        }
                    }
                    setStatuses(_statuses)
                })
        }
    }, [open])

    useEffect(() => {
        open && setStatus(currentStatus)
    }, [open, currentStatus])
    
    const save = () => {
        setLoading(true)
        request('/order/changeStatus', 'POST', {
            orderId,
            statusKey: status
        }).then(data => {
            onChanged()
            onClose()
        }).catch(err => {
            console.error(`Failed for change status: ${err.message || err}`)
        }).finally(() => {
            setLoading(false)
        })
    }

    return (
        <Modal
            className="change-status-order-modal"
            open={open}
            onCancel={onClose}
            centered
            width={350}
            title='Ubah Status'
            okText='Simpan'
            cancelText='Tutup'
            okButtonProps={{
                loading
            }}
            cancelButtonProps={{
                disabled: loading
            }}
            onOk={save}
        >
            <Select
                value={status}
                onChange={e => setStatus(e)}
                disabled={loading}
                className="select"
            >{statuses.map((status, i) => (
                <Select.Option key={i} value={status.key}>{status.name}</Select.Option>
            ))}</Select>
        </Modal>
    )
}