import { Button, Input, Select, Tabs } from "antd";
import conf from "conf";
import MainAppContainer from "element/MainAppContainer";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import request from "utils/request";
import './index.scss'
import { useLocation, useNavigate } from "react-router";
import SearchIcon from '@mui/icons-material/Search';
import OrderItem from './OrderItem'
import encode, { decode } from "urlencode";

const allStatus = {
    name: "Semua",
    key: ""
}

let searchTimeout, filterStatusInterval

export default function Order() {
    const location = useLocation()
    const [statuses, setStatuses] = useState([allStatus])
    const [status, setStatus] = useState(undefined)
    const [search, setSearch] = useState(
        new URLSearchParams(window.location.search).has('search') ? new URLSearchParams(window.location.search).get('search') : ''
    )
    const [roles, setRoles] = useState([])
    const [page, setPage] = useState(1)
    const [loading, setLoading] = useState(false)
    const [hasMore, setHasMore] = useState(false)
    const [orders, setOrders] = useState([])
    const [finished, setFinished] = useState(true)
    const [isPrivate, setIsPrivate] = useState(false) // default is public

    const navigate = useNavigate()

    useEffect(() => {
        filterStatusInterval = setInterval(() => {
            fetchStatuses()
        }, 6000);
    }, [])

    const fetchOrders = (clear = false) => {
        if (roles.length < 1) { return }
        if (loading) { return }
        setLoading(true)
        const role = roles[0] || 'general'
        const url = `/order/list2?search=${search}&page=${page}&from=${role}&status=${status}&finished=${finished ? 1 : 0}`
        request(url).then(data => {
            setIsPrivate(data.isPrivate)
            if (clear) {
                setOrders(data.orders)
            } else {
                let _orders = clear ? [] : [...orders]
                for (const item of data.orders) {
                    const i = _orders.findIndex(x => x.orderId === item.orderId)
                    if (i < 0) {
                        _orders.push(item)
                    }
                }
                setOrders(_orders)
            }
            setHasMore(data.orders.length > 0)
        }).catch(err => console.error(`Failed for get the order list: ${err.message || err}`))
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        setOrders([])
        setHasMore(true)
        setPage(1)
        fetchOrders(true)
    }, [status, finished])

    useEffect(() => {
        if(searchTimeout) { clearTimeout(searchTimeout) }
        

        searchTimeout = setTimeout(() => {
            setOrders([])
            setHasMore(true)
            setPage(1)
            fetchOrders(true)

            const searchParams = new URLSearchParams(location.search)
            searchParams.set('search', encode(search))
            navigate(`/order?${searchParams.toString()}`)
        }, 500)

        return () => clearTimeout(searchTimeout)
    }, [search])

    useEffect(() => {
        fetchOrders()
    }, [page, roles])

    useEffect(() => {
        request('/user/roles').then(data => {
            setRoles(data.roles)
        }).catch(err => console.error(`Failed for get the roles: ${err.message || err}`))
        fetchOrders()
    }, [])

    useEffect(() => {
        const search = new URLSearchParams(location.search)
        if (status === undefined) {
            // Cek apakah ada di url nya 
            if (search.has('tab')) { setStatus(search.get('tab')) }
            else { setStatus(allStatus.key) }
        }
    }, [status, location])

    useEffect(() => {
        const search = new URLSearchParams(location.search)
        if(search.has('search')) {
            setSearch(decode(search.get('search')))
        }
    }, [])

    useEffect(() => {
        if (typeof status === 'string') {
            const search = new URLSearchParams(location.search)
            search.set('tab', status)
            const url = location.pathname + "?" + search.toString()
            navigate(url)
        }
    }, [status])

    const fetchStatuses = () => {
        let tasks = roles.map((role) => {
            return new Promise(r => {
                request(`/order/filterStatus?as=${role}&finished=${finished ? 1 : 0}`).then(data => r(data)).catch(err => r([]))
            })
        })
        Promise.all(tasks)
        .then(data => {
            let x = []
            for(const row of data) {
                for(const item of row) {
                    const i = x.findIndex(x => x.key === item.key)
                    if(i<0) {
                        x.push(item)
                    }
                }
            }
            x.length > 0 && setStatuses(x)
        }).catch(err => console.error(`Failed for get the statuses: ${err.message || err}`))
    }

    useEffect(() => {
        fetchStatuses()
    }, [roles, finished])

    const refreshOrderItem = orderId => {
        const role = roles[0] || "general"
        request(`/order/list2?search=${orderId}&page=1&status=&from=${role}`).then(data => {
            const item = data.orders[0]
            if(item) {
                let _orders = [...orders]
                const i = _orders.findIndex(x => x.orderId === item.orderId)
                if(i>=0) {
                    _orders[i] = item
                }
                setOrders(_orders)
            }
        }).catch(err => console.error(err.message||err))
    }

    return (
        <MainAppContainer name='order'>
            <Helmet>
                <title>{conf.appName} - Pesanan</title>
            </Helmet>
            <Tabs
                items={statuses.map((x, i) => {
                    return {
                        key: x.key,
                        label: x.name
                    }
                })}
                className="status-tab"
                activeKey={status}
                onChange={e => setStatus(e)}
            />
            <div className="order-header">
                <Input
                    size='large'
                    placeholder='Kamu bisa cari berdasarkan No. Pesanan'
                    prefix={<SearchIcon className="search-icon" />}
                    className="search"
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                />
                {/* {(roles.includes('admin1') || roles.includes('admin2') || roles.includes('admin3')) && !isPrivate && (
                    <Select
                        value={finished}
                        onChange={e => setFinished(e)}
                        size='large'
                        className="finish-status-select"
                    >
                        <Select.Option value={false}>Belum Selesai</Select.Option>
                        <Select.Option value={true}>Selesai</Select.Option>
                    </Select>
                )} */}
            </div>
            <div className="orders">
                {orders.map((order, i) => {
                    return <OrderItem
                        key={i}
                        data={order}
                        role={roles[0] || 'general'}
                        roles={roles}
                        onUpdate={() => {
                            refreshOrderItem(order.orderId)
                        }}
                        isPrivate={isPrivate}
                    />
                })}
            </div>
            {hasMore && (
                <Button
                    size='large'
                    type='primary'
                    loading={loading}
                    className="load-more-btn"
                    onClick={() => setPage(x => x + 1)}
                >Muat Lainnya</Button>
            )}
        </MainAppContainer>
    )
}