import { Badge, Button, Tooltip, Typography } from "antd";
import moment from 'moment-timezone'
import 'moment/locale/id'
import { useNavigate } from "react-router";
import request from "utils/request";

export default function NotificationItem({ data }) {

    const navigate = useNavigate()

    const markAsRead = () => {
        if(data.read) { return }
        request('/notification/markAsRead', 'POST', { id: data.id }).then(data => {
            console.log(data)
        }).catch(err => console.error(`Failed for mark as read notification: ${err.message || err}`))
    }

    return (
        <Badge
            count={data.read ? 0 : 1}
            offset={[-15, 15]}
            className="notification-item-badge"
            size='small'
        >
            <Button
                className="notification-item"
                type='text'
                onClick={() => {
                    markAsRead()
                    if(typeof data.url === 'string') {
                        window.location.href = data.url
                    }
                }}
            >
                <Typography className="title">{data.title}</Typography>
                {data.description && (
                    <Typography.Paragraph className="description">{data.description}</Typography.Paragraph>
                )}
                <Tooltip title={moment(data.epoch * 1000).tz('Asia/Jakarta').fromNow()}
                    arrow={false}
                >
                    <div className="date">{moment(data.epoch * 1000).tz('Asia/Jakarta').format('DD MMM YYYY HH:mm')}</div>
                </Tooltip>
            </Button>
        </Badge>
    )
}