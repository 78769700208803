import { Modal, Typography } from "antd";

export default function CopiedAlert({open, onClose}) {
    return (
        <Modal title='Berhasil'
            width={350}
            open={open}
            onCancel={onClose}
            onOk={onClose}
            centered
            okButtonProps={{
                style: {
                    display: 'none'
                }
            }}
            cancelButtonProps={{
                type: 'primary'
            }}
            cancelText='Tutup'
        >
            <Typography className="text">
                ID Pesanan telah berhasil disalin ke clipboard
            </Typography>
        </Modal>
    )
}