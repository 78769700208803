import { Typography } from "antd";
import courierSimple from 'dist/ilustration/courier-simple.png'

export default function TargetMarket() {
    return (
        <div className="target-market">
            <div className="right">
                <img src={courierSimple} alt="Pesan Produk Impianmu" />
            </div>
            <div className="left">
                <Typography className="title">Pesan Produk Impianmu</Typography>
                <Typography className="desc">Ingin belanja di Aliexpress? Apapun kebutuhan Anda - apakah Anda seorang dropshipper, pecinta gadget, fashion enthusiast, kolektor, atau lainnya. Kami akan membantu Anda mendapatkan produk impian dengan mudah dan cepat. Biarkan kami mengurus prosesnya, sehingga Anda bisa menikmati barang favorit tanpa repot.</Typography>
            </div>
        </div>
    )
}