import HomeContainer from "element/HomeContainer";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import request from "utils/request";
import Sidebar from './Sidebar'
import './index.scss'
import { Card, Spin } from "antd";


export default function MainAppContainer({children, name}) {
    const [authenticated, setAuthenticated] = useState(true)
    const location = useLocation()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [roles, setRoles] = useState([])

    useEffect(() => {
        setLoading(true)
        // Check the session status, if not authenticated: redirect to the main page with the login dialog open
        request('/session/isValid').then(data => {
            if(!data.valid) {
                authenticated(false)
            } else {
                request('/user/roles').then(data => {
                    setRoles(data.roles)
                    setAuthenticated(true)
                }).catch(err => {
                    console.error(`Failed for get profile: ${err.message || err}`)
                    setAuthenticated(false)
                })
            }
        }).catch(err => {
            console.error(`Failed for detect the login state: ${err.message || err}`)
            setAuthenticated(false)
        }).finally(() => setLoading(false))
    }, [location.pathname])

    useEffect(() => {
        if(!authenticated) {
            navigate('/?showLogin=1')
        }
    }, [authenticated])

    return (
        <HomeContainer name={name}>
            <div className="main-app-container" data-name={name}>
                <Sidebar />
                <Card className="right-side">
                    {loading && (
                        <Spin
                            size='large'
                            className='main-app-spinner'
                        />
                    )}
                    {!loading && (
                        <>{children}</>
                    )}
                </Card>
            </div>
        </HomeContainer>
    )
}