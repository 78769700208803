import { BrowserRouter, Routes, Route } from "react-router-dom"
import Home from './pages/home'
import { ConfigProvider } from "antd"
import Register from "./pages/register";
import Login from "./pages/login";
import Logout from "./pages/logout";
import Profile from "pages/profile";
import Address from "pages/address";
import Order from "pages/order";
import OrderDetail from "pages/order-detail";
import NewOrder from "pages/new-order";
import ManageUser from "pages/manage-user";
import Chat from "pages/chat";
import FAQ from "pages/faq";
import ChatBtnFloat from "element/ChatBtnFloat";
import RedirectToNonWWW from "utils/redirectToNonWWW";
import MetaHandler from "utils/MetaHandler";
import NotFound from "pages/not-found";
import PrivatePembukuan from "pages/private-pembukuan";
import PrivateOMS from "pages/private-oms";
import PrivateProfitOMS from "pages/private-profit-oms";
import PrivateProfitBarang from "pages/private-profit-barang";
import Voucer from "pages/voucer";
import NotificationPopupListener from "element/NotificationPopupListener";
import Aduan from "pages/aduan";
import KritikDanSaran from "pages/kritik-dan-saran";
import SyaratDanKetentuan from "pages/syarat-dan-ketentuan";

export default function App() {

    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: "#EB1C24",
                    fontFamily: "Poppins"
                }
            }}
        >
            <BrowserRouter>
                <RedirectToNonWWW />
                <MetaHandler />
                <ChatBtnFloat />
                <NotificationPopupListener />
                <Routes>
                    <Route path='/' element={<Home />} />
                    <Route path='/register' element={<Register />} />
                    <Route path='/login' element={<Login />} />
                    <Route path='/logout' element={<Logout />} />
                    <Route path='/profile' element={<Profile />} />
                    <Route path='/address' element={<Address />} />
                    <Route path='/order' element={<Order />} />
                    <Route path='/order-detail' element={<OrderDetail />} />
                    <Route path='/new-order' element={<NewOrder />} />
                    <Route path='/manage-user' element={<ManageUser />} />
                    <Route path='/chat' element={<Chat />} />
                    <Route path='/faq' element={<FAQ />} />
                    <Route path='/private-pembukuan' element={<PrivatePembukuan />} />
                    <Route path='/private-oms' element={<PrivateOMS />} />
                    <Route path='/private-profit-oms' element={<PrivateProfitOMS />} />
                    <Route path='/private-profit-barang' element={<PrivateProfitBarang />} />
                    <Route path='/voucer' element={<Voucer />} />
                    <Route path='/aduan' element={<Aduan />} />
                    <Route path='/kritik-dan-saran' element={<KritikDanSaran />} />
                    <Route path='/syarat-dan-ketentuan' element={<SyaratDanKetentuan />} />
                    <Route path='/*' element={<NotFound />} />
                </Routes>
            </BrowserRouter>
        </ConfigProvider>
    )
}