import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TelegramIcon from '@mui/icons-material/Telegram';


const medias = [
    {
        name: "Facebook",
        url: "https://web.facebook.com/profile.php?id=100093462541856",
        icon: <FacebookRoundedIcon className='icon' />
    },
    {
        name: "Instagram",
        url: "https://www.instagram.com/ziqvalab/",
        icon: <InstagramIcon className='icon' />
    },
    {
        name: "WhatsApp",
        url: "https://wa.me/6281230996146",
        icon: <WhatsAppIcon className='icon' />
    },
    {
        name: "YouTube",
        url: "https://youtube.com/@ZiqvaLabs",
        icon: <YouTubeIcon className='icon' />
    },
    {
        name: "Telegram",
        url: "https://tttttt.me/ziqvalabs",
        icon: <TelegramIcon className='icon' />
    }
]

export default medias