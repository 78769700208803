import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Alert, Button, Card, Divider, Dropdown, Image, Timeline } from 'antd';
import Typography from 'antd/es/typography/Typography';
import { useLocation, useNavigate } from 'react-router';
import moment from 'moment-timezone'
import 'moment/locale/id'
import CopyToClipboard from 'react-copy-to-clipboard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CopiedAlert from 'pages/order/CopiedAlert';
import { useEffect, useState } from 'react';
import request from 'utils/request';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import formatRupiah from 'utils/formatRupiah';
import CancelModal from './CancelModal';
import PayModal from './PayModal'
import conf from 'conf';

const paymentMethods = [
    'BANK JAGO'
]

export default function Detail({ data, onReload }) {

    const location = useLocation()
    const navigate = useNavigate()
    const [copiedAlertOpen, setCopiedAlertOpen] = useState(false)
    const [addresses, setAddresses] = useState([])
    const [cancelOpen, setCancelOpen] = useState(false)
    const [payModalOpen, setPayModalopen] = useState(false)

    const changeAddress = (id) => {
        request('/order/changeAddress', 'POST', {
            orderId: data.orderId,
            addressId: id
        }).then(() => {
            onReload()
        }).catch(err => console.error(`Failed for change the address: ${err.message || err}`))
    }

    useEffect(() => {
        if (data && data.canChangeAddress) {
            request('/user/addresses').then(data => {
                setAddresses(data)
            }).catch(err => console.error(`Failed for get the addresses: ${err.message || err}`))
        }
    }, [data])

    const changePaymentMethod = bank => {
        request('/order/changePaymentMethod', 'POST', {
            orderId: data.orderId,
            paymentMethod: bank
        }).then(() => {
            onReload()
        }).catch(err => console.error(`Failed for change the payment method: ${err.message || err}`))
    }

    return data && (
        <>
            <CancelModal
                orderId={data.orderId}
                open={cancelOpen}
                onCancel={onReload}
                onClose={() => setCancelOpen(false)}
            />
            <PayModal
                open={payModalOpen}
                onClose={() => setPayModalopen(false)}
                orderId={data.orderId}
                onPaid={onReload}
                paymentMethod={data.paymentMethod}
            />
            <CopiedAlert
                open={copiedAlertOpen}
                onClose={() => setCopiedAlertOpen(false)}
            />
            <div className="back-btn"
                onClick={() => navigate('/order')}
            >
                <ArrowBackIosIcon className='icon' />
                <Typography className='text'>Kembali</Typography>
            </div>
            {/* Warning rejection time by waiting payment */}
            {data.timeoutExpired !== null && (
                <Alert
                    type='warning'
                    message={(
                        <Typography>Silahkan selesaikan pembayaran sebelum <b>{moment(data.timeoutExpired * 1000).tz("Asia/Jakarta").format('dddd, DD MMMM YYYY HH:mm')}</b>.</Typography>
                    )}
                    showIcon
                    style={{
                        marginTop: '15px'
                    }}
                />
            )}
            {/* Transaction card */}
            <Card className='detail-card transaction'>
                <div className="head">
                    <Typography className='title'>Transaksi</Typography>
                </div>
                <Divider className='divider' />
                <div className="body">
                    <div className="row">
                        <div className="left">No Pesanan</div>
                        <div className="right cursor-pointer">
                            <div className="text">{data.orderId || ""}</div>
                            <CopyToClipboard
                                onCopy={() => setCopiedAlertOpen(true)}
                                text={data.orderId || ""}
                            >

                                <ContentCopyIcon className='icon' />
                            </CopyToClipboard>
                        </div>
                    </div>
                    <div className="row">
                        <div className="left">Waktu Pemesanan</div>
                        <div className="right">{moment(data.created * 1000).tz("Asia/Jakarta").format('dddd, DD MMMM YYYY HH:mm')}</div>
                    </div>
                </div>
            </Card>
            {/* Track */}
            <Card className='detail-card tracks'>
                <div className="head">
                    <Typography className='title'>Lacak Pesanan</Typography>
                </div>
                <Divider className='divider' />
                <div className="body">
                    <Timeline
                        mode='left'
                        className='timeline'
                        items={data.tracks.map((track) => {
                            return {
                                label: <Typography className='date'>{moment(track.created * 1000).tz('Asia/Jakarta').format('dddd, DD MMMM YYYY HH:mm')}</Typography>,
                                children: (
                                    <div className="track-detail">
                                        <Typography className='title'>{track.title}</Typography>
                                        {track.description && (
                                            <Typography className='desc' dangerouslySetInnerHTML={{ __html: track.description }}></Typography>
                                        )}
                                    </div>
                                )
                            }
                        })}
                    />
                </div>
            </Card>
            {/* Address */}
            <Card className='detail-card address'>
                <div className="head">
                    <Typography className='title'>Alamat Penerima</Typography>
                </div>
                <Divider className='divider' />
                <div className="body">
                    <Typography className='name'>{data.targetAddress.name}</Typography>
                    <Typography className='desc'>{data.targetAddress.province}/{data.targetAddress.regency}/{data.targetAddress.district}/{data.targetAddress.village}{
                        data.targetAddress.additionalAddress ? `\n${data.targetAddress.additionalAddress}` : ''
                    }</Typography>
                    {data.canChangeAddress && (
                        <Dropdown trigger={['click']} menu={{
                            items: addresses.map((x, i) => {
                                return {
                                    key: x.id,
                                    label: x.name,
                                    icon: <LocationOnIcon />,
                                    onClick: () => changeAddress(x.id)
                                }
                            })
                        }}>
                            <Button
                                size='large'
                                className='change-address-btn'
                            >Pilih Alamat lain</Button>
                        </Dropdown>
                    )}
                </div>
            </Card>
            {/* Product */}
            <Card className='detail-card product'>
                <div className="body">
                    <div className="row">
                        {data.product.images[0] && (
                            <Image
                                src={data.product.images[0]}
                                alt={data.product.name}
                                title={data.product.name}
                                className='product-image'
                                preview={false}
                                onClick={() => window.open(data.product.images[0])}
                            />
                        )}
                        <div className="info">
                            <Typography className='name'>{data.product.name}</Typography>
                            <Typography className='quantity'>{data.quantity} pcs{data.unitPrice > 0 && ` x ${formatRupiah(data.unitPrice)}`}</Typography>
                            {data.unitPrice > 0 && (
                                <div className="price">{formatRupiah(data.unitPrice * data.quantity)}</div>
                            )}
                        </div>
                    </div>
                    {data.unitPrice > 0 && (
                        <>
                            <Divider className='divider' />
                            <div className="order-footer">
                                <div className="left">Total Pesanan Produk</div>
                                <div className="right">{formatRupiah(data.unitPrice * data.quantity)}</div>
                            </div>
                        </>
                    )}
                </div>
            </Card>
            {/* Payment Method */}
            <Card className='detail-card payment-method'>
                <div className="body">
                    <div className="row">
                        <div className="left">
                            <Typography className='text'>Metode Pembayaran</Typography>
                            {data.paymentMethod && (
                                <>
                                    <div className="row">
                                        <div className="left">
                                            <Typography style={{
                                                color: '#155724'
                                            }}>{data.paymentMethod}</Typography>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="left">
                                            <Typography style={{
                                                color: '#155724'
                                            }}>A.n {conf.banks.find(x => x.name === data.paymentMethod).detail}</Typography>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="right">
                            <Dropdown trigger={['click']} menu={{
                                items: paymentMethods.map((x, i) => {
                                    return {
                                        key: i,
                                        label: x,
                                        onClick: () => changePaymentMethod(x)
                                    }
                                })
                            }}>
                                <Button
                                    type='primary'
                                    className='payment-method-btn'
                                    size='large'
                                >{data.paymentMethod ? 'Ubah Metode' : 'Pilih Metode'}</Button>
                            </Dropdown>
                        </div>
                    </div>

                </div>
            </Card>
            {/* Prices */}
            {data.priceToPay && (
                <Card className='detail-card prices'>
                    <div className="head">
                        <Typography className='title'>Rincian Biaya</Typography>
                    </div>
                    <Divider className='divider' />
                    <div className="prices">
                        {data.prices.map((price, i) => (
                            <div className="price" key={i}>
                                <div className="left">{price.for}</div>
                                <div className="right">{price.min ? '- ' : ''}{formatRupiah(price.amount)}</div>
                            </div>
                        ))}
                    </div>
                    <Divider className='divider' />
                    <div className="prices">
                        <div className="price">
                            <div className="left">Total Pemesanan Produk</div>
                            <div className="right">{formatRupiah(data.unitPrice * data.quantity)}</div>
                        </div>
                        <div className="price">
                            <div className="left">Total Yang Harus Dibayar (All-in)</div>
                            <div className="right">{formatRupiah(data.priceToPay)}</div>
                        </div>
                    </div>
                </Card>
            )}
            {/* Actions */}
            <Card className='detail-card actions'>
                <div className="body">
                    <div className="row">
                        <div className="right">
                            <Button
                                size='large'
                                disabled={Boolean(data.paymentImage) || !data.canCancel}
                                onClick={() => setCancelOpen(true)}
                                onCancel={onReload}
                            >Batalkan Transaksi</Button>
                            <Button
                                size='large'
                                type='primary'
                                onClick={() => {
                                    if (data.paymentImage) {
                                        window.open(`${conf.backendUrl}/file/${data.paymentImage}`, '_blank')
                                    } else {
                                        setPayModalopen(true)
                                    }
                                }}
                                disabled={data.paymentMethod === null || (data.priceToPay === null)}
                            >{data.paymentImage ? 'Lihat Bukti Pembayaran' : 'Bayar Sekarang'}</Button>
                        </div>
                    </div>
                </div>
            </Card>
        </>
    )
}