import { Modal, Timeline } from "antd";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import request from "utils/request";
import 'moment/locale/id'

export default function TrackView({open, onClose, orderId}) {
    const [timelines, setTimelines] = useState([])
    const [loading, setLoading] = useState(false)
    
    useEffect(() => {
        if(open) {
            setLoading(true)
            request('/order/timelines', 'POST', { orderId }).then(data => {
                setTimelines(data)
            }).catch(err => console.error(`Failed for get the timelines: ${err.toString()}`))
            .finally(() => setLoading(false))
        } else {
            setTimelines([])
        }
    }, [open])

    return (
        <Modal title='Tracks' open={open}
            onCancel={onClose}
            centered
            width={400}
            okButtonProps={{
                style: {
                    display: 'none'
                }
            }}
            cancelButtonProps={{
                danger: true,
                type: "primary"
            }}
            cancelText='Tutup'
        >
            <div className="timelines-container">
                <Timeline mode='left' items={timelines.map((timeline, i) => {
                    return {
                        label: moment(timeline.created * 1000).tz('Asia/Jakarta').format('  D MMMM YYYY HH:mm'),
                        children: (
                            <div className="timeline-info">
                                <div className="title" style={{
                                    fontSize: '14px',
                                    fontWeight: 600
                                }}>{timeline.title}</div>
                                {timeline.description && (
                                    <div className="description" style={{
                                        fontSize: '12px',
                                        fontWeight: 400
                                    }} dangerouslySetInnerHTML={{ __html: timeline.description }}></div>
                                )}
                            </div>
                        )
                    }
                })} />
            </div>
        </Modal>
    )
}