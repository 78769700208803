import { Button, Card, Divider, Input, Typography } from "antd";
import { useEffect, useState } from "react";
import isValidHttpsUrl from "utils/isValidHttpsUrl";
import CloseIcon from '@mui/icons-material/Close';
import { useLocation, useNavigate } from "react-router";
import { decode } from "urlencode";

export default function OrderCustomForm({
    onProductName,
    onProps,
    onUrl,
    onQuantity    
}) {
    const [productName, setProductName] = useState('')
    const [props, setProps] = useState([])
    const [url, setUrl] = useState('')
    const [quantity, setQuantity] = useState(1)

    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        onProductName(productName)
        onProps(props)
        onUrl(isValidHttpsUrl(url) ? url : null)
        onQuantity(quantity)
    }, [url, quantity, props, productName])

    const handleRemoveVariant = index => {
        setProps(x => x.filter((_, i) => i !== index))
    }

    useEffect(() => {
        const search = new URLSearchParams(location.search)
        if(search.has('prodUrl')) {
            const url = decode(search.get('prodUrl'))
            setUrl(url)
            search.delete('prodUrl')
            navigate('/new-order?' + search.toString())
        }
    }, [location])



    return (
        <Card className="order-custom-form" size='small'>
            <div className="forms">
                <div className="row">
                    <div className="form-control">
                        <Typography className="label">Nama Produk</Typography>
                        <Input
                            type='text'
                            value={productName}
                            onChange={e => setProductName(e.target.value)}
                            placeholder='___'
                            size='large'
                            required
                            className="field"
                        />
                    </div>
                    <div className="form-control fit">
                        <Typography className="label">Kuantitas</Typography>
                        <Input
                            placeholder='___'
                            onChange={e => setQuantity(e.target.value)}
                            value={quantity}
                            size='large'
                            type='number'
                            style={{
                                width: '80px'
                            }}
                        />
                    </div>
                </div>

                <div className="form-control">
                    <Typography className="label">URl Produk</Typography>
                    <Input
                        value={url}
                        onChange={e => setUrl(e.target.value)}
                        placeholder="___"
                        size='large'
                        required
                    />
                </div>
                <Divider className="divider" />
                {/* Here's the properties card which contained the variant of product */}
                <div className="property">
                    <div className="properties">
                        {props.map((prop, i) => (
                            <Card className="property-item" key={i}
                                size='small'
                            >

                                <div className="property-item-container">
                                    <Input className="name"
                                        size='small'
                                        value={prop.name}
                                        placeholder='Nama. ex: Warna'
                                        onChange={e => {
                                            let _props = [...props]
                                            _props[i].name = e.target.value
                                            setProps(_props)
                                        }}
                                    />
                                    <Input className="value"
                                        size='small'
                                        value={prop.value}
                                        placeholder='ex: Biru'
                                        onChange={e => {
                                            let _props = [...props]
                                            _props[i].value = e.target.value
                                            setProps(_props)
                                        }}
                                    />

                                    <Button
                                        className="close-btn"
                                        size='small'
                                        type='text'
                                        onClick={() => handleRemoveVariant(i)}
                                        danger
                                        icon={<CloseIcon className="icon" />}
                                    />
                                </div>
                            </Card>
                        ))}
                    </div>
                    <Button
                        size='large'
                        type='primary'
                        onClick={() => {
                            setProps(x => [...x, {
                                name: "",
                                value: ""
                            }])
                        }}
                        className="add-variant-btn"
                        disabled={
                            props.findIndex(x => x.name.trim().length < 1) >= 0 ||
                            props.findIndex(x => x.value.trim().length < 1) >= 0
                        }
                    >{props.length < 1 ? "Inisialisasi Varian" : 'Tambahkan Varian'}</Button>
                </div>
            </div>
        </Card>
    )
}