import { Button, Card, Divider, Dropdown, FloatButton, Input, Typography, message } from "antd";
import conf from "conf";
import { useEffect, useRef, useState } from "react";
import { io } from 'socket.io-client'
import cookies from 'react-cookies'
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import request from "utils/request";
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import InfiniteScroll from "react-infinite-scroller";
import ChatItem from './ChatItem'
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import EndChatDialog from "./EndChatDialog";
import ImageIcon from '@mui/icons-material/Image';
import FormData from 'form-data'
let _socket


export default function Chat({ conversationId }) {
    const [messages, setMessages] = useState([])
    const [loading, setLoading] = useState(false)
    const [type, setType] = useState('')
    const [interlocutors, setInterlocutors] = useState(null)
    const [hasMore, setHasMore] = useState(true)
    const [page, setPage] = useState(1)
    const [visibleScrollToBottom, setVisibleScrollToBottom] = useState(false)
    const [ended, setEnded] = useState(false)
    const [endOpen, setEndOpen] = useState(false)
    const [imageLoading, setImageLoading] = useState(false)

    const sendImageInputRef = useRef()
    
    const handleSendImage = files => {
        const file = files[0]
        if(file) {
            setImageLoading(true)
            const fd = new FormData()
            fd.append('file', file)
            request(`/chat/${conversationId}/sendImage`, 'POST', fd).then(data => {
                
            }).catch(err => console.error(err.message || err)).finally(() => {
                setImageLoading(false)
            })
        }
    }

    useEffect(() => {
        if(conversationId) {
            request('/chat/isEnded', 'POST', { conversationId }).then(state => {
                setEnded(state)
            }).catch(err => console.error(err.message || err))
        }
    }, [messages, conversationId])

    const firstRequest = useRef(true)

    const refreshIsBottom = () => {
        const val = Math.abs(scrollerRef.current.scrollHeight - scrollerRef.current.clientHeight - scrollerRef.current.scrollTop) < 250
        setVisibleScrollToBottom(!val)
    }

    useEffect(() => {
        if(firstRequest.current) {
            if(messages.length > 0) {
                firstRequest.current = false
                scrollToBottom()
            }
        }
    }, [messages])

    const scrollerRef = useRef()
    const messageEndRef = useRef()

    const loadMore = async (init = false, p) => {
        setLoading(true)
        const _page = p ? p : page
        while (true) {
            if (_socket ? _socket.connected : false) { break } else {
                await new Promise(r => setTimeout(r, 100))
            }
        }

        _socket.once('messages', (_messages) => {
            let _ = _page === 1 ? [] : [...messages]
            for (const msg of _messages) {
                const i = _.findIndex(x => x.id === msg.id)
                if (i < 0) {
                    _.push(msg)
                }
            }
            if(conversationId) {
                setMessages(_)
            }
            setLoading(false)
            setHasMore(_messages.length > 0)
            
        })
        _socket.emit("get-message", { page: _page, session: cookies.load('session') })
    }

    const f = async() => {
        if (conversationId) {
            await new Promise(r => setTimeout(r, 1000))
            loadMore(false, 1)
        }
    }

    useEffect(() => {
        f()
    }, [conversationId])

    const getInterlocutors = () => {
        request('/chat/interlocutors', 'POST', { conversationId }).then(data => {
            setInterlocutors(data)
        }).catch(err => console.error(err.message || err))
    }


    useEffect(() => {
        if (conversationId) {
            setInterlocutors(null)
            const socket = io(conf.backendUrl, {
                query: `session=${cookies.load('session')}&conversationId=${conversationId}&type=message`,
                secure: window.location.href.includes('.com')
            })

            socket.on('new-message', msg => {
                setMessages(x => [msg, ...x])
                scrollToBottom()
            })

            _socket = socket
            getInterlocutors()
            scrollerRef.current.addEventListener('scroll', refreshIsBottom)
        }
        return () => {
            try {
                setLoading(false)
                setHasMore(true)
                setPage(1)
                setMessages([])
                firstRequest.current = true
                
                try {
                    _socket.disconnect()
                    _socket.removeAllListeners()
                } catch(err) {}
                try {
                    scrollerRef.current.removeEventListener('scroll', refreshIsBottom)
                } catch(err) {}
            } catch(err) {}
        }
        setInterlocutors(null)
    }, [conversationId])

    const sent = () => {
        const v = type.trim()
        if (v.length < 1) { return }
        setType('')
        _socket.emit('send-message', v)
    }

    const scrollToBottom = () => {
        messageEndRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
    }

    return (
        <Card
            className="chat-data"
        >
            <EndChatDialog 
                open={endOpen}
                onClose={() => setEndOpen(false)}
                conversationId={conversationId}
            />
            <div className="chat-header">
                <div className="profile">
                    <PersonOutlinedIcon className="icon" />
                    {interlocutors && (
                        <div className="info">
                            <Typography className="name">{interlocutors.name}</Typography>
                            <Typography className="email">{interlocutors.email}</Typography>
                        </div>
                    )}
                </div>
                <Dropdown
                    trigger={['click']}
                    menu={{
                        items: [
                            {
                                key: 1,
                                label: "Akhiri percakapan",
                                danger: true,
                                disabled: ended,
                                onClick: () => setEndOpen(true)
                            }
                        ]
                    }}
                >
                    <Button className="action-btn"
                        size='small'
                        type='text'
                        icon={<MoreVertOutlinedIcon className="icon" />}
                    ></Button>
                </Dropdown>
            </div>
            <Divider className="divider" />
            {visibleScrollToBottom && (
                <Button
                    icon={<ExpandCircleDownIcon className="icon" />}
                    className="scroll-down-btn"
                    shape='circle'
                    type='primary'
                    size='large'
                    onClick={scrollToBottom}
                />
            )}
            <div className="chat-items" ref={scrollerRef} style={{
                scrollBehavior: 'smooth'
            }}>

                {(hasMore && messages.length > 0) && (
                    <Button className="load-more-btn"
                        size='small'
                        type='primary'
                        onClick={() => {
                            setPage(x => {
                                loadMore(false, x+1)
                                return x + 1
                            })
                            
                        }}
                        style={{
                            width: 'fit-content',
                            marginInline: 'auto'
                        }}
                    >Muat Sebelumnya</Button>
                )}
                {Array.from(messages).reverse().map((message, i) => (
                    <ChatItem key={i} data={message} />
                ))}
                <div ref={messageEndRef} style={{
                    paddingBottom: "90px"
                }} />
            </div>
            <Divider className="divider" />
            <div className="chat-footer">
                <Input
                    className="message-field"
                    placeholder="Ketik sesuatu ..."
                    disabled={ended}
                    value={type}
                    onChange={e => setType(e.target.value)}
                    onKeyUp={e => {
                        if (e.keyCode === 13) {
                            sent()
                        }
                    }}
                />
                <input
                    style={{display: 'none' }}
                    type='file'
                    accept='image/jpeg, image/jpg, image/png'
                    ref={sendImageInputRef}
                    onChange={e => {
                        handleSendImage(e.target.files)
                    }}
                />
                <Button 
                    icon={loading ? undefined : <ImageIcon  style={{color: ended ? 'gray' : '#0A58BB'}} />}
                    className="send-image-btn"
                    size='small'
                    loading={imageLoading}
                    type='text'
                    onClick={() => sendImageInputRef.current.click()}
                    disabled={ended}
                />
                <Button
                    className="send-btn"
                    icon={<SendOutlinedIcon className="icon" />}
                    type='primary'
                    onClick={sent}
                    disabled={ended}
                >Kirim</Button>
            </div>
        </Card>
    )
}