import { Modal, Typography } from "antd";
import request from "utils/request";

export default function EndChatDialog({open, onClose, conversationId}) {
    const end = () => {
        request('/chat/end', 'POST', {
            conversationId
        }).then(() => {
            onClose()
        }).catch(err => console.error(err.message || err))
    }

    return (
        <Modal
            title='Akhiri Percakapan'
            width={350}
            centered
            open={open}
            onCancel={onClose}
            onOk={end}
        >
            <Typography>Anda yakin ingin mengakhiri percakapan ini ?</Typography>
        </Modal>
    )
}