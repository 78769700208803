import { Alert, Input, Modal, Typography } from "antd";
import { useEffect, useState } from "react";
import request from "utils/request";

export default function AdminNote({note, orderId, open, onClose, onChanged}) {
    const [loading, setLoading] = useState(false)
    const [value, setValue] = useState('')
    const [error, setError] = useState(null)

    useEffect(() => {
        setValue(note)
        setError(null)
    }, [open])

    const save = () => {
        setLoading(true)
        setError(null)
        request('/order/updateAdminNote', 'POST', {
            orderId,
            value: value.trim().length < 1 ? null : value.trim()
        }).then(data => {
            onChanged()
            onClose()
            setError(null)
        }).catch(err => setError(err.message || err))
        .finally(() => setLoading(false))
    }

    return (
        <Modal
            open={open}
            onCancel={onClose}
            width={400}
            centered
            title='Catatan Admin'
            okText='Simpan'
            cancelText='Tutup'
            cancelButtonProps={{
                disabled: loading
            }}
            okButtonProps={{
                loading
            }}
            onOk={save}
        >
            {error && <Alert 
                message={error}
                type='error'
                showIcon
                style={{
                    marginBottom: '15px'
                }}
            />}
            <Input.TextArea 
                placeholder='Catatan'
                autoSize={{
                    minRows: 7,
                    maxRows: 10
                }}
                disabled={loading}
                value={value}
                onChange={e => setValue(e.target.value)}
            />
        </Modal>
    )
}