import { Modal, Typography } from "antd";
import { useNavigate } from "react-router";

export default function SuccessOrderAlert({invoiceCode, onClose}) {
    const navigate = useNavigate()
    const gotoSearch = () => navigate('/order?tab=&search=' + invoiceCode)

    return (
        <Modal title='Berhasil'
            open={typeof invoiceCode === 'string'}
            onCancel={onClose}
            cancelText='Tutup'
            okText='Lihat'
            onOk={() => {
                gotoSearch()
                onClose()
            }}
            centered
            width={400}
        >
            <Typography>Pembelian telah berhasil silahkan klik <b>Lihat</b> untuk cek status pesanan</Typography>
        </Modal>
    )
}