import { Card, Input, Select, Typography } from "antd"
import { useEffect, useState } from "react"
import request from "utils/request"
import rupiah from "utils/rupiah"

let searchT

export default function ShippingPriceEstimation() {
    const [data, setData] = useState([])
    const [selected, setSelected] = useState(null)
    const [search, setSearch] = useState('')


    useEffect(() => {
        request('/order/shippingPriceEstimation').then(data => {
            setData(data)
        }).catch(err => console.error(err.message || err))
    },[])

    return (
        <div className="shipping-price-estimation">
            <Card title='Estimasi Harga Pengiriman' className="card-container">
                <div className="forms">
                    <Select
                        className="form-control"
                        showSearch
                        value={selected === null ? '- Pilih Tujuan -' : selected}
                        onChange={e => setSelected(e)}
                        filterOption={false}
                        onSearch={e => setSearch(e)}
                        dropdownStyle={{
                            width: 300
                        }}
                        style={{
                            minWidth: 120
                        }}
                    >
                        {data.filter(x => x.city.toLowerCase().includes(search.toLowerCase())).map((item, i) => (
                            <Select.Option key={i} value={JSON.stringify(item)}>{item.city}</Select.Option>
                        ))}
                    </Select>
                    {selected !== null && (
                        <Typography style={{
                            fontSize: 12,
                            marginTop: 10
                        }}>Estimasi: {rupiah.encode(JSON.parse(selected).amount)}</Typography>
                    )}
                </div>
            </Card>
        </div>
    )
}