import { Button, Card, Checkbox, Col, Flex, Input, Row, Table, Tag, Typography } from "antd";
import moment from "moment-timezone";
import rupiah from "utils/rupiah";
import urlify from "utils/urlify";
import 'moment/locale/id'
import './index.scss'
import { useEffect, useState } from "react";
import CancelDialog from './CancelDialog'
import request from "utils/request";
import { Link } from "react-router-dom";

let updateHeavyInt, updateTransferInt, updateMarkingCodeInt

export default function DataPreview({ orders, onChanged, onSelected }) {

    const [width, setWidth] = useState(window.innerWidth)
    const [height, setHeight] = useState(window.innerHeight)
    const [cancelOrderId, setCancelOrderId] = useState(null)
    const [search, setSearch] = useState('')
    const [orderIds, setOrderIds] = useState([])
    const [editedHeavyIds, setEditedHeavyIds] = useState([])
    const [editedHeavyValue, setEditedHeavyValue] = useState(null)
    const [editedTransferIds, setEditedTransferIds] = useState([])
    const [editedTransferValue, setEditedTransferValue] = useState(0)
    const [finishOmsLoading, setFinishOmsLoading] = useState(null)

    useEffect(() => {
        if(finishOmsLoading) {
            const invoices = finishOmsLoading.map(x => x[0])
            request('/order/adminOmsFinishToggle', 'POST', { invoices }).then(data => {
                const i = orders.findIndex(x => x.invoices === finishOmsLoading)
                setFinishOmsLoading(null)
                onChanged(i, { 
                    adminOmsFinished: data.newState
                 })
            }).catch(err => {
                console.error(err.message)
                setFinishOmsLoading(null)
            })
        }
    }, [finishOmsLoading])

    const updateHeavy = () => {
        request('/order/privateOmsUpdateHeavy', "POST", {
            invoices: editedHeavyIds.map(x => x[0]),
            heavy: editedHeavyValue
        }).then(() => {
            setEditedHeavyIds([])
            onChanged()
        }).catch(err => console.error(err.message || err))
    }

    const updateTransfer = () => {
        request('/order/privateOmsUpdateTransfer', 'POST', {
            invoices: editedTransferIds.map(x => x[0]),
            transfer: editedTransferValue / editedTransferIds.length
        }).then(() => {
            setEditedHeavyIds([])
            onChanged()
        }).catch(err => console.error(err.message || err))
    }

    useEffect(() => {
        onSelected(orderIds)
    }, [orderIds])

    const handleResize = () => {
        setWidth(window.innerWidth)
        setHeight(window.innerHeight)
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    return (
        <div className="data-preview">
            <CancelDialog
                orderId={cancelOrderId}
                onClose={() => setCancelOrderId(null)}
                onChanged={onChanged}
            />
            <Table
                className="table"
                rowKey={"date"}
                columns={[
                    {
                        key: "no",
                        width: 60,
                        title: "No",
                        render: (_, i, x) => <Typography className="no">{x + 1}</Typography>
                    },
                    {
                        key: "date",
                        title: "Tanggal",
                        width: 210,
                        render: (_, i) => <Typography className="date">{moment(i.date * 1000).tz('Asia/Jakarta').format('dddd, D MMMM YYYY')}</Typography>,
                    },
                    {
                        key: "quantity",
                        title: "Kuantitas",
                        align: 'center',
                        width: 40,
                        render: (_, i) => <Typography className="quantity">{i.quantity} pcs</Typography>
                    },
                    {
                        key: "heavy",
                        title: "Berat",
                        width: 80,
                        render: (_, i) => {
                            return (
                                <>
                                    {editedHeavyIds === i.invoices && (
                                        <Input
                                            size='small'
                                            value={editedHeavyValue}
                                            onChange={e => {
                                                const v = parseInt(e.target.value)
                                                setEditedHeavyValue(isNaN(v) ? 1 : v)
                                            }}
                                            style={{ width: '100%' }}
                                            autoFocus
                                            onKeyUp={e => {
                                                if (e.keyCode === 13) { updateHeavy() }
                                                else if (e.keyCode === 27) { setEditedHeavyIds([]) }
                                            }}
                                        />
                                    )}
                                    {editedHeavyIds !== i.invoices && (
                                        <Typography className="heavy"
                                            onDoubleClick={() => {
                                                setEditedHeavyIds(i.invoices)
                                                setEditedHeavyValue(i.heavy)
                                            }}
                                        >{i.heavy} Kg</Typography>
                                    )}
                                </>
                            )
                        },
                        className: 'editable-cell',
                    },
                    {
                        key: "shippingPrice",
                        title: "Biaya Pengiriman",
                        width: 180,
                        render: (_, i) => <Typography className="shipping-price">{rupiah.encode(i.shippingPrice)}</Typography>
                    },
                    {
                        key: "transfer",
                        title: "Transfer",
                        width: 170,
                        render: (_, i) => {
                            if (editedTransferIds === i.invoices) {
                                return (
                                    <Input
                                        value={rupiah.encode(editedTransferValue)}
                                        autoFocus
                                        onChange={e => {
                                            const v = rupiah.decode(e.target.value.split('.').join(''))
                                            setEditedTransferValue(isNaN(v) ? 0 : v)
                                        }}
                                        onKeyUp={e => {
                                            if (e.keyCode === 27) { setEditedTransferIds([]) }
                                            else if (e.keyCode === 13) { updateTransfer() }
                                        }}
                                    />
                                )
                            } else {
                                return (
                                    <Typography className="transfer"
                                        onDoubleClick={() => {
                                            setEditedTransferIds(i.invoices)
                                            setEditedTransferValue(i.transfer)
                                        }}
                                    >{rupiah.encode(i.transfer)}</Typography>
                                )
                            }
                        },
                        className: 'editable-cell'
                    },
                    {
                        key: "profit",
                        title: "Profit",
                        width: 160,
                        render: (_, i) => <Typography className="profit">{rupiah.encode(i.profit)}</Typography>
                    },
                    {
                        key: "markingCode",
                        title: "Marking Code",
                        render: (_, i) => <Typography className="marking-code">{i.markingCode}</Typography>
                    },
                    {
                        key: "action",
                        title: "Action",
                        align: 'center',
                        width: 100,
                        render: (_, i) => <div className="actions-cell">
                            {!window.location.href.includes('beliexpress.com') && (
                                <Checkbox
                                    checked={i.adminOmsFinished}
                                    onChange={() => setFinishOmsLoading(i.invoices)}
                                    disabled={finishOmsLoading !== null && finishOmsLoading !== i.invoices}
                                >Selesai</Checkbox>
                            )}
                            <Button
                                type="primary"
                                danger
                                onClick={() => {
                                    if (window.confirm('Anda yakin ingin melanjutkan ?')) {
                                        request('/order/cancelFromProfitOMS', "POST", {
                                            invoices: i.invoices.map(x => x[0])
                                        }).then(() => {
                                            onChanged()
                                        }).catch(err => console.error(err.message || err))
                                    }
                                }}
                            >Hapus</Button>
                        </div>
                    }
                ]}
                pagination={false}
                bordered={true}
                rowClassName={record => {
                    return record.adminOmsFinished ? 'finished-row' : ''
                }}
                title={() => <Input
                    placeholder="Cari"
                    value={search}
                    onChange={e => setSearch(e.target.value.toLowerCase())}
                    className="search-field"
                />}
                dataSource={orders.filter(x => {
                    return x.invoices.findIndex(y => y[0].toLowerCase().includes(search.toLowerCase())) >= 0
                })}
                size='small'
                scroll={{
                    x: 'max-content'
                }}
                summary={pd => {
                    let quantity = 0, heavy = 0, transfer = 0, shippingPrice = 0, profit = 0

                    pd.forEach((p) => {
                        quantity += p.quantity
                        heavy += p.heavy
                        shippingPrice += p.shippingPrice
                        profit += p.profit
                        transfer += p.transfer
                    })

                    return (
                        <Table.Summary.Row>
                            <Table.Summary.Cell colSpan={3}>Total:</Table.Summary.Cell>
                            <Table.Summary.Cell align="center">{quantity} pcs</Table.Summary.Cell>
                            <Table.Summary.Cell align="center">{heavy} Kg</Table.Summary.Cell>
                            <Table.Summary.Cell>{rupiah.encode(shippingPrice)}</Table.Summary.Cell>
                            <Table.Summary.Cell>{rupiah.encode(transfer)}</Table.Summary.Cell>
                            <Table.Summary.Cell colSpan={1}>{rupiah.encode(profit)}</Table.Summary.Cell>
                            <Table.Summary.Cell />
                        </Table.Summary.Row>
                    )
                }}
                expandable={{
                    expandedRowRender: (record) => {

                        return (
                            <div className="expanded-element">
                                <div className="product">
                                    <div className="invoices">{record.invoices.map((invoice, i) => (
                                        <Typography className="name" key={i}>{invoice[0]} - <b>{invoice[1]}</b></Typography>
                                    ))}</div>
                                    <div className="row">
                                        <div className="row images">
                                            {record.productImages.map((image, i) => (
                                                <img
                                                    src={image}
                                                    key={i}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                }}
            />
        </div>
    )
} 