import {useEffect} from "react";
import cookies from 'react-cookies'

export default function Logout() {
    useEffect(() => {
        cookies.remove('session', {
            path: '/'
        })

        setTimeout(() => {
            window.location.href='/'
        }, 1000)
    }, []);

    return (
        <div className={'logout page'}>Please wait ...</div>
    )
}