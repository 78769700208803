function getItemIdFromAliExpressURL(url) {
    // Format URL regex pattern
    var pattern = /^(?:https?:\/\/)?(?:\S+\.)?aliexpress\.com\/item\/(\d+)(\.html)?$/i;
    // Extract itemid from URL
    var match = url.match(pattern);

    // Jika match ditemukan, return itemid. Jika tidak, return null.
    return match ? match[1] : null;
}

export default getItemIdFromAliExpressURL