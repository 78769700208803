import conf from "conf";
import axios from "axios";

export default function request(path, method, body) {
  return new Promise((resolve, reject) => {
    const url = `${conf.backendUrl}${path}`;
    axios({
      url,
      method,
      data: body,
      withCredentials: true,
    }, {
      withCredentials: true
    })
      .then(({ data }) => {
        if (data.error) {
          reject(data.msg);
        } else {
          resolve(data.data);
        }
      })
      .catch((err) => {
        reject(err.message || err);
      });
  });
}
