import { FloatButton, Badge } from "antd";
import CommentIcon from '@mui/icons-material/Comment';
import './style.scss'
import request from "utils/request";
import { useEffect, useState } from 'react'
import { useNavigate } from "react-router";

// Refresh every 15 sec
let refreshInt

export default function ChatBtnFloat() {

    const [unread, setUnread] = useState(0)
    const [authenticated, setAuthenticated] = useState(false)
    const navigate = useNavigate()

    const getUnreadCount = () => {
        request('/chat/unreadCount').then(data => {
            setUnread(data)
        }).catch(err => {
            console.error(err.message || err)
        })
    }

    useEffect(() => {
        request('/session/isValid', 'GET').then(data => {
            setAuthenticated(data.valid)
        }).catch(err => console.error(err.message || err))
    }, [])

    useEffect(() => {
        getUnreadCount()
        if(refreshInt) { clearInterval(refreshInt) }
        refreshInt = setInterval(getUnreadCount, 15000)

        return () => {
            refreshInt && clearInterval(refreshInt)
        }
    }, [])

    return authenticated && (
        <FloatButton
            shape='circle'
            className="element chat-float-btn"
            icon={(<CommentIcon className='icon' />)}
            type='primary'
            badge={{ count: unread, size: 'small' }}
            onClick={() => navigate('/chat')}
        />
    )
}