import { Button, Tooltip } from "antd";
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { useState } from "react";
import request from "utils/request";
import { useNavigate } from 'react-router-dom'

export default function MakeConversation({orderId}) {
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const make = () => {
        setLoading(true)
        request('/chat/makeConversationFromOrder', 'POST', {
            orderId
        }).then((conversationId) => {
            navigate('/chat?i=' + conversationId)
        }).catch(err => {
            console.error(err.message || err)
        }).finally(() => {
            setLoading(false)
        })
    }

    return (
        <Tooltip title='Buat percakapan mengenai pesanan ini'>
            <Button
                onClick={make}
                icon={!loading && <QuestionAnswerIcon htmlColor="gray" />}
                size='small'
                type='text'
                loading={loading}
            />
        </Tooltip>
    )
}