import { Button, Card, Popconfirm, Tag, Typography } from "antd";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import request from "utils/request";
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import { useState } from "react";
import EditDialog from './EditDialog'
import conf from "conf";

export default function AddressItem({ data, onDelete }) {
    const [editDialogOpen, setEditDialogOpen] = useState(false)

    const removeAddress = () => {
        request('/user/address/remove', 'POST', {
            id: data.id
        }).then(() => {
            onDelete()
        }).catch(err => {
            console.error(`Failed for remove address: ${err.message || err}`)
        })
    }

    return (
        <Card data-id={data.id}
            className="address-item"
        >
            <EditDialog 
                id={data.id}
                open={editDialogOpen}
                onClose={() => setEditDialogOpen(false)}
                onReload={onDelete}
            />
            <Typography className="title">{data.name}</Typography>
            <Typography.Paragraph className="detail">
                {data.province}/{data.regency}/{data.district}/{data.village}<br />
                {data.additionalAddress}
            </Typography.Paragraph>
            <Tag
                color='red'
                style={{
                    marginTop: '-10px',
                    marginBottom: '10px'
                }}
            >{conf.addressTypes.find(x => x.key === data.type).name}</Tag>
            <div className="actions">
                <Popconfirm
                    title='Konfirmasi'
                    description='Yakin anda ingin menghapus alamat secara permanen?'
                    okButtonProps={{
                        danger: true,
                        type: 'primary'
                    }}
                    okText='Hapus'
                    cancelButtonProps={{
                        type: 'dashed',
                    }}
                    cancelText='Batal'
                    arrow={false}
                    onConfirm={removeAddress}
                >
                    <Button
                        icon={<DeleteOutlineOutlinedIcon className="icon" />}
                        className="delete-btn action-btn"
                        type='text'
                        danger
                    />
                </Popconfirm>
                <Button className="action-btn edit" icon={<CreateOutlinedIcon />}
                    type='text'
                    onClick={() => setEditDialogOpen(x => !x)}
                >
                </Button>
            </div>
        </Card>
    )
}