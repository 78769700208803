import { Button, Col, Input, Row, Table } from "antd";
import moment from "moment-timezone";
import rupiah from "utils/rupiah";
import urlify from "utils/urlify";
import 'moment/locale/id'
import './index.scss'
import { useEffect, useState } from "react";
import CancelDialog from './CancelDialog'
import request from "utils/request";
import { useNavigate } from "react-router";

export default function DataPreview({orders, onChanged, isPrivate}) {

    const [width, setWidth] = useState(window.innerWidth)
    const [height, setHeight] = useState(window.innerHeight)
    const [cancelOrderId, setCancelOrderId] = useState(null)
    const [search, setSearch] = useState('')

    const navigate = useNavigate()

    const handleResize = () => {
        setWidth(window.innerWidth)
        setHeight(window.innerHeight)
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    return (
        <div className="data-preview">
            <CancelDialog
                orderId={cancelOrderId}
                onClose={() => setCancelOrderId(null)}
                onChanged={onChanged}
            />
            <Table
                className="table"
                columns={[
                    {
                        key: 'no',
                        title: "No",
                        render: (text, _, i) => <div className="no">{i + 1}</div>,
                        align: 'center',
                        width: 60,
                    },
                    {
                        key: 'admin',
                        title: "Admin",
                        render: (text, _) => <div className="admin">{_.admins.join(',')}</div>
                    },
                    {
                        key: "orderId",
                        title: "Invoice",
                        render: (text, _) => <div className="invoice"
                            onClick={() => {
                                navigate(`/order/?search=${_.invoice}&tab=${_.statusKey}`)
                            }}
                        >{_.invoice}</div>
                    },
                    {
                        key: "adminNote",
                        render: (x, _) => <div className="admin-note" dangerouslySetInnerHTML={{__html: _.adminNote ? urlify(_.adminNote) : ''}}></div>,
                        title: "Catatan Admin"
                    },
                    {
                        key: "priceInTokped",
                        render: (x, i) => <div className="price-in-tokped">{rupiah.encode(i.priceInTokped)}</div>,
                        title: isPrivate ? "Harga Tokped" : 'Harga Total'
                    },
                    {
                        key: "capitalPrice",
                        render: (x, i) => <div className="capital-price">{rupiah.encode(i.capitalPrice)}</div>,
                        title: isPrivate ? "Harga Lazada" : 'Harga Barang'
                    },
                    {
                        key: "profit",
                        render: (x, i) => <div className="profit">{rupiah.encode(i.profit)}</div>,
                        title: "Profit"
                    },
                    {
                        key: "processingDate",
                        render: (x, i) => <div className="processing-date">
                            <div className="date-format">{moment(i.processingDate * 1000).format('DD MMM YYYY')}</div>
                        </div>,
                        title: "Pada"
                    },
                    {
                        key: "invoice",
                        render: (x, i) => (
                            <div className="actions">
                                <Button className="action-btn"
                                    size='small'
                                    type='dashed'
                                    onClick={() => {
                                        if(window.confirm('Anda yakin ingin melanjutkan')) {
                                            request('/order/omsPrivate', 'POST', { orderId: i.invoice }).then(() => {
                                                onChanged()
                                            }).catch(err => console.error(err.message || err))
                                        }
                                    }}
                                >OMS</Button>
                                <Button className="action-btn batal"
                                    size='small'
                                    type='primary'
                                    disabled={i.statusKey === 'rejected' || i.statusKey === 'canceled'}
                                    onClick={() => setCancelOrderId(i.invoice)}
                                >Batal</Button>
                            </div>
                        )
                    }
                ]}
                pagination={false}
                bordered={true}
                title={() => <Input 
                    placeholder="Cari"
                    value={search}
                    onChange={e => setSearch(e.target.value.toLowerCase())}
                    className="search-field"
                />}
                dataSource={orders.filter(x => {
                    return x.invoice.toLowerCase().includes(search) || 
                        x.admins.join(',').toLowerCase().includes(search) || 
                        (typeof x.adminNote === 'string' ? x.adminNote.toLowerCase().includes(search) : false)
                })}
                size='small'
                scroll={{
                    x: 'max-content'
                }}
                rowClassName={record => {
                    return record.omsFinished ? 'finished-row' : ''
                }}
                summary={pd => {
                    let priceInTokped = 0, capitalPrice = 0, profit = 0
                    
                    pd.forEach((p) => {
                        priceInTokped+= p.priceInTokped
                        capitalPrice+= p.capitalPrice
                        profit+= p.profit
                    })

                    return (
                        <Table.Summary.Row>
                            <Table.Summary.Cell colSpan={4}>Total:</Table.Summary.Cell>
                            <Table.Summary.Cell>{rupiah.encode(priceInTokped)}</Table.Summary.Cell>
                            <Table.Summary.Cell>{rupiah.encode(capitalPrice)}</Table.Summary.Cell>
                            <Table.Summary.Cell>{rupiah.encode(profit)}</Table.Summary.Cell>
                            <Table.Summary.Cell colSpan={2} />
                        </Table.Summary.Row>
                    )
                }}
            />
        </div>
    )
} 