import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Button, Dropdown, Typography } from "antd";
import { useEffect, useState } from 'react';
import SetHarga from './SetHarga'
import './action.scss'
import ChangeStatus from './ChangeStatus';
import TrackDialog from './TrackDialog';
import SendToAdmin3Dialog from './SendToAdmin3Dialog'
import TrackView from './TrackView';
import CancelDialog from './CancelDialog'
import FinishDialog from './FinishDialog';
import RefundDialog from './RefundDialog'
import RejectDialog from './RejectDialog'
import DeliveredProof from './DeliveredProof'

export default function Action({ orderId, roles, onReload, order, modal, isPrivate }) {
    const [menus, setMenus] = useState([])
    const [changeStatusOpen, setChangeStatusOpen] = useState(false)
    const [setHargaOpen, setSetHargaOpen] = useState(false)
    const [trackOpen, setTrackOpen] = useState(false)
    const [sendToAdmin3Open, setToAdmin3Open] = useState(false)
    const [trackViewOpen, setTrackViewOpen] = useState(false)
    const [cancelDialogOpen, setCancelDialogOpen] = useState(false)
    const [finishDialogOpen, setFinishDialogOpen] = useState(false)
    const [refundDialogOpen, setRefundDialogOpen] = useState(false)
    const [rejectDialogOpen, setRejectDialogOpen] = useState(false)
    const [deliveredProofOpen, setDeliveredProofOpen] = useState(false)


    useEffect(() => {
        let _roles = []

        if (roles.includes('admin1')) {
            _roles.push(...[
                {
                    label: "Atur Harga",
                    onClick: () => setSetHargaOpen(true),
                    disabled: isPrivate ? false : order.admin1.finished
                },
                {
                    label: "Ubah Status",
                    onClick: () => setChangeStatusOpen(true),
                    disabled: isPrivate ? modal <= 0 : order.admin1.finished
                },
                {
                    label: "Tolak Pesanan",
                    onClick: () => setRejectDialogOpen(true),
                    disabled: isPrivate ? false : order.admin1.finished
                }
            ])
        }
        if (roles.includes('admin2')) {
            _roles.push(...[
                {
                    label: "Ubah Status",
                    onClick: () => setChangeStatusOpen(true),
                    disabled: modal <= 0
                },
                {
                    label: "Tambahkan Track",
                    onClick: () => setTrackOpen(true)
                },
                {
                    label: <Typography style={{
                        color: (isPrivate ? (order.adminNote === null || order.adminNote == "") : ((order.adminNote === null || order.adminNote == "") || order.admin2.finished)) ? "#b2b2b2" : 'black'
                    }}>Kirim ke <b>{order.admin3.name}</b></Typography>,
                    onClick: () => setToAdmin3Open(true),
                    disabled: isPrivate ? (order.adminNote === null || order.adminNote == "") : ((order.adminNote === null || order.adminNote == "") || order.admin2.finished)
                },
                {
                    label: "Refund",
                    onClick: () => setRefundDialogOpen(true),
                    disabled: isPrivate ? false : order.admin2.finished
                }
            ])
        }
        if(roles.includes('admin3')) {
            _roles.push(...[
                {
                    label: "Ubah Status",
                    onClick: () => setChangeStatusOpen(true)
                },
                {
                    label: "Tambahkan Track",
                    onClick: () => setTrackOpen(true)
                },
                {
                    label: "Lihat Track",
                    onClick: () => setTrackViewOpen(true)
                },
                {
                    label: "Kirim Bukti Terkirim",
                    onClick: () => setDeliveredProofOpen(true),
                    disabled: isPrivate ? false : order.admin3.finished,
                },
                {
                    type: "divider"
                },
                {
                    label: "Selesaikan",
                    onClick: () => setFinishDialogOpen(true),
                    disabled: isPrivate ? false : order.admin3.finished,
                },
                {
                    label: "Batalkan",
                    onClick: () => setRejectDialogOpen(true),
                    disabled: isPrivate ? false : order.admin3.finished,
                    danger: true
                }
            ])
        }

        _roles.map((x, i) => {
            x.key = i
            return x
        })

        // Unique roles by the label
        let _uroles = []
        for(const role of _roles) {
            const i = _uroles.findIndex(x => x.label === role.label)
            if(i<0) {
                _uroles.push(role)
            }
        }

        setMenus(_uroles)
    }, [roles, modal, order, isPrivate])

    return (roles.includes('admin1') || roles.includes('admin2') || roles.includes('admin3')) && (
        <>
            <DeliveredProof 
                open={deliveredProofOpen}
                onRefresh={onReload}
                onClose={() => setDeliveredProofOpen(false)}
                orderId={orderId}
            />
            <RejectDialog 
                open={rejectDialogOpen}
                onClose={() => setRejectDialogOpen(false)}
                orderId={orderId}
                onChanged={onReload}
            />
            <RefundDialog 
                orderId={orderId}
                open={refundDialogOpen}
                onClose={() => setRefundDialogOpen(false)}
                onRefunded={onReload}
            />
            <FinishDialog 
                open={finishDialogOpen}
                onClose={() => setFinishDialogOpen(false)}
                orderId={orderId}
                onChanged={onReload}
            />
            <CancelDialog 
                open={cancelDialogOpen}
                onClose={() => setCancelDialogOpen(false)}
                orderId={orderId}
                onChanged={onReload}
            />
            <TrackView 
                open={trackViewOpen}
                onClose={() => setTrackViewOpen(false)}
                order={order}
                onRefresh={onReload}
                orderId={orderId}
            />
            <SendToAdmin3Dialog
                open={sendToAdmin3Open}
                onClose={() => setToAdmin3Open(false)}
                onChanged={onReload}
                adminName={order.admin3.name}
                orderId={orderId}
            />
            <TrackDialog
                open={trackOpen}
                onClose={() => setTrackOpen(false)}
                order={order}
                onRefresh={onReload}
            />
            <SetHarga
                open={setHargaOpen}
                onClose={() => setSetHargaOpen(false)}
                orderId={orderId}
                onReload={onReload}
                order={order}
            />
            <ChangeStatus
                orderId={orderId}
                open={changeStatusOpen}
                onClose={() => setChangeStatusOpen(false)}
                currentStatus={order.status.key}
                onChanged={onReload}
                roles={roles}
            />
            <Dropdown
                trigger={['click']}
                menu={{
                    items: menus
                }}
            >
                <Button
                    type='text'
                    icon={<MoreVertIcon />}
                    size='small'
                    shape='circle'
                />
            </Dropdown>
        </>
    )
}