import { Image, Input, Modal, Typography, Upload } from "antd";
import { useEffect, useState } from "react";
import FormData from 'form-data'
import request from "utils/request";

export default function DeliveredProof({ open, orderId, onClose, onRefresh }) {
    const [loading, setLoading] = useState(false)
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [file, setFile] = useState(null)
    const [renderFile, setRenderFile] = useState(null)

    useEffect(() => {
        setTitle('Terkirim')
        setDescription('Pesanan Anda telah terkirim')
        setFile(null)
    }, [open])

    useEffect(() => {
        if (file === null) { setRenderFile(null) } else {
            const reader = new FileReader()
            reader.onloadend = e => {
                setRenderFile(e.target.result)
            }
            reader.readAsDataURL(file)
        }
    }, [file])

    const sent = () => {
        setLoading(true)
        const fd = new FormData()
        fd.append('title', title.trim())
        fd.append('description', description.trim())
        fd.append('file', file)
        fd.append('orderId', orderId)

        request('/order/addDeliveredProof', 'POST', fd)
        .then(data => {
            onClose()
            onRefresh()
        }).catch(err => {
            console.error(err.message || err)
        }).finally(() => {
            setLoading(false)
        })
    }

    return (
        <Modal
            open={open}
            closable={!loading}
            onCancel={onClose}
            title='Kirim Bukti Terkirim'
            centered
            width={500}
            className="delivered-proof-modal"
            cancelText='Tutup'
            cancelButtonProps={{
                disabled: loading
            }}
            okText='Kirim'
            okButtonProps={{
                loading,
                disabled: !file || title.trim().length < 1 || description.trim().length < 1
            }}
            onOk={sent}
        >
            <div className="forms">
                <div className="form-control">
                    <Typography className="title">Judul</Typography>
                    <Input
                        disabled={loading}
                        size='large'
                        value={title}
                        onChange={e => setTitle(e.target.value)}
                    />
                </div>
                <div className="form-control">
                    <Typography className="title">Deskripsi</Typography>
                    <Input
                        disabled={loading}
                        size='large'
                        value={description}
                        onChange={e => setDescription(e.target.value)}
                    />
                </div>
                <Upload.Dragger
                    disabled={loading}
                    showUploadList={false}
                    locale='id'
                    beforeUpload={e => {
                        setFile(e)
                        return false
                    }}
                    style={{
                        marginBottom: '10px'
                    }}
                    fileList={file ? [file] : []}
                    accept='image/png, image/jpeg, image/jpg'
                    onRemove={() => setFile(null)}
                >
                    <p className="ant-upload-text">Klik atau seret file bukti barang terkirim di area ini</p>
                    <p className="ant-upload-hint">Mendukung file dengan format jpg, jpeg, png. max: 5mb</p>
                </Upload.Dragger>
                {renderFile && (
                    <Image
                        src={renderFile}
                        className="render-file"
                        alt={title}
                        title={title}
                        width={200}
                    />
                )}
            </div>
        </Modal>
    )
}