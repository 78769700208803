import { Alert, Button, Flex, Input, Modal, Radio, Tag, notification } from "antd";
import { useEffect, useState } from "react";
import request from "utils/request";
import AddTimeline from './AddTrack'

export default function TrackDialog({ open, onClose, order, onRefresh }) {
    const [title, setTitle] = useState('')
    const [desc, setDesc] = useState('')
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const [customTrackOpen, setCustomTrackOpen] = useState(false)
    const [templates, setTemplates] = useState([])


    const refreshTemplates = () => {
        request('/user/templateTrack').then(data => {
            setTemplates(data)
        }).catch(err => console.error(`Failed for load templates: ${err.toString()}`))
    }

    useEffect(() => {
        if (!open) {
            setCustomTrackOpen(false)
            setTemplates([])
        } else {
            refreshTemplates()
        }
    }, [open])

    const [api, contextHolder] = notification.useNotification()

    useEffect(() => {
        setTitle('')
        setDesc('')
    }, [open])

    const addTrack = () => {
        setError(null)
        setLoading(true)
        request('/order/addMutationTimeline', 'POST', {
            orderId: order.orderId,
            title,
            description: desc
        }).then(data => {
            onClose()
            api.open({
                message: "Berhasil",
                description: "Timeline telah berhasil ditambahkan",
                duration: 4.5
            })
        }).catch(err => {
            console.error(`Failed for add mutation timeline: ${err.toString()}`)
            setError(err.message || err.toString())
        })
            .finally(() => setLoading(false))
    }

    const removeTemplate = name => {
        request('/user/removeTemplateTrack', 'POST', {
            name
        }).catch(err => {
            console.error(`Failed for remove template: ${err.toString()}`)
        })
    }

    return (
        <>
            <AddTimeline
                open={customTrackOpen}
                onClose={() => setCustomTrackOpen(false)}
                onAdded={refreshTemplates}
            />
            {contextHolder}
            <Modal
                open={open}
                onCancel={onClose}
                cancelText='Batal'
                cancelButtonProps={{
                    danger: true,
                    loading: loading
                }}
                centered
                width={500}
                title='Tambahkan Track'
                className="add-timeline-modal"
                okText='Simpan'
                okButtonProps={{
                    disabled: title.trim().length < 1 || desc.trim().length < 1,
                    loading: loading
                }}
                onOk={addTrack}
            >
                <div className="form">
                    {error && (
                        <Alert
                            message={error}
                            type='error'
                            showIcon
                            style={{
                                marginBottom: '10px'
                            }}
                        />
                    )}
                    <Input
                        placeholder='Judul'
                        variant='filled'
                        value={title}
                        onChange={e => setTitle(e.target.value)}
                        disabled={loading}
                    />
                    <Input.TextArea
                        placeholder='Deskripsi track'
                        autoSize={{
                            minRows: 6,
                            maxRows: 10
                        }}
                        style={{
                            marginTop: '10px'
                        }}
                        value={desc}
                        onChange={e => setDesc(e.target.value)}
                        disabled={loading}
                    />
                    <Flex wrap='wrap' gap='4px 0' style={{
                        marginTop: "10px"
                    }}>
                        {templates.map((template, i) => (
                            <Tag closable onClose={() => {
                                removeTemplate(template.name)
                            }} onClick={() => {
                                setTitle(template.title)
                                setDesc(template.description)
                            }} color='green' style={{
                                cursor: 'pointer'
                            }}>{template.name}</Tag>
                        ))}
                    </Flex>
                    <Button type='dashed'
                        size='small'
                        style={{
                            marginTop: '10px'
                        }}
                        onClick={() => setCustomTrackOpen(true)}
                    >Tambahkan Template</Button>
                </div>
            </Modal>
        </>
    )
}