import { Modal, Input, Typography, Alert } from "antd";
import { useEffect, useState } from "react";
import request from "utils/request";

export default function NewConversation({open, onClose, onAdded}) {
    const [name, setName] = useState('')
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)

    useEffect(() => {
        setName('')
    }, [open])

    const make = () => {
        setError(false)
        setLoading(true)
        request('/chat/makeConversation', 'POST', {
            name: name.trim()
        }).then(data => {
            onAdded(data.conversationId)
        }).catch(err => {
            setError(err.message || err)
        }).finally(() => {
            setLoading(false)
        })
    }

    return (
        <Modal className="new-conversation-modal"
            open={open}
            onCancel={onClose}
            title='Buat Percakapan Baru'
            centered
            width={350}
            onOk={make}
            cancelText='Batal'
            okText='Buat'
            closable={!loading}
            cancelButtonProps={{ disabled: loading }}
            okButtonProps={{ loading }}
        >
            {error && (
                <Alert 
                    message={error}
                    showIcon
                    type='error'
                    style={{
                        marginBottom: '15px'
                    }}
                />
            )}
            <Input 
                size='large'
                autoFocus
                value={name}
                placeholder='Nama Percakapan'
                onChange={e => setName(e.target.value)}
                disabled={loading}
            />
            <Typography className="info" style={{
                fontSize: '12px',
                color: 'var(--primary-color)'
            }}>ex: Tanya soal pengiriman</Typography>
        </Modal>
    )

}