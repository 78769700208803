import { useEffect, useState } from "react"
import sidebarData from "./sidebarData"
import request from "utils/request"
import { Avatar, Button, Card, Divider, Menu, Typography } from "antd"
import avatar from 'dist/avatar/profile.png'
import { Link, useLocation, useNavigate } from "react-router-dom"
import conf from "conf"
import DataUsageIcon from '@mui/icons-material/DataUsage';

export default function Sidebar() {
    // Get the roles
    const [roles, setRoles] = useState([])
    const [profile, setProfile] = useState(null)
    const [isPrivate, setIsPrivate] = useState(!window.location.href.includes(conf.publicUrl))
    const [menuItems, setMenuItems] = useState([])
    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        request('/user/roles').then(data => {
            setRoles(data.roles)
        }).catch(err => console.error(`Failed for get the roles: ${err.message || err}`))
    }, [])

    useEffect(() => {
        request('/user/get/profile').then(data => {
            setProfile(data)
        }).catch(err => console.error(`Failed for load the user: ${err.message || err}`))
    }, [roles])

    useEffect(() => {
        let _menus = []
        if (roles.includes('general')) {
            _menus.push(...sidebarData.generalMenus.map(x => {
                x.label = x.name
                x.key = x.url
                return x
            }))
        }
        if (roles.includes('developer')) {
            _menus.push(...sidebarData.developer.map(x => {
                x.label = x.name
                x.key = x.url
                return x
            }))
        }
        
        _menus.push(...sidebarData.default.map(x => {
            x.label = x.name
            x.key = x.url
            return x
        }))

        if(roles.findIndex(x => x.startsWith('admin')) >= 0) {
            _menus.push(...sidebarData.admin.map(x => {
                x.label = x.name
                x.key = x.url
                return x
            }))
        } 

        if ((roles.includes('admin1') || roles.includes('admin2') || roles.includes('admin3'))) {
            _menus.push({
                key: '#',
                label: "Pembukuan",
                type: 'group',
                children: [
                    { key: "/private-pembukuan", label: window.location.href.includes('beliexpress.com') ? 'Dikirim' : "Diproses" },
                    { key: "/private-oms", label: "OMS" },
                    { key: "/private-profit-oms", label: "Profit OMS" },
                    { key: "/private-profit-barang", label: "Profit Barang" }
                ],
                icon: <DataUsageIcon className='icon' />
            })
        }

        setMenuItems(_menus)
    }, [roles, profile, isPrivate])

    return (
        <Card className="sidebar">

            {profile && (
                <>
                    <div className="profile">
                        <div className="left">
                            <Avatar
                                src={avatar}
                                alt={profile.name}
                                title={profile.name}
                                size='large'
                            />
                        </div>
                        <div className="right">
                            <Typography className="name">{profile.name}</Typography>
                            <Typography className="email">{profile.email}</Typography>
                        </div>
                    </div>
                    <Menu
                        className="menus"
                        id="default"
                        items={menuItems}
                        selectedKeys={[location.pathname.split('?')[0].toLowerCase()]}
                        onSelect={e => {
                            navigate(e.key)
                        }}
                    />
                </>
            )}
        </Card>
    )
}