import MainAppContainer from "element/MainAppContainer";
import './index.scss'
import { useEffect, useState } from "react";
import request from "utils/request";
import { Button, Input, Table, Typography } from "antd";
import AddDialog from "./AddDialog";
import moment from "moment-timezone";

export default function Voucer() {
    const [voucers, setVoucers] = useState([])
    const [search, setSearch] = useState('')
    const [addOpen, setAddOpen] = useState(false)
    const [editId, setEditId] = useState(null)

    const fetchData = () => {
        request('/voucer/all?q=').then(d => setVoucers(d)).catch(err => console.error(err.message || err))
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <MainAppContainer name='voucer'>
            <AddDialog 
                open={addOpen}
                onClose={() => setAddOpen(false)}
                onAdded={fetchData}
                as='add'
            />
            <AddDialog 
                open={editId !== null}
                onClose={() => setEditId(null)}
                onAdded={fetchData}
                as='edit'
                voucerId={editId}
            />
            <div className="voucer-header">
                <Input 
                    className="search-field"
                    placeholder="Cari"
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                />
                <div className="right">
                    <Button
                        className="add-voucer"
                        type='primary'
                        onClick={() => setAddOpen(true)}
                        loading={addOpen}
                    >Tambah Voucer</Button>
                </div>
            </div>
            <Table
                dataSource={voucers.filter(x => x.code.toLowerCase().includes(search.toLowerCase()))}
                columns={[
                    {
                        key: "",
                        title: "#",
                        render: (_, x, i) => i + 1,
                        width: 20,
                        align: 'center'
                    },
                    {
                        key: "code",
                        title: "Code",
                        render: (_, i) =>  <Typography className="code-cell">{i.code}</Typography>,
                        width: 150
                    },
                    {
                        key: "claimed",
                        title: "Di Claim",
                        render: (_, i) => <Typography className="cell-claimed">{i.claimed}</Typography>,
                        width: 150,
                    },
                    {
                        key: 'limit',
                        title: "Limit",
                        render: (_, i) => <Typography className="limit-cell">{i.isLimited ? i.limit : 'UNLIMITED'}</Typography>,
                        width: 150
                    },
                    {
                        key: "created",
                        title: "Dibuat pada",
                        render: (_, i) => <Typography className="cell-created">{moment(i.created * 1000).tz('Asia/Jakarta').format('D MMMM YYYY')}</Typography>,
                        width: 200
                    },
                    {
                        key: "createdBy",
                        title: "Dibuat oleh",
                        render: (_, i) => <Typography className="cell-created-by">{i.createdByName}</Typography>,
                        width: 150,
                    },
                    {
                        key: 'action',
                        title: "-",
                        render: (_, i) => <div className="action-cell">
                            <Button
                                size='small'
                                type='dashed'
                                className="action-btn edit"
                                onClick={() => setEditId(i.id)}
                            >Edit</Button>
                            <Button className="action-btn delete"
                                type='primary'
                                size='small'
                                onClick={() => {
                                    if(window.confirm('Anda yakin ingin melanjutkan?')) {
                                        request('/voucer/remove', 'POST', { id: i.id }).then(() => {
                                            fetchData()
                                        }).catch(err => console.error(err.message || err))
                                    }
                                }}
                            >Hapus</Button>
                        </div>
                    }
                ]}
            />
        </MainAppContainer>
    )
}