import { Image, Typography } from "antd"
import { MessageBox } from "react-chat-elements"
import 'react-chat-elements/dist/main.css'
import moment from 'moment-timezone'
import 'moment/locale/id'
import request from "utils/request"
import { useEffect, useState } from 'react'
import { useNavigate } from "react-router"
import conf from "conf"


export default function ChatItem({data}) {
    return (
        <div className="chat-item" data-type={data.type}>
            {data.type === 'message' && (
                <MessageBox
                    position={data.isme ? 'right' : 'left'}
                    text={data.content}
                    type='text'
                    className={data.isme ? 'isme' : 'isnotme'}
                    date={moment(data.sendAt * 1000).toDate()}
                />
            )}
            {data.type === 'information' && (
                <Typography className="information">{data.content}</Typography>
            )}
            {data.type === 'product' && (
                <ProductItem orderId={data.content} data={data} />
            )}
            {data.type === 'image' && (
                <img 
                    src={`${conf.backendUrl}/file/${data.content}`}
                    className={`image-message ${data.isme ? 'isme' : 'isnotme'}`}
                    onClick={() => window.open(`${conf.backendUrl}/file/${data.content}`, '_blank')}
                />
            )}
        </div>
    )
}

function ProductItem({orderId, data}) {
    const [product, setProduct] = useState(null)
    useEffect(() => {
        request('/order/detail?inv=' + orderId).then(data => {
            setProduct(data)
        }).catch(err => console.error(err.message || err))
    }, [])

    const navigate = useNavigate()

    return (
        <MessageBox
            reply={{
                photoURL: product ? (product.product.images[0] ? product.product.images[0] : undefined) : undefined,
                title: product ? product.product.name : '-',
                titleColor: '#8717ae',
                message: product ? product.status.name : '-',
            }}
            onReplyMessageClick={() => {
                navigate(`/order?tab=&search=${orderId}`)
            }}
            type={'text'}
            text={orderId}
            position={data.isme ? 'right' : 'left'}
            className={data.isme ? 'isme' : 'isnotme'}
            date={moment(data.sendAt * 1000).toDate()}
        />
    )
}