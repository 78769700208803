import './index.scss'
import Header from './Header'
import Footer from 'element/Footer'

export default function HomeContainer({name, children}) {
    return (
        <div className="home-container element">
            <Header />
            <div className="main-container">
                <div className="child-container" data-name={name}>
                    {children}
                    <Footer />
                </div>
            </div>
        </div>
    )
}