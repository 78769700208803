import { Checkbox, Tooltip } from "antd";
import { useEffect, useState } from "react";
import request from "utils/request";

export default function AvailableCheck() {
    const [checked, setChecked] = useState(false)

    const refreshState = () => {
        request('/user/availableForReplyChat').then(state => {
            setChecked(state)
        }).catch(err => console.error(err.message || err))
    }

    const change = (state) => {
        request('/user/setAvailableForReplyChat', 'POST', { state }).then(() => {
            refreshState()
        }).catch(err => console.error(err.message || err))
    }

    useEffect(() => {
        refreshState()
    }, [])

    return (
        <Tooltip title='Bersedia menerima pesan'>
            <Checkbox
                style={{
                    marginLeft: '15px'
                }}
                checked={checked}
                onChange={e => change(e.target.checked)}
            />
        </Tooltip>
    )
}