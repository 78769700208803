import { Card, Typography, Badge } from "antd";
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import moment from 'moment-timezone'
import 'moment/locale/id'

export default function ConversationItem({data, onClick, active}) {
    return (
        <Card
            className="conversation-item"
            bordered={active}
            hoverable
            size='small'
            onClick={onClick}
            data-active={active ? 1 : 0}
        >
            <div className="flex">
                <AccountCircleOutlinedIcon className="avatar-icon" />
                <div className="info">
                    <Typography className="name">{data.name}</Typography>
                    {data.lastMessage && (
                        <Typography className="last-message">{data.lastMessage}</Typography>
                    )}
                </div>
                <div className="right">
                    <div className="date">{moment((data.lastMessageEpoch ? data.lastMessageEpoch : data.createdAt) * 1000).tz('Asia/Jakarta').format('DD MMM H.m')}</div>
                   {data.unreadCount > 0 && (
                     <div className="dot" />
                   )}
                </div>
            </div>
        </Card>
    )
}