import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import MapIcon from '@mui/icons-material/Map';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import SupervisedUserCircleOutlinedIcon from '@mui/icons-material/SupervisedUserCircleOutlined';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import DiscountIcon from '@mui/icons-material/Discount';
import ReportIcon from '@mui/icons-material/Report';

const sidebarData = {
    generalMenus: [
        {
            name: "Profil Saya",
            url: "/profile",
            icon: <AssignmentIndOutlinedIcon className='icon' />
        },
        {
            name: "Alamat",
            url: "/address",
            icon: <MapIcon className='icon' />
        },
        {
            name: "Pesanan Baru",
            url: '/new-order',
            icon: <ShoppingBagOutlinedIcon className='icon' />
        }
    ],
    default: [
        {
            name: "Pesanan",
            url: "/order",
            icon: <ReceiptLongOutlinedIcon className='icon' />
        },
        {
            name: "Chat",
            url: "/chat",
            icon: <CommentOutlinedIcon className='icon' />
        }
    ],
    developer: [
        {
            name: "Kelola Pengguna",
            url: "/manage-user",
            icon: <SupervisedUserCircleOutlinedIcon className='icon' />
        },
        {
            name: "Voucer",
            url: "/voucer",
            icon: <DiscountIcon className='icon' />
        },
        
    ],
    adminPrivate: [
        {
            name: "Pembukuan",
            url: "/private-pembukuan",
            icon: <DataUsageIcon className='icon' />
        },
    ],
    admin: [
        {
            name: "Kritik & Saran",
            url: '/kritik-dan-saran',
            icon: <ReportIcon className='icon' />
        },
        {
            name: "Kelola Pengguna",
            url: "/manage-user",
            icon: <SupervisedUserCircleOutlinedIcon className='icon' />
        },
    ]
}

export default sidebarData