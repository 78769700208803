import { Button, Checkbox, Col, Input, Row, Table } from "antd";
import moment from "moment-timezone";
import rupiah from "utils/rupiah";
import urlify from "utils/urlify";
import 'moment/locale/id'
import './index.scss'
import { useEffect, useState } from "react";
import CancelDialog from './CancelDialog'
import request from "utils/request";
import { useNavigate } from "react-router";

let updateHeavyInt, updateTransferInt, updateMarkingCodeInt

export default function DataPreview({orders, onChanged, onSelected}) {

    const [width, setWidth] = useState(window.innerWidth)
    const [height, setHeight] = useState(window.innerHeight)
    const [cancelOrderId, setCancelOrderId] = useState(null)
    const [search, setSearch] = useState('')
    const [orderIds, setOrderIds] = useState([])

    const navigate = useNavigate()

    useEffect(() => {
        setOrderIds([])
    }, [orders])

    useEffect(() => {
        onSelected(orderIds)
    }, [orderIds])

    const handleResize = () => {
        setWidth(window.innerWidth)
        setHeight(window.innerHeight)
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    return (
        <div className="data-preview">
            <CancelDialog
                orderId={cancelOrderId}
                onClose={() => setCancelOrderId(null)}
                onChanged={onChanged}
            />
            <Table
                className="table"
                columns={[
                    {
                        key: "checkbox",
                        title: <Checkbox
                            checked={orderIds.length === orders.length}
                            indeterminate={orderIds.length > 0 && orderIds.length < orders.length}
                            onChange={e => {
                                if(e.target.checked) {
                                    setOrderIds(orders.map(i => i.invoice))
                                } else {
                                    setOrderIds([])
                                }
                            }}
                        ></Checkbox>,
                        render: (_, i) => <Checkbox 
                            checked={orderIds.includes(i.invoice)}
                            onChange={e => {
                                let x = [...orderIds]
                                if(e.target.checked) {
                                    x.push(i.invoice)
                                } else {
                                    x = x.filter(y => y !== i.invoice)
                                }
                                setOrderIds(x)
                            }}
                        />,
                        width: 30
                    },
                    {
                        key: 'no',
                        width: 40,
                        title: "No",
                        render: (text, _, i) => <div className="no">{i + 1}</div>
                    },
                    {
                        key: "orderId",
                        title: "Invoice",
                        render: (text, _) => <div className="invoice"
                            onClick={() => {
                                navigate(`/order?search=${_.invoice}&tab=${_.statusKey}`)
                            }}
                        >{_.invoice}</div>
                    },
                    {
                        key: 'admin',
                        title: "Admin",
                        render: (text, _) => <div className="admin">{_.admins.join(',')}</div>
                    },
                    {
                        key: "shippingPrice",
                        title: "Harga Pengiriman",
                        render: (text, i) => <div className="shipping-price">{rupiah.encode(i.shippingPrice)}</div>
                    },
                ]}
                pagination={false}
                bordered={true}
                rowClassName={record => {
                    return record.finished ? 'finished-row' : ''
                }}
                title={() => <Input 
                    placeholder="Cari"
                    value={search}
                    onChange={e => setSearch(e.target.value.toLowerCase())}
                    className="search-field"
                />}
                dataSource={orders.filter(x => {
                    return x.invoice.toLowerCase().includes(search)
                })}
                size='small'
                scroll={{
                    x: 'max-content'
                }}
                summary={pd => {
                    let shippingPrice = 0, heavy = 0, transfer = 0
                    
                    pd.forEach((p) => {
                        shippingPrice+= p.shippingPrice
                        heavy+= p.heavy
                        transfer+= p.transferPrice
                    })

                    return (
                        <Table.Summary.Row>
                            <Table.Summary.Cell colSpan={2}>Total:</Table.Summary.Cell>
                            <Table.Summary.Cell>Berat: {rupiah.encode(heavy).replace('Rp', '').trim()}</Table.Summary.Cell>
                            <Table.Summary.Cell colSpan={1}>Transfer: {rupiah.encode(transfer)}</Table.Summary.Cell>
                            <Table.Summary.Cell>{rupiah.encode(shippingPrice)}</Table.Summary.Cell>
                        </Table.Summary.Row>
                    )
                }}
            />
        </div>
    )
} 