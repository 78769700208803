// Images
import pinkWallet from 'dist/ilustration/pink-wallet.png'
import jetPlane from 'dist/ilustration/jet-plane.png'
import { Typography } from 'antd'
import conf from 'conf'

export default function Superiority() {
    return (
        <div className="superiority">
            <div className="item">
                <img src={jetPlane} alt="Pengiriman cepat" data-mobile="" />
                <div className="content">
                    <Typography className='title'>Pengiriman <div className="different" style={{
                        color: "rgb(255, 109, 79)"
                    }}>Cepat</div></Typography>
                    <Typography className='desc'>
                        Kami memahami bahwa Anda tidak ingin menunggu terlalu lama untuk mendapatkan produk impian Anda. Dengan {conf.appName}, Anda dapat menikmati pengiriman cepat dengan estimasi waktu antara 2-4 minggu. Kami bekerja sama dengan mitra logistik terpercaya untuk memastikan setiap paket tiba tepat waktu di depan pintu Anda. Jadi, belanja sekarang dan rasakan kenyamanan berbelanja online dengan {conf.appName}.
                    </Typography>
                </div>
                <img src={jetPlane} alt="Pengiriman cepat" />
            </div>
            <div className="item">
                <img
                    src={pinkWallet}
                    alt='Biaya Murah'
                    data-mobile=""
                />
                <img
                    src={pinkWallet}
                    alt='Biaya Murah'
                />
                <div className="content">
                    <Typography className='title'>Biaya <div className="diff" style={{
                        color: "rgb(18, 128, 121)"
                    }}>Murah</div></Typography>
                    <Typography className='desc'>Kami di {conf.appName} percaya bahwa belanja online haruslah terjangkau dan menyenangkan. Oleh karena itu, kami menawarkan biaya pengiriman yang sangat terjangkau untuk memastikan pengalaman berbelanja Anda tidak hanya mudah, tapi juga hemat. Dengan biaya murah yang kammi tawarkan, Anda dapat dengan tenang berbelanja dari AliExpress tanpa perlu khawatir tentang biaya pengiriman yang tinggi. Kami berkomitmen untuk menyediakan layanan pengiriman yang hemat dan ramah kantong bagi semua pelanggan kami. Jadi, mulailah berbelanja sekarang dan temukan betapa murahnya belanja dengan {conf.appName}.</Typography>
                </div>
            </div>
        </div>
    )
}