import { useEffect, useState } from 'react' 
import { Helmet } from 'react-helmet'
import conf from 'conf'
import { useLocation } from 'react-router'

export default function MetaHandler() {
    const [options, setOptions] = useState({})
    const location = useLocation()

    useEffect(() => {
        const meta = conf.metas.find(x => x.url === location.pathname.split('?')[0].split("#")[0].toLocaleLowerCase())
        if(meta) {
            setOptions(meta)            
        } else {
            // Gunakan default
            setOptions(conf.metas.find(x => x.url === null))
        }
    }, [location])

    return (
        <Helmet>
            {Object.entries(options).filter(x => x[0] !== 'url').map((option, i) => (
                <meta name={option[0]} key={i} content={option[1]}></meta>
            ))}
        </Helmet>
    )
}