import { Button, Typography } from 'antd'
import conf from 'conf'
import digitalOrder from 'dist/ilustration/digital-order.jpeg'
import logo from 'dist/image/logo-small.png'
import { Link } from 'react-router-dom'

export default function LevelUp() {
    return (
        <div className="level-up">
            <div className="container">
                <div className="left">
                    <img src={logo} alt={conf.appName} className="logo" />
                    <Typography className='title'>Tingkatkan pengalaman belanja Anda</Typography>
                    <Typography className='desc'>Dengan <b>{conf.appName}</b></Typography>
                    <Link to='/register'>
                        <Button
                            size='large'
                            style={{
                                marginTop: '15px'
                            }}
                        >Daftar Sekarang</Button>
                    </Link>
                </div>
                <div className="right">
                    <img src={digitalOrder} alt="Level UP" />
                </div>
            </div>
        </div>
    )
}