import { Button, Card, Input, Radio } from "antd";
import SearchIcon from '@mui/icons-material/Search';
import { useEffect, useRef, useState } from "react";
import isValidAliexUrl from "utils/isValidAliexUrl";
import isValidHttpsUrl from "utils/isValidHttpsUrl";
import { useNavigate } from "react-router";
import { encode } from 'urlencode'
import { Link } from "react-router-dom";
import ContentPasteIcon from '@mui/icons-material/ContentPaste';

export default function OrderStarter() {

    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [value, setValue] = useState('')
    const [type, setType] = useState('auto')
    const [valid, setValid] = useState(false)
    const [url, setUrl] = useState('/')

    useEffect(() => {
        if (type === 'auto') {
            setValid(isValidAliexUrl(value))
        } else {
            setValid(isValidHttpsUrl(value))
        }
        const url = `/new-order?type=${type}&prodUrl=${encode(value)}`
        setUrl(url)
    }, [value, type])

    useEffect(() => {
        if (value.includes('?')) { setValue(value.split('?')[0]) }
    }, [value])

    const checkBtnRef = useRef()

    return (
        <Card className="order-starter"
            size='small'
            hoverable
        >
            <Radio.Group
                className="type-group"
                value={type}
                onChange={e => setType(e.target.value)}
            >
                <Radio value='auto'>Otomatis</Radio>
                <Radio value='custom'>Custom</Radio>
            </Radio.Group>
            <Input
                placeholder={type === 'auto' ? "Paste URL Produk Aliexpress" : "Masukkan URL produk dari marketplace selain AliExpress"}
                prefix={<ContentPasteIcon className="icon-start" />}
                suffix={<Link to={!valid ? '#' : url}>
                    <Button ref={checkBtnRef} loading={loading} type='primary' danger disabled={!valid}
                    >CHECK</Button>
                </Link>}
                className="input-control"
                value={value}
                disabled={loading}
                onChange={e => setValue(e.target.value)}
                onKeyUp={e => {
                    if(e.keyCode === 13) {
                        checkBtnRef.current.click()
                    }
                }}
            />
        </Card>
    )
}