import { Card, Steps } from "antd";
import moment from 'moment-timezone'
import 'moment/locale/id'

export default function AdminWorkStatus({order}) {
    return (
        <Card
            size='small'
            className="admin-work-status-card"
        >
            <Steps 
                items={[
                    {
                        title: order.admin1.name,
                        status: order.admin1.finished ? 'finish' : 'error',
                        description: order.admin1.finishedAt ? moment(order.admin1.finishedAt * 1000).tz('Asia/Jakarta').format('DD MMM HH.mm') : ''
                    },
                    {
                        title: order.admin2.name,
                        status: order.admin2.finished ? 'finish' : 'error',
                        description: order.admin2.finishedAt ? moment(order.admin2.finishedAt * 1000).tz('Asia/Jakarta').format('DD MMM HH.mm') : ''
                    },
                    {
                        title: order.admin3.name,
                        status: order.admin3.finished ? 'finish' : 'error',
                        description: order.admin3.finishedAt ? moment(order.admin3.finishedAt * 1000).tz('Asia/Jakarta').format('DD MMM HH.mm') : ''
                    }
                ]}
                size='small'
            />
        </Card>
    )
}