import { Empty, Button, Typography } from 'antd'
import emptyConversationImage from 'dist/chat/empty-conversation.png'

export default function EmptyConversation({onAddRequest}) {
    return (
        <div className="empty-conversation">
        <Empty 
            description={(
                <div className="action-container">
                    <Typography>Anda tidak memiliki pesan</Typography>
                    <Button type='primary'
                        style={{
                            marginTop: '10px'
                        }}
                        onClick={onAddRequest}
                    >Mulai Percakapan</Button>
                </div>
            )}
        />
    </div>
    )
}