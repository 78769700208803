import { Alert, Flex, Input, Modal, Select, Tag, Typography } from "antd";
import conf from "conf";
import { useEffect, useState } from "react";
import request from "utils/request";

export default function EditDialog({ id, open, onClose, onReload }) {
    const [loading, setLoading] = useState(false)
    const [name, setName] = useState('')
    const [district, setDistrict] = useState(null)
    const [regency, setRegency] = useState(null)
    const [village, setVillage] = useState(null)
    const [province, setProvince] = useState(null)
    const [additionalAddress, setAdditionalAddress] = useState('')
    const [error, setError] = useState(null)
    const [type, setType] = useState(null)

    // Stored data
    const [districts, setDistricts] = useState([])
    const [regencies, setRegencies] = useState([])
    const [villages, setVillages] = useState([])
    const [provinces, setProvinces] = useState([])

    const onSave = () => {
        setError(null)
        setLoading(true)
        request('/user/updateAddress', 'POST', {
            id,
            district: JSON.parse(district).name,
            village: JSON.parse(village).name,
            regency: JSON.parse(regency).name,
            province: JSON.parse(province).name,
            additionalAddress: additionalAddress,
            type
        }).then(data => {
            setError(false)
            onClose()
            onReload()
        }).catch(err => {
            setError(err.message || err)
        }).finally(() => {
            setLoading(false)
        })
    }

    useEffect(() => {
        if (province === null) { setRegency(null) } else {
            // Fetch  the regencies
            const p = JSON.parse(province)
            if (typeof p.url === 'string') {
                const url = p.url.split(conf.backendUrl).join('')
                request(url).then(data => {
                    setRegencies(data.map(x => JSON.stringify(x)))
                    setRegency(null)
                })
            }
        }
    }, [province])

    const fetchProvinces = () => {
        request('/region/provinces').then(data => {
            setProvinces(data.map(x => JSON.stringify(x)))
        }).catch(err => {
            console.error(`Failed for get the provinces: ${err.message || err}`)
        })
    }

    const fetchAddressId = () => {
        setLoading(true)
        request('/user/getAddress/' + id).then(data => {
            setName(data.name)
            setVillage(JSON.stringify({
                name: data.village,
                id: 0
            }))
            setType(data.type)
            setRegency(JSON.stringify({
                name: data.regency,
                id: 0
            }))
            setProvince(JSON.stringify({
                name: data.province,
                id: 0
            }))
            setDistrict(JSON.stringify({
                name: data.district,
                id: 0
            }))
            setAdditionalAddress(data.additionalAddress)

            fetchProvinces()
        }).catch(err => {
            console.error(`Failed for get the address detail: ${err.message || err}`)
        }).finally(() => {
            setLoading(false)
        })
    }

    useEffect(() => {
        if (open) {
            fetchAddressId()
        } else {
            setName('')
        }
        setLoading(false)
    }, [open])

    useEffect(() => {
        if(regency === null) { setDistrict(null) } else {
            const r = JSON.parse(regency)
            if(typeof r.url === 'string') {
                const url = r.url.split(conf.backendUrl).join('')
                request(url).then(data => {
                    setDistricts(data.map(x => JSON.stringify(x)))
                    setDistrict(null)
                }).catch(err => {
                    console.error(`Failed for load districts data: ${err.message || err}`)
                })
            }
        }
    }, [regency])

    useEffect(() => {
        if(district === null) { setVillage(null) } else {
            const d = JSON.parse(district)
            if(typeof d.url === 'string') {
                const url = d.url.split(conf.backendUrl).join('')
                request(url).then(data => {
                    setVillages(data.map(x => JSON.stringify(x)))
                    setVillage(null)
                }).catch(err => {
                    console.error(`Failed for load villages data: ${err.message || err}`)
                })
            }
        }
    }, [district])
    
    return (

        <Modal
            title='Ubah Lokasi'
            open={open}
            onCancel={onClose}
            onOk={onSave}
            centered
            width={400}
            cancelText='Batal'
            okText='Simpan'
            className="address-edit-dialog"
            cancelButtonProps={{
                disabled: loading,
            }}
            closable={!loading}
            okButtonProps={{
                loading
            }}
        >
            <div className="forms">
                {error && (
                    <Alert 
                        type='error'
                        message={error}
                        showIcon
                    />
                )}
                
                <div className="form-control">
                    <Typography className="label">Nama</Typography>
                    <Input
                        className="field"
                        size='large'
                        value={name}
                        onChange={e => setName(e.target.value)}
                        disabled={loading}
                    />
                </div>
                <div className="form-control">
                    <Typography className="label">Jenis Alamat</Typography>
                    <Flex wrap='wrap' gap={5}>
                        {conf.addressTypes.map((x, i) => (
                            <Tag key={i} color={type === x.key ? 'red' : 'default'}
                                style={{ cursor: 'pointer' }}
                                onClick={() => setType(x.key)}
                            >{x.name}</Tag>
                        ))}
                    </Flex>
                </div>
                <div className="form-control">
                    <Typography className="label">Provinsi</Typography>
                    <Select value={province === null ? '-' : JSON.parse(province).name}
                        size='large'
                        showSearch
                        className="field"
                        onChange={e => setProvince(e)}
                        placeholder
                        disabled={loading}
                    >
                        {provinces.map((province, i) => (
                            <Select.Option value={province} key={i}>{JSON.parse(province).name}</Select.Option>
                        ))}
                    </Select>
                </div>
                <div className="form-control">
                    <Typography className="label">Daerah</Typography>
                    <Select value={regency === null ? '-' : JSON.parse(regency).name}
                        size='large'
                        showSearch
                        className="field"
                        onChange={e => setRegency(e)}
                        disabled={loading}
                    >
                        {regencies.map((regency, i) => (
                            <Select.Option value={regency} key={i}>{JSON.parse(regency).name}</Select.Option>
                        ))}
                    </Select>
                </div>
                <div className="form-control">
                    <Typography className="label">Distrik</Typography>
                    <Select
                        value={district === null ? '-' : JSON.parse(district).name}
                        size='large'
                        showSearch
                        className="field"
                        onChange={e => setDistrict(e)}
                        disabled={loading}
                    >
                        {districts.map((district, i) => (
                            <Select.Option value={district} key={i}>{JSON.parse(district).name}</Select.Option>
                        ))}
                    </Select>
                </div>
                <div className="form-control">
                    <Typography className="label">Desa</Typography>
                    <Select className="field" value={village === null ? '-' : JSON.parse(village).name}
                        size='large'
                        showSearch
                        onChange={e => setVillage(e)}
                        disabled={loading}
                    >
                        {villages.map((village, i) => (
                            <Select.Option
                                value={village}
                                key={i}
                            >{JSON.parse(village).name}</Select.Option>
                        ))}
                    </Select>
                </div>
                <div className="form-control">
                    <Typography className="label">Detail</Typography>
                    <Input.TextArea className="field"
                        size='large'
                        autoSize={{
                            minRows: 3,
                            maxRows: 4
                        }}
                        showCount
                        maxLength={300}
                        disabled={loading}
                        value={additionalAddress}
                        style={{
                            marginBottom: '15px'
                        }}
                        onChange={e => setAdditionalAddress(e.target.value)}
                    ></Input.TextArea>
                </div>
            </div>
        </Modal>
    )
}