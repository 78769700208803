import { Alert, Button, Input, Radio, Typography } from "antd";
import MainAppContainer from "element/MainAppContainer";
import './index.scss'
import { useEffect, useState } from 'react'
import request from "utils/request";
import SuccessAlert from "./SuccessAlert";
import { Helmet } from "react-helmet";
import conf from "conf";

export default function Profile() {
    const [name, setName] = useState('')
    const [birthDate, setBirthDate] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [gender, setGender] = useState('')
    const [loading, setLoading] = useState(true)
    const [showSuccessAlert, setShowSuccessAlert] = useState(false)
    const [error, setError] = useState(false)

    useEffect(() => {
        setLoading(true)
        // Get the profile detail
        request('/user/get/profile').then(data => {
            setName(data.name)
            setEmail(data.email)
            setBirthDate(data.birthDate)
            setPhone(data.phone)
            setGender(data.gender)
        }).catch(err => console.error(`Failed for get profile: ${err.message || err}`))
        .finally(() => setLoading(false))
    }, [])

    const update = () => {
        setLoading(true)
        setError(false)
        request('/user/updateProfile', 'POST', {
            name,
            email,
            birthDate,
            phone,
            gender
        }).then(() => {
            setShowSuccessAlert(true)
            setError(false)
        }).catch(err => {
            console.error(`Failed for update profile: ${err.message || err}`)
            setError(err.message || err)
        }).finally(() => {
            setLoading(false)
        })
    }

    return (
        <MainAppContainer name='profile'>
            <Helmet>
                <title>{conf.appName} - Profil Saya</title>
            </Helmet>
            <SuccessAlert
                open={showSuccessAlert}
                onClose={() => setShowSuccessAlert(false)}
            />
            <div className="form-controls">
                {error && <Alert type='error' message={error} showIcon
                />}
                <div className="form-control">
                    <Typography className="label">Nama</Typography>
                    <Input
                        className="input"
                        size='large'
                        disabled={loading}
                        showCount
                        maxLength={30}
                        value={name}
                        onChange={e => setName(e.target.value)}
                    />
                </div>
                <div className="form-control">
                    <Typography className="label">Tanggal Lahir</Typography>
                    <Input type='date'
                        size='large'
                        className="input"
                        disabled={loading}
                        value={birthDate}
                        onChange={e => setBirthDate(e.target.value)}
                    />
                </div>
                <div className="form-control">
                    <Typography className="label">Email</Typography>
                    <Input
                        className="input"
                        size='large'
                        readOnly
                        disabled
                        value={email}
                    />
                </div>
                <Alert type='error' message='Ubah Password' />
                <div className="form-control">
                    <Typography className="label">No. Handphone</Typography>
                    <Input
                        className="input"
                        size='large'
                        type='number'
                        disabled={loading}
                        value={phone}
                        onChange={e => setPhone(e.target.value)}
                    />
                </div>
                <div className="form-control">
                    <Typography className="label">Jenis Kelamin</Typography>
                    <Radio.Group className="input" 
                        disabled={loading}
                        value={gender}
                        onChange={e => setGender(e.target.value)}
                    >
                        <Radio value='L'>Laki-Laki</Radio>
                        <Radio value='P'>Perempuan</Radio>
                    </Radio.Group>
                </div>
                <Button
                    size='large'
                    loading={loading}
                    type='primary'
                    onClick={update}
                    className="save-btn"
                >{!loading ? "Simpan" : undefined}</Button>
            </div>
        </MainAppContainer>
    )
}