import conf from "conf";
import MainAppContainer from "element/MainAppContainer";
import { Helmet } from "react-helmet";
import { useEffect, useState } from 'react'
import { Image, Spin, Typography } from "antd";
import './index.scss'
import errorImage from 'dist/image/error.png'
import request from "utils/request";
import { useLocation } from "react-router";
import Detail from './Detail'

export default function OrderDetail() {
    const [order, setOrder] = useState(null) // False = error | null = loading | Object = order
    const [orderId, setOrderId] = useState(null)

    const location = useLocation()
    
    const refresh = () => {
        if(typeof orderId !== 'string') { return }
        request('/order/detail?inv=' + orderId).then(data => {
            setOrder(data)
        }).catch(err => {
            console.error(`Failed for get the order: ${err.message || err}`)
            setOrder(false)
        })
    }

    useEffect(() => {
        refresh()
    }, [orderId])

    useEffect(() => {
        const search = new URLSearchParams(location.search)
        if(search.has('code')) { setOrderId(search.get('code')) }
    }, [location])

    return (
        <MainAppContainer name='order-detail'>
            <Helmet>
                <title>{conf.appName} - Detail Pesanan</title>
            </Helmet>

            {order === null && (
                <div className="loading-container">
                    <Spin 
                        size='large'
                        className="spinner"
                    />
                    <Typography className="title">Memuat ...</Typography>
                </div>
            )}
            
            {order === false && (
                <div className="error-container">
                    <Image 
                        src={errorImage}
                        preview={false}
                        className="image"

                    />
                    <Typography className="title">Terjadi kesalahan saat memuat halaman</Typography>
                </div>
            )}


            {typeof order === 'object' && (
                <Detail data={order}
                    onReload={refresh}
                />
            )}
        </MainAppContainer>
    )
}