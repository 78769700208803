import { Modal, Typography } from "antd";
import { useState } from "react";
import request from "utils/request";

export default function CancelDialog({orderId, onClose, onChanged}) {

    const [loading, setLoading] = useState(false)

    const cancel = () => {
        setLoading(true)
        request('/order/cancelFromPrivatePembukuan', 'POST', {
            orderId
        }).then(() => {
            onClose()
            onChanged()
        }).catch(err => console.error(err.message || err)).finally(() => {
            setLoading(false)
        })
    }

    return (
        <Modal
            title='Batalkan'
            onCancel={onClose}
            width={350}
            centered
            open={typeof orderId === 'string'}
            cancelText='Tutup'
            cancelButtonProps={{
                disabled: loading
            }}
            okButtonProps={{
                loading
            }}
            okText='Batalkan'
            onOk={cancel}
        >
            <Typography>Anda yakin ingin membatalkan pesanan <b>{orderId}</b></Typography>
        </Modal>
    )
}