import { Alert, Input, Modal } from "antd";
import { useEffect, useState } from "react";
import request from "utils/request";

export default function AddTimeline({ open, onClose, onAdded }) {
    const [name, setName] = useState('')
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const add = () => {
        setError(null)
        setLoading(true)
        request('/user/addTemplateTrack', 'POST', {
            name,
            title,
            description
        }).then(() => {
            onClose()
            setError(null)
            setTimeout(() => {
                onAdded()
            }, 1000)
        }).catch(err => {
            console.error(`Failed for add template track: ${err.toString()}`);
            setError(err.toString())
        })
        .finally(() => {
            setLoading(false)
        })
    }

    useEffect(() => {
        setTitle('')
        setName('')
        setDescription('')
    }, [open])

    return (
        <Modal title='Tambahkan Template'
            centered
            closable={!loading}
            width={350}
            open={open}
            onCancel={onClose}
            className="add-timeline-dialog"
            okText='Simpan'
            cancelText='Batal'
            onOk={add}
        >
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px'
            }}>
                {error && (
                    <Alert
                        type='error'
                        showIcon
                        message={error}
                    />
                )}
                <Input
                    placeholder='Nama'
                    value={name}
                    onChange={e => setName(e.target.value)}
                />
                <Input
                    placeholder='Judul'
                    value={title}
                    onChange={e => setTitle(e.target.value)}
                />
                <Input
                    placeholder='Deskripsi'
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                />
            </div>
        </Modal>
    )
}