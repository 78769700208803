import { Modal, Typography } from "antd";
import request from "utils/request";

export default function FinishDialog({orderId, onClose, onUpdate}) {

    return (
        <Modal
            title='Selesaikan Pesanan'
            open={orderId !== null}
            onCancel={onClose}
            centered
            width={350}
            cancelText='Batal'
            okText='Selesaikan'
            onOk={() => {
                request('/order/finishFromUser', "POST", {
                    orderId
                }).then(() => {
                    onClose()
                    onUpdate()
                }).catch(err => console.error(err.message || err))
            }}
        >
            <Typography>Anda yakin ingin selesaikan ini ?</Typography>
        </Modal>
    )
}