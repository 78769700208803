import { Modal, Typography } from "antd";
import { useNavigate } from "react-router";

export default function HasPhoneAlert({open}) {
    const navigate = useNavigate()

    return (
        <Modal
            title='Warning'
            open={open}
            onCancel={() => {}}
            centered
            width={350}
            cancelButtonProps={{
                style: {
                    display: 'none'
                }
            }}
            okText='Set No. telp'
            onOk={() => navigate('/profile')}
            closable={false}
        >
            <Typography className="paragraph">Silahkan isi nomor telfon anda untuk melakukan pembelian</Typography>
        </Modal>
    )
}