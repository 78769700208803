import MainAppContainer from "element/MainAppContainer";
import { useEffect, useState, useRef} from 'react'
import request from "utils/request";
import UserItem from "./UserItem";
import InfiniteScroll from "react-infinite-scroller";
import {
    Input,
    Button,
    Tabs
} from 'antd'
import './index.scss'
import { Helmet } from "react-helmet";
import conf from "conf";

const uniqueUsers = (users, adds) => {
    for(const add of adds) {
        const i = users.findIndex(x => x.email === add.email)
        if(i<0) {
            users.push(add)
        }
    }
    return users
}

let searchT = null

export default function ManageUser() {

    const [page, setPage] = useState(1)
    const [filter, setFilter] = useState('BOTH')
    const [search, setSearch] = useState('')
    const [users, setUsers] = useState([])
    const [loading, setLoading] = useState(false)
    const [hasMore, setHasMore] = useState(true)

    const [stats, setStats] = useState({
        admin: 0,
        all: 0,
        developer: 0,
        user: 0
    })

    const filters = [
        {
            label: `Semua${stats.all>0 && ` (${stats.all})`}`,
            key: "BOTH",
            children: <></>
        },
        {
            label:`Pengguna${stats.user>0 && ` (${stats.user})`}`,
            key: "USER_ONLY",
            children: <></>
        },
        {
            label: `Admin${stats.admin>0 && ` (${stats.admin})`}`,
            key: "ADMIN_ONLY",
            children: <></>
        },
        {
            label: `Developer${stats.developer>0 && ` (${stats.developer})`}`,
            key: "DEVELOPER_ONLY",
            children: <></>
        }
    ]
    
    const scroller = useRef()

    const refresh = (clear = false) => {
        setLoading(true)
        request(`/developer/users?q=${search}&page=${clear ? 1 : page}&filter=${filter}`).then(data => {
            setHasMore(data.users.length > 0)
            setUsers(clear ? data.users : uniqueUsers(users, data.users))
            setPage(clear ? 2 : page + 1)
            setStats(data.userStats)
        }).catch(err => console.error(`Failed for get the users: ${err.toString()}`)).finally(() => setLoading(false))
    }

    useEffect(() => {
        refresh(true)
    }, [filter, search])


    return (
        <MainAppContainer name='manage-user'>
            <Helmet>
                <title>{conf.appName} - Kelola Pengguna</title>
            </Helmet>
            <div className="head">
                <Tabs items={filters} activeKey={filter}
                    onChange={(e) => setFilter(e)}
                    className='tab'
                />
                <Input
                    placeholder='Cari'
                    onChange={e => {
                        if(searchT) { clearTimeout(searchT) }
                        searchT = setTimeout(() => {
                            setSearch(e.target.value)
                        }, 500)
                    }}
                    variant='filled'
                    className="search-field"
                />
            </div>
            
            <div className="users">
                <InfiniteScroll
                    hasMore={hasMore}
                    loadMore={() => {
                        if(!loading) refresh()
                    }}
                    useWindow={false}
                    getScrollParent={() => scroller.current}
                    threshold={50}
                >
                    {users.map((user, i) => (
                        <UserItem 
                            key={i}
                            user={user}
                            onDevState={(state) => {
                                let _users = [...users]
                                _users[i].isDev = state
                                setUsers(_users)
                            }}
                            onAdminState={state => {
                                let _users = [...users]
                                _users[i].isAdmin = state
                                setUsers(_users)
                            }}
                            onAccessChange={access => {
                                let _users = [...users]
                                _users[i].access = access === '-' ? [null] : [access]
                                setUsers(_users)
                            }}
                            onBannedChange={state => {
                                let _users = [...users]
                                _users[i].banned = state
                                setUsers(_users)
                            }}
                        />
                    ))}
                </InfiniteScroll>
            </div>
        </MainAppContainer>
    )
}

