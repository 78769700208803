import { Modal, Typography } from "antd";
import request from "utils/request";

export default function RejectDialog({orderId, open, onClose, onChanged}) {
    
    const reject = () => {
        request('/order/markAsDone', 'POST', {
            orderId,
            status: 'reject'
        }).then(() => {
            onClose()
            onChanged()
        }).catch(err => console.error(`Failed for reject this order: ${err.message || err}`))
    }

    return (    
        <Modal
            title='Tolak Pesanan'
            centered
            width={350}
            open={open}
            onCancel={onClose}
            okText='Tolak'
            cancelText='Batal'
            onOk={reject}
        >
            <Typography>Anda yakin ingin menolak pesanan <b>{orderId}</b> ?</Typography>
        </Modal>
    )
}