import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const RedirectToNonWWW = () => {
    const navigate = useNavigate();
    const location = useLocation()

    useEffect(() => {
        if (window.location.hostname.startsWith('www.')) {
            const newUrl = window.location.protocol + '//' + window.location.hostname.slice(4) + window.location.pathname + window.location.search;
            window.location.replace(newUrl);
        }
    }, []);

    return null;
};

export default RedirectToNonWWW;
