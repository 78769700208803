import {Alert, Button, Card, Divider, Input, Modal, Typography} from "antd";
import { useEffect, useRef, useState } from 'react'
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import './index.scss'
import google from 'dist/icon/company/google.png'
import { Link } from "react-router-dom";
import conf from "../../conf";
import request from "../../utils/request";

export default function AuthLogin({ open, onClose }) {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [loading, setLoading] = useState(false)
    const [emailError, setEmailError] = useState(null)
    const [passwordError, setPasswordError] = useState(null)
    const [error, setError] = useState(null)

    useEffect(() => {
        var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setEmailError(emailRegex.test(email) ? null : 'Email tidak valid')
    }, [email])

    useEffect(() => {
        setPasswordError(password.trim().length < 1 ? 'Password harus diisi' : null)
    }, [password])

    const emailRef = useRef()

    useEffect(() => {
        setEmail('')
        setPassword('')

        if (open && emailRef.current) {
            emailRef.current.focus()
        }
    }, [open])

    const login = () => {
        setLoading(true)
        setError(null)
        request('/session/loginCredentials', 'POST', { email, password }).then(data => {
            setError(null)
            window.location.href=`/login?newSession=${data.hash}`
        }).catch(err => setError(err.message || err)).finally(() => setLoading(false))
    }

    return (
        <Modal
            centered
            title='Masuk'
            open={open}
            onCancel={onClose}
            width={600}
            className="auth-login-modal"
            closable={!loading}
        >
            <div className="forms">
                {error && <Alert type={'error'} message={error} showIcon={true}>{error}</Alert>}
                <Input
                    prefix={<MailOutlineIcon className="icon" />}
                    size='large'
                    disabled={loading}
                    ref={emailRef}
                    variant='outlined'
                    placeholder='Alamat Email'
                    className="form-control"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    autoFocus
                    status={emailError ? 'error' : undefined}
                />
                {emailError && <Typography.Text type="danger" className={'error-message'}>{emailError}</Typography.Text>}
                <Input.Password
                    disabled={loading}
                    size='large'
                    variant='outlined'
                    placeholder="Kata Sandi"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    className="form-control"
                    prefix={<LockOutlinedIcon className="icon" />}
                />
                {passwordError && <Typography.Text type={'danger'} className={'error-message'}>{passwordError}</Typography.Text>}
                <Button className="login-btn"
                    type='primary'
                    size='large'
                    loading={loading}
                    disabled={passwordError || emailError}
                    onClick={login}
                >MASUK</Button>
                <Divider className="divider">atau lanjutkan dengan</Divider>
                <div className="another-methods">
                    <Button className="method-btn"
                        size='large'
                        loading={loading}
                        onClick={() => window.location.href=`${conf.backendUrl}/auth/google`}
                    >
                        {!loading && (
                            <div className="inside">
                                <img src={google} alt="Google" className="icon" />
                                <div className="text">Google</div>
                            </div>
                        )}
                    </Button>
                </div>
                <Typography className="register-text">Tidak punya akun? <b><Link to='/register'>Daftar Disini</Link></b></Typography>
            </div>
        </Modal>
    )
}