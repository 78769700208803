import { CheckOutlined } from "@mui/icons-material";
import { Card, Checkbox, Divider, Dropdown } from "antd";
import moment from "moment";
import request from "utils/request";

export default function UserItem({ user, onDevState, onAdminState, onAccessChange, onBannedChange }) {
    const changeDeveloper = (state) => {
        request('/developer/changeIsDev', 'POST', {
            state,
            email: user.email,
        }).then(() => {
            onDevState(state)
        }).catch(err => console.error(`Failed for change is developer: ${err}`))
    }

    const changeAdmin = state => {
        request('/developer/changeIsAdmin', "POST", {
            email: user.email,
            state
        }).then(() => {
            onAdminState(state)
        }).catch(err => console.error(`Failed for change is admin: ${err}`))
    }

    const changeAccess = (access) => {
        request('/developer/changeAccess', 'POST', {
            email: user.email,
            access
        }).then(() => {
            onAccessChange(access)
        }).catch(err => console.error(`Failed for change access: ${err.toString()}`))
    } 

    const handleOpenWa = (phone) => {
        if(!phone) { return }
        if(phone.startsWith('08')) {
            phone = `62${phone.slice(1)}`
            window.open(`https://wa.me/${phone}`, "_blank")
        }
    }

    const setBanned = (state) => {
        request('/developer/setBanned', "POST", {
            email: user.email,
            state: state
        }).then(() => {
            onBannedChange(state)
        }).catch(err => console.error(`Failed for set banned: ${err}`))
    }

    return (
        <Card className="user-item" size='small' data-email={user.email}>
            <div className="profile-detail">
                <div className="name">{user.name}</div>
                <div className="email"
                    onClick={() => window.location.href=`mailto:${user.email}`}
                >Email: <b>{user.email}</b></div>
                <div className="phone" onClick={() => {
                    handleOpenWa(user.phone)
                }}>Telp: <b>{user.phone ? user.phone : '-'}</b></div>
                <div className="gender">Jenis Kelamin: <b>{user.gender ? user.gender : '-'}</b></div>
                <div className="birth-date"
                >Tanggal Lahir: <b>{user.birthDate ? moment(user.birthDate * 1000).format('D MMMM YYYY') : '-'}</b></div>
                {user.since && (
                    <div className="since">Terdaftar sejak: <b>{moment(user.since * 1000).format('D MMMM YYYY H.m')}</b></div>
                )}
                {user.lastLogin && (
                    <div className="last-login">Terakhir Login: <b>{moment(user.lastLogin * 1000).format('D MMMM YYYY H.m')}</b></div>
                )}
            </div>
            <div className="right">
                <div className="roles">
                    Peran: <Dropdown menu={{
                        items: accesses.map((_, i) => {
                            return {
                                label: _[1],
                                key: _[0],
                                onClick: () => changeAccess(_[0])
                            }
                        }),
                    }}
                    trigger={['click']}
                    >
                        <div className="current-role">{
                            user.access[0] === null ? '[Tidak Ada]' : user.access.join(', ')
                        }</div>
                    </Dropdown>
                </div>
                <Checkbox
                    checked={user.isDev}
                    onChange={(e) => {
                        changeDeveloper(e.target.checked)
                    }}
                >Developer</Checkbox>
                <Checkbox
                    checked={user.isAdmin}
                    onChange={e => {
                        changeAdmin(e.target.checked)
                    }}
                >Admin</Checkbox>
                <Checkbox  
                    onChange={e => setBanned(e.target.checked)}
                    checked={user.banned}
                >Banned</Checkbox>
            </div>
        </Card>
    )
}

const accesses = [
    ['admin1', 'Admin1'],
    ['admin2', 'Admin2'],
    ['admin3', 'Admin3'],
    ['-', '-']
]