import { Button, Card, Checkbox, Col, Flex, Input, Row, Table, Tag, Typography } from "antd";
import moment from "moment-timezone";
import rupiah from "utils/rupiah";
import urlify from "utils/urlify";
import 'moment/locale/id'
import './index.scss'
import { useEffect, useState } from "react";
import CancelDialog from './CancelDialog'
import request from "utils/request";
import { Link } from "react-router-dom";

let updateHeavyInt, updateTransferInt, updateMarkingCodeInt

export default function DataPreview({ orders, onChanged, onSelected }) {

    const [width, setWidth] = useState(window.innerWidth)
    const [height, setHeight] = useState(window.innerHeight)
    const [cancelOrderId, setCancelOrderId] = useState(null)
    const [search, setSearch] = useState('')
    const [orderIds, setOrderIds] = useState([])

    useEffect(() => {
        onSelected(orderIds)
    }, [orderIds])

    const handleResize = () => {
        setWidth(window.innerWidth)
        setHeight(window.innerHeight)
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    return (
        <div className="data-preview">
            <CancelDialog
                orderId={cancelOrderId}
                onClose={() => setCancelOrderId(null)}
                onChanged={onChanged}
            />
            <Table
                className="table"
                rowKey={"invoice"}
                columns={[
                    {
                        key: "no",
                        width: 70,
                        title: "No",
                        render: (_, i, x) => <Typography className="no">{x + 1}</Typography>
                    },
                    {
                        key: "invoice",
                        title: "Invoice",
                        width: 240,
                        render: (_, i) => <Typography className="date">{i.invoice}</Typography>
                    },
                    {
                        key: "priceInTokped",
                        title: window.location.href.includes('beliexpress.com') ? 'Harga Jual' : "Harga Tokped",
                        width: 160,
                        render: (_, i) => <Typography className="harga-tokped">{rupiah.encode(i.priceInTokped)}</Typography>
                    },
                    {
                        key: "capitalPrice",
                        title: "Harga Modal",
                        width: 160,
                        render: (_, i) => <Typography className="capital-price">{rupiah.encode(i.capitalPrice)}</Typography>
                    },
                    {
                        key: "profit",
                        title: "Profit",
                        width: 160,
                        render: (_, i) => <Typography className="profit">{rupiah.encode(i.profit)}</Typography>
                    },
                    {
                        key: "Pada",
                        title: "Pada",
                        render: (_, i) => <Typography className="at">{moment(i.at * 1000).tz('Asia/Jakarta').format('dddd, D MMMM YYYY')}</Typography>
                    }
                ]}
                pagination={false}
                bordered={true}
                rowClassName={record => {
                    return record.finished ? 'finished-row' : ''
                }}
                title={() => <Input
                    placeholder="Cari"
                    value={search}
                    onChange={e => setSearch(e.target.value.toLowerCase())}
                    className="search-field"
                />}
                dataSource={orders.filter(x => {
                    return x.invoice.toLowerCase().includes(search)
                })}
                size='small'
                scroll={{
                    x: 'max-content'
                }}
                summary={pd => {
                    let priceInTokped = 0,
                        capitalPrice = 0,
                        profit = 0

                    pd.forEach((p) => {
                        priceInTokped+= p.priceInTokped
                        capitalPrice+= p.capitalPrice
                        profit+= p.profit
                    })

                    return (
                        <Table.Summary.Row>
                            <Table.Summary.Cell colSpan={2}>Total:</Table.Summary.Cell>
                            <Table.Summary.Cell align="left">{rupiah.encode(priceInTokped)}</Table.Summary.Cell>
                            <Table.Summary.Cell align="left">{rupiah.encode(capitalPrice)}</Table.Summary.Cell>
                            <Table.Summary.Cell align="left">{rupiah.encode(profit)}</Table.Summary.Cell>
                            <Table.Summary.Cell />
                        </Table.Summary.Row>
                    )
                }}
                // expandable={{
                //     expandedRowRender: (record) => {

                //         return (
                //             <div className="expanded-element">
                //                 <div className="product">
                //                     <Typography className="name">{record.product.name}</Typography>
                //                     <div className="row">
                //                         <div className="row images">
                //                             {record.product.images.map((image, i) => (
                //                                 <img
                //                                     src={image}
                //                                     key={i}
                //                                     title={record.product.name}
                //                                     alt={record.product.name}
                //                                 />
                //                             ))}
                //                         </div>
                //                     </div>
                //                     <div className="row unit-price">
                //                         Harga Unit: {rupiah.encode(record.unitPrice)}
                //                     </div>
                //                     <Link to={record.product.url}
                //                         target='_blank'
                //                         className="prod-url"
                //                     >{record.product.url}</Link>
                //                     <Flex wrap='wrap' className="props">
                //                         {record.props.map((prop, i) => (
                //                             <Tag key={i}>{prop.name}: {prop.value}</Tag>
                //                         ))}
                //                     </Flex>
                //                 </div>
                //             </div>
                //         )
                //     }
                // }}
            />
        </div>
    )
} 