import cookies from 'react-cookies'
import {useEffect} from "react";
import {useLocation, useNavigate} from "react-router";

export default function Login() {
    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        const search = new URLSearchParams(location.search)
        if(search.has('newSession')) {
            const session = search.get('newSession')
            cookies.save('session', session, {
                path: '/',
                secure: false,
                sameSite: 'strict'
            })

            setTimeout(() => {
                navigate('/')
            }, 1000)
        }

    }, [location]);

    return (
        <div>Please wait ...</div>
    )
}