import { Alert, Checkbox, Input, Modal, Select, Typography } from "antd";
import { useEffect, useState } from "react";
import request from "utils/request";

export default function AddDialog({ open, onClose, onAdded, as, voucerId }) {
    const [code, setCode] = useState('')
    const [isLimited, setIsLimited] = useState(true)
    const [limit, setLimit] = useState(100)
    const [type, setType] = useState('PERCENTAGE')
    const [decreaseValue, setDecreaseValue] = useState(0) 
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    useEffect(() => {
        if(open && as === 'edit' && voucerId !== null) {
            setError(null)
            setLoading(true)
            request('/voucer/detail/'+voucerId).then((data) => {
                setCode(data.code)
                setIsLimited(data.isLimited)
                setLimit(data.limit)
                setType(data.type)
                setDecreaseValue(data.decreaseValue)
            }).catch(err => setError(err.message || err))
            .finally(() => setLoading(false))
        }
    }, [open, as, voucerId])

    const update = () => {
        setError(null)
        setLoading(true)
        request('/voucer/update', 'POST', {
            id: voucerId,
            code,
            isLimited,
            limit,
            type,
            decreaseValue,
        }).then(() => {
            onClose()
            onAdded()
        }).catch(err => setError(err.message || err))
        .finally(() => setLoading(false))
    }

    const make = () => {
        setError(null)
        setLoading(true)
        request('/voucer/make', "POST", {
            code,
            isLimited,
            limit,
            type,
            decreaseValue
        }).then(() => {
            onAdded()
            onClose()
        }).catch(err => {
            console.error(err.message || err)
            setError(err.message || err)
        })
        .finally(() => setLoading(false))
    }

    useEffect(() =>  {
        setCode('')
        setError(null)
        setError(false)
    }, [open])

    return (
        <Modal
            centered
            width={400}
            open={open}
            onCancel={onClose}
            title={as == 'edit' ? 'Ubah Voucer' : "Tambahkan Voucer"}
            cancelText='Batal'
            okText='Simpan'
            cancelButtonProps={{ disabled: loading }}
            okButtonProps={{
                loading
            }}
            onOk={() => {
                as === 'edit' ? update() : make()
            }}
        >
            <div className="add-voucer-dialog">
                {error && (
                    <Alert
                        type='error'
                        message={error}
                    />
                )}
                <div className="form-control">
                    <Typography className="label">Code</Typography>
                    <Input
                        className="code-field field"
                        size='small'
                        placeholder="Code"
                        disabled={loading}
                        value={code}
                        onChange={e => {
                            setCode(e.target.value.toUpperCase().split(' ').join(''))
                        }}
                    />
                </div>
                <div className="form-control">
                    <Typography className="label">Limit</Typography>
                    <div className="flex">
                        <Checkbox 
                            checked={isLimited}
                            disabled={loading}
                            onChange={e => setIsLimited(e.target.checked)}
                        />
                        <Input 
                            type='number'
                            size='small'
                            placeholder='Limit'
                            disabled={!isLimited || loading}
                            value={limit}
                            onChange={e => {
                                const v = parseInt(e.target.value)
                                setLimit(isNaN(v) ? 1 : v)
                            }}
                        />
                    </div>
                </div>
                <div className="form-control">
                    <Typography className="label">Pengurangan</Typography>
                    <div className="flex">
                        <Select
                            value={type}
                            onChange={e => setType(e)}
                            disabled={loading}
                            size='small'
                        >
                            <Select.Option value='PERCENTAGE'>Persen</Select.Option>
                            <Select.Option value='AMOUNT'>Rupiah</Select.Option>
                        </Select>
                        <Input 
                            size='small'
                            disabled={loading}
                            value={decreaseValue}
                            onChange={e => {
                                const v = parseInt(e.target.value)
                                setDecreaseValue(isNaN(v) ? 1 : v)
                            }}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    )
}