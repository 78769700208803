import HomeContainer from "../../element/HomeContainer";
import Helmet from "react-helmet";
import conf from "../../conf";
import './index.scss'
import {useEffect, useState} from "react";
import shoppingIlustration from 'dist/image/shopping.png'
import {Alert, Button, Card, Divider, Input, Typography} from "antd";
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import googleIcon from 'dist/icon/company/google.png'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {useNavigate} from "react-router";
import {Link} from "react-router-dom";
import request from "../../utils/request";
import SuccessAlert from './SuccessAlert'

export default function Register() {
    const [height, setHeight] = useState(window.innerHeight)
    const [width, setWidth] = useState(window.innerWidth)
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [hash, setHash] = useState(null)
    const [successAlertOpen, setSuccessAlertOpen] = useState(false)

    const navigate = useNavigate()

    const handleResize = () => {
        setHeight(window.innerHeight)
        setWidth(window.innerWidth)
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize)
        setHash(null)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, []);

    const handleBack = () => navigate(-1)

    const register = () => {
        setLoading(true)
        setError(null)
        var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        try {
            if(!emailRegex.test(email)) {
                throw new Error('Email tidak valid')
            }
            if(password !== confirmPassword) { throw new Error('Password tidak sama') }
            request('/session/register', 'POST', { email, password, name }).then(data => {
                setError(null)
                setHash(data.hash)
                setSuccessAlertOpen(true)
            }).catch(err => {
                setError(err.message || err)
            }).finally(() => setLoading(false))
        } catch(err) {
            setError(err.message || err)
            setLoading(false)
        }
    }


    return (
        <HomeContainer name='register'>
            <SuccessAlert
                open={successAlertOpen}
                onClose={() => setSuccessAlertOpen(false)}
                hash={hash}
            />
            <Helmet>
                <title>{conf.appName} - Register</title>
            </Helmet>
            <div className={'container'} style={{
                height: `${height - 60}px`
            }}>
                <div className="inside-container">
                    {width >= 860 && (
                        <div className="left">
                            <img src={shoppingIlustration} alt="Register" className="ilustration"/>
                            <Typography className='title'>Semua Murah dan Ada di {conf.appName}</Typography>
                            <Typography className='desc'>Pusat Belanja Online Terlengkap di Indonesia</Typography>
                        </div>
                    )}
                    <Card className='register-card'>
                        <Button
                            icon={<ArrowBackIcon/>}
                            type={'text'}
                            onClick={handleBack}
                        />
                        <Typography className='title'>DAFTAR</Typography>
                        <div className="forms">
                            {error && (<Alert
                                type='error'
                                message={error}
                                showIcon
                            />)}
                            <Input
                                size='large'
                                placeholder='Nama'
                                value={name}
                                onChange={e => setName(e.target.value)}
                                className='form-control'
                                prefix={<AccountCircleOutlinedIcon className='icon' />}
                                disabled={loading}
                            />
                            <Input
                                size='large'
                                placeholder='Alamat Email'
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                className='form-control'
                                prefix={<EmailOutlinedIcon className='icon' />}
                                disabled={loading}
                            />
                            <Input.Password
                                size='large'
                                placeholder='Kata Sandi'
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                                className='form-control'
                                prefix={<LockOutlinedIcon className='icon' />}
                                disabled={loading}
                            />
                            <Input.Password
                                size='large'
                                placeholder='Konfirmasi Kata Sandi'
                                value={confirmPassword}
                                onChange={e => setConfirmPassword(e.target.value)}
                                className='form-control'
                                prefix={<LockOutlinedIcon  className='icon' />}
                                disabled={loading}
                            />
                            <Button
                                size={'large'}
                                type={'primary'}
                                loading={loading}
                                onClick={register}
                            >Daftar</Button>
                            <Divider>atau lanjutkan dengan</Divider>
                            <div className="another-methods">
                                <Button className={'method-btn'}
                                    size={'large'}
                                    loading={loading}
                                    onClick={() => {
                                        window.location.href=`${conf.backendUrl}/auth/google`
                                    }}
                                >
                                    {!loading && (
                                        <div className="method">
                                            <img src={googleIcon} alt="Google" className="icon"/>
                                            <div className="text">Google</div>
                                        </div>
                                    )}
                                </Button>
                            </div>
                            <Typography className={'login-text'}>
                                Sudah punya akun? <Link to={'/?showLogin=1'}>Login disini</Link>
                            </Typography>
                        </div>
                    </Card>
                </div>
            </div>
        </HomeContainer>
    )
}