import { useEffect, useRef, useState } from "react";
import { Empty, Spin, notification } from 'antd'
import { useLocation, useNavigate } from "react-router";
import NotificationItem from './NotificationItem'
import InfiniteScroll from "react-infinite-scroller";
import request from "utils/request";

export default function NotificationDialog() {
    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        const url = new URLSearchParams(window.location.search)
        if(url.has('resetNotificationRead')) {
            url.delete('resetNotificationRead')
            navigate(window.location.pathname + "?" + url.toString())
            let x = [...notifications].map(x => {
                return {
                    ...x,
                    read: true
                }
            })
            setNotifications(x)
        }
    }, [location])

    const [loading, setLoading] = useState(false)
    const [notifications, setNotifications] = useState([])
    const [open, setOpen] = useState(false)
    const [page, setPage] = useState(1)
    const [hasMore, setHasMore] = useState(false)

    const scroller = useRef()

    const fetchNotifications = () => {
        setLoading(true)
        request(`/notification/get?page=${page}`).then(data => {
            setPage(x => x + 1)
            setHasMore(data.length > 0)
            let x = [...notifications]
            for (const item of data) {
                const i = x.findIndex(x => x.id === item.id)
                if (i < 0) {
                    x.push(item)
                }
            }
            setNotifications([...notifications, ...data])
        }).catch(err => console.error(`Failed for get the notification: ${err.message || err}`))
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        setPage(1) 
        if (open) {
            setHasMore(true)
            fetchNotifications()
        } else {
            setLoading(false)
            setNotifications([])
        }
    }, [open]);

    useEffect(() => {
        const search = new URLSearchParams(location.search)
        setOpen(search.has('showNotification') && search.get('showNotification') == 1)
    }, [location]);

    return (
        <div className={'notification-dialog-content'} ref={scroller}>
            {/* {loading && (<Spin className={'loading-spinner'} size={'large'} />)} */}
            {notifications.length < 1 && (
                <Empty
                    className="empty-container"
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description='Tidak ada notifikasi'
                />
            )}
            {notifications.length > 0 && (
                <div className="notifications" >
                    <InfiniteScroll
                        pageStart={1}
                        hasMore={hasMore}
                        loadMore={() => {
                            if (!loading) {
                                fetchNotifications()
                            }
                        }}
                        useWindow={false}
                        getScrollParent={() => scroller.current}
                    >
                        {notifications.map((notification, i) => (
                            <NotificationItem key={i} data={notification} />
                        ))}
                    </InfiniteScroll>
                </div>
            )}
        </div>
    )
}