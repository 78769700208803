import { Image, Modal } from "antd";
import conf from "conf";

export default function EvidenceTransfer({open, onClose, order}) {
    return (
        <Modal
            title='Bukti Transfer'
            width={500}
            open={open}
            onCancel={onClose}
            cancelText='Tutup'
            okButtonProps={{
                style: {
                    display: 'none'
                }
            }}
            centered
        >
            <Image 
                src={`${conf.backendUrl}/file/${order.paymentImage}`}
                style={{
                    width: '100%'
                }}
            />
        </Modal>
    )
}