import {Badge, Button, Popover, Typography} from "antd";
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import {useEffect, useRef, useState} from "react";
import ringtone from 'dist/sound/ringtone-notification.mp3'
import { useGlobalAudioPlayer } from 'react-use-audio-player'
import {useLocation, useNavigate} from "react-router";
import request from "../../utils/request";
import NotificationDialog from "./NotificationDialog";

let refreshInterval

export default function Notification() {
    const [open, setOpen] = useState(false)
    const { load } = useGlobalAudioPlayer()
    const location = useLocation()
    const [unreadCount, setUnreadCount] = useState(0)
    const lastUnreadCount = useRef(0)
    const [refreshed, setRefreshed] = useState(false)
    const [markAllAsReadLoading, setMarkAllAsReadLoading] = useState(false)
    const navigate = useNavigate()

    const markAllAsRead = () => {
        const url = new URLSearchParams(window.location.search)
        url.set('resetNotificationRead', '1')
        navigate(window.location.pathname + "?" + url.toString())
        setMarkAllAsReadLoading(true)
        request('/notification/markAllAsRead', 'POST').then(data => {
            refreshUnreadCount()
        })
        .catch(err => console.error(err.message || err))
        .finally(() => setMarkAllAsReadLoading(false))
    }

    useEffect(() => {
        const search = new URLSearchParams(location.search)
        if(open) {
            if(!search.has('showNotification')) { search.set('showNotification', '1') }
        } else {
            if(search.has('showNotification')) { search.delete('showNotification') }
        }
        navigate(window.location.pathname + "?" + search.toString())
    }, [open]);

    const refreshUnreadCount = () => request('/notification/unreadCount').then(data => {
        setUnreadCount(data)

        if(refreshed && lastUnreadCount.current < data) {
            load(ringtone, {
                autoplay: true
            })
        }

        lastUnreadCount.current = data
        setRefreshed(true)
    }).catch(err => console.error(`Failed for read the notification unread data: ${err.message || err}`))

    useEffect(() => {
        refreshUnreadCount()
        if(refreshInterval) { clearInterval(refreshInterval) }
        refreshInterval = setInterval(refreshInterval, 10000)
        return () => clearInterval(refreshInterval)
    }, [location]);


    return (
        <Popover
            trigger={'click'}
            content={<NotificationDialog
                open={open}
                onClose={() => setOpen(false)}
            />}
            open={open}
            onOpenChange={x => setOpen(x)}
            placement={'topRight'}
            arrow={false}
            title={(
                <div className="notification-title">
                    <Typography>Notifikasi</Typography>
                    <div className="right-side">
                        <Button type='link' className="link"
                            disabled={markAllAsReadLoading}
                            onClick={markAllAsRead}
                        >Tandai semua sudah terbaca</Button>
                    </div>
                </div>
            )}
        >
            <Badge
                count={unreadCount}
                placement={'start'}
                offset={[0, 2]}
            >
                <Button icon={<NotificationsNoneOutlinedIcon className={'icon'} />}
                        size={'small'}
                        type={'text'}
                        className={'notification-toggle-btn'}
                />
            </Badge>
        </Popover>
    )
}