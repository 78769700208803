import { Alert, Button, Card, Divider, Flex, Image, Input, Radio, Typography } from "antd";
import conf from "conf";
import { useEffect, useRef, useState } from "react";
import isValidAliexUrl from "utils/isValidAliexUrl";
import getItemIdFromAliExpressURL from "utils/getItemIdFromAliexUrl";
import request from "utils/request";
import { useLocation, useNavigate } from "react-router";
import { decode } from "urlencode"; 

export default function OrderAutoForm({ onItemId, onQuantity, onImages, onProductName, onProps }) {
    const [url, setUrl] = useState('')
    const [validUrl, setValidUrl] = useState(false)
    const [loading, setLoading] = useState(false)
    const [product, setProduct] = useState(null)
    const [error, setError] = useState(false)
    const [itemId, setItemId] = useState(null)
    const [quantity, setQuantity] = useState(1)
    const [props, setProps] = useState([])

    const location = useLocation()
    const navigate = useNavigate()

    const btnCheckRef = useRef()
    
    useEffect(() => {
        const search = new URLSearchParams(location.search)
        if(search.has('prodUrl')) {
            const url = decode(search.get('prodUrl')).split('?')[0]
            setUrl(url)
            if(isValidAliexUrl(url)) {
                setTimeout(() => {
                    btnCheckRef.current.click()
                }, 1000)
            }
            search.delete('prodUrl')
            navigate('/new-order?'+search.toString())
        }
    }, [location])

    useEffect(() => {
        onImages(product ? product.images : [])
        onQuantity(quantity)
        onProductName(product ? product.name : '')
        onProps(props)
    }, [quantity, product, props])

    useEffect(() => {
        if (url.includes('?')) { setUrl(url.split('?')[0]) }
        setValidUrl(isValidAliexUrl(url))
    }, [url])

    useEffect(() => {
        setItemId(validUrl ? getItemIdFromAliExpressURL(url) : null)
    }, [validUrl])

    useEffect(() => {
        onItemId(itemId)
    }, [itemId])

    const check = () => {
        setProps([])
        setQuantity(1)
        setLoading(true)
        setError(false)
        setProduct(null)
        const url = `/product/get/${itemId}?type=ALIEXPRESS`
        request(url).then(data => {
            setError(false)
            setProduct(data)
            setProps(data.properties.map((prop) => {
                return {
                    name: prop.name,
                    value: prop.values[0]
                }
            }))
        }).catch(err => {
            setError(err.message || err)
        }).finally(() => {
            setLoading(false)
        })
    }

    return (
        <>
            {error && (
                <Alert
                    type='error'
                    showIcon
                    message={error}
                    style={{ marginBottom: 10, marginTop: 15 }}
                />
            )}
            <Card className="order-auto-form" size='small'>
                <div className="form-control">
                    <Typography className="label">Url AliExpress</Typography>
                    <Input
                        className="url-input form-field"
                        value={url}
                        onChange={e => setUrl(e.target.value)}
                        placeholder="https://www.aliexpress.com/item/***.html"
                        status={validUrl ? undefined : 'error'}
                        suffix={(
                            <Button type='primary'
                                disabled={!validUrl}
                                onClick={check}
                                ref={btnCheckRef}
                                loading={loading}
                            >Check</Button>
                        )}
                        onKeyUp={e => {
                            if(e.keyCode === 13) {
                                btnCheckRef.current.click()
                            }
                        }}
                    />
                    {!validUrl && (
                        <Typography className="error-field">URL tidak valid</Typography>
                    )}
                </div>
            </Card>
            {product !== null && (
                <Card className="order-auto-product-detail">
                    <div className="product-detail">
                        <div className="left">
                            {product.images[0] && (
                                <Flex className="images-fluid" wrap='wrap' gap={8}>
                                    {product.images.map((image, i) => (
                                        <Image
                                            src={image}
                                            alt={product.name}
                                            className="product-image"
                                            title={product.name}
                                            key={i}
                                        />
                                    ))}
                                </Flex>
                            )}
                        </div>
                        <div className="right">
                            <Typography className="product-name">{product.name}</Typography>
                            <Divider className="divider" />
                            <div className="form-control quantity" style={{
                                width: 70
                            }}>
                                <Typography className="label">Quantity</Typography>
                                <Input
                                    className="input-field"
                                    value={quantity}
                                    type='number'
                                    onChange={e => setQuantity(e.target.value)}
                                />
                            </div>
                            <div className="form-control props">
                                {product.properties.map((property, _) => (
                                    <div className="control-item" key={_}>
                                        <div className="label">{property.name}: </div>
                                        <div className="control">
                                            <Radio.Group disabled={loading} value={props[_].value} optionType='default' onChange={e => {
                                                let x = [...props]
                                                x[_].value = e.target.value
                                                setProps(x)
                                            }}>
                                                {property.values.map((value, _) => (
                                                    <Radio value={value} key={_}>{value}</Radio>
                                                ))}
                                            </Radio.Group>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </Card>
            )}
        </>
    )
}