import { Button, Card, Divider, Flex, Image, Tag } from "antd";
import Typography from "antd/es/typography/Typography";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import moment from 'moment-timezone'
import 'moment/locale/id'
import CopyToClipboard from "react-copy-to-clipboard";
import { useState } from "react";
import CopiedAlert from './CopiedAlert'
import formatRupiah from "utils/formatRupiah";
import { useNavigate } from "react-router";
import AdminWorkStatus from "./AdminWorkStatus";
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import AdminNote from './AdminNote'
import urlify from "utils/urlify";
import Action from './Action/index'
import ShippingCapitalInput from "./ShippingCapitalInput";
import EvidenceTransfer from "./EvidenceTransfer";
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import MakeConversation from "./MakeConversation";
import FinishDialog from "./FinishDialog";
import TakeoutDiningIcon from '@mui/icons-material/TakeoutDining';

const canFinishStatus = ['shipping']

export default function OrderItem({ data, role, roles, onUpdate, isPrivate }) {
    const [copiedOpen, setCopiedOpen] = useState(false)
    const [adminNoteOpen, setAdminNoteOpen] = useState(false)
    const [evidenceOfTransferOpen, setEvidenceOfTransferOpen] = useState(false)
    const [capital, setCapital] = useState(0)
    const [selesaiOrderId, setSelesaiOrderId] = useState(null)

    const navigate = useNavigate()

    return (
        <Card className="order-item" data-id={data.orderId}
        >
            <FinishDialog 
                orderId={selesaiOrderId}
                onClose={() => setSelesaiOrderId(null)}
                onUpdate={onUpdate}
            />
            <EvidenceTransfer
                order={data}
                open={evidenceOfTransferOpen}
                onClose={() => setEvidenceOfTransferOpen(false)}
            />
            <AdminNote
                open={adminNoteOpen}
                onClose={() => setAdminNoteOpen(false)}
                note={data.adminNote ? data.adminNote : ''}
                onChanged={onUpdate}
                orderId={data.orderId}
            />
            <CopiedAlert
                open={copiedOpen}
                onClose={() => setCopiedOpen(false)}
            />
            <div className="order-header">
                <div className="left">
                    <Typography className="order-id">
                        <span>{data.orderId}</span>
                        <CopyToClipboard text={data.orderId} onCopy={() => setCopiedOpen(true)}>
                            <ContentCopyIcon className="copy-btn" />
                        </CopyToClipboard>
                    </Typography>
                    {/* The date of order created */}
                    <div className="created-date">
                        {moment(data.created * 1000).tz('Asia/Jakarta').format('dddd, DD MMMM YYYY HH:mm')}
                    </div>
                </div>

                <div className="right">
                    <MakeConversation
                        orderId={data.orderId}
                    />
                    <Divider
                        type='vertical'
                        className="divider divider-test"
                        style={{
                            height: '30px'
                        }}
                    />
                    {data.lastTrack && (
                        <>
                            <div className="last-track">
                                <LocalShippingOutlinedIcon className="icon" />
                                <Typography className="text">{data.lastTrack}</Typography>
                            </div>
                            <Divider
                                type='vertical'
                                className="divider divider-test"
                                style={{
                                    height: '30px'
                                }}
                            />
                        </>
                    )}
                    <div className="process-time" data-diff={data.processTime.diff} style={{
                        background: data.processTime.background,
                        color: data.processTime.color
                    }}>
                        {data.processTime.str}
                    </div>
                    <div className="status" style={{
                        color: data.status.color,
                        backgroundColor: data.status.background
                    }}>
                        <div className="text">{data.status.name}</div>
                    </div>
                    <Action
                        orderId={data.orderId}
                        roles={roles}
                        onReload={onUpdate}
                        order={data}
                        modal={capital}
                        isPrivate={isPrivate}
                    />
                </div>
            </div>
            {(roles.includes('admin1') || roles.includes('admin2') || roles.includes('admin3')) && (
                <div className="order-detailed-row">
                    <AdminWorkStatus order={data} />
                    <div className="right">
                        <div className="detail">
                            <AccountCircleOutlinedIcon className="icon" />
                            <div className="info">
                                <Typography className="title" style={{
                                    whiteSpace: 'nowrap'
                                }}>{data.user.name}</Typography>
                                <Typography className="subtitle" style={{
                                    whiteSpace: 'nowrap'
                                }}>{data.user.email}</Typography>
                            </div>
                        </div>
                        <Divider type='vertical' style={{
                            minHeight: '60px'
                        }} className="divider" />
                        <div className="detail">
                            <LocationOnOutlinedIcon className="icon" />
                            <div className="info">
                                <Typography className="subtitle">{data.targetAddress.province}/{data.targetAddress.regency}/{data.targetAddress.district}/{data.targetAddress.village}</Typography>
                                {data.additionalAddress && (
                                    <Typography className="subtitle-small" style={{
                                        marginTop: '10px'
                                    }}>{data.additionalAddress}</Typography>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div className="order-body">
                <div className="product"

                >
                    {data.product.images.length > 0 && (
                        <Image
                            src={data.product.images[0]}
                            width={80}
                            height={80}
                            alt={data.product.name}
                            title={data.product.name}
                            className="product-image"
                            preview={false}
                        />
                    )}
                    <div className="text-information">
                        <Typography className="product-name"
                            onClick={() => window.open(data.url, '_blank')}
                        >{data.product.name}</Typography>
                        <div className="quantity-and-unit-price">{data.quantity} pcs {data.unitPrice > 0 ? `x ${formatRupiah(data.unitPrice)}` : ''}</div>
                        {data.unitPrice > 0 && (
                            <div className="goods-price">{formatRupiah(data.unitPrice * data.quantity)}</div>
                        )}

                        {(roles.includes('admin1') || roles.includes('admin2') || roles.includes('admin3')) && data.note && (
                            <div className="note"
                                dangerouslySetInnerHTML={{ __html: urlify(data.note) }}
                            ></div>
                        )}
                        {data.props.length > 0 && (
                            <Flex className="props" wrap='wrap' style={{
                                marginTop: '10px'
                            }}>
                                {data.props.map((prop, i) => (
                                    <Tag key={i}>{prop.name}: {prop.value}</Tag>
                                ))}
                            </Flex>
                        )}
                    </div>
                </div>
            </div>
            <Divider />
            <div className="order-footer">
                <div className="footer-left-side">
                    <ShippingCapitalInput
                        roles={roles}
                        orderId={data.orderId}
                        currentCapitalPrice={data.capitalPrice}
                        currentShippingPrice={data.shippingPrice}
                        order={data}
                        onCapital={cap => setCapital(cap)}
                    />

                    {(roles.includes('admin1') || roles.includes('admin2') || roles.includes('admin3')) && (
                        <div className="admin-note"
                            onClick={() => setAdminNoteOpen(true)}
                        >
                            <CreateOutlinedIcon className="icon" />
                            <Typography className="text" dangerouslySetInnerHTML={{ __html: `Catatan Admin: ${!data.adminNote ? '-' : urlify(data.adminNote)}` }} />
                        </div>
                    
                    )}
                        <Typography className="repacking-container">
                            <TakeoutDiningIcon className="icon" />
                            <div className="text">Repacking: {data.repacking ? 'Ya' : 'Tidak'}</div>
                        </Typography>
                </div>
                <div className="footer-right-side">
                    {data.priceToPay && (
                        <div className="total-product">Total Product: <div className="amount">{formatRupiah(data.priceToPay)}</div></div>
                    )}
                    {(roles.includes('admin1') || roles.includes('admin2') || roles.includes('admin3')) && data.paymentImage && (
                        <Button type='link'
                            size='small'
                            style={{
                                marginBottom: '10px'
                            }}
                            onClick={() => setEvidenceOfTransferOpen(true)}
                        >Lihat Bukti Transfer</Button>
                    )}
                    <div className="actions">
                        <Button
                            size='large'
                            type='primary'
                            className="detail-btn"
                            onClick={() => navigate('/order-detail?code=' + data.orderId)}
                        >Detail Pesanan</Button>
                        {isPrivate && canFinishStatus.includes(data.status.key) && (
                            <Button
                                size='large'
                                type='primary'
                                className="finish-btn"
                                onClick={() => setSelesaiOrderId(data.orderId)}
                            >Selesai</Button>
                        )}
                    </div>
                </div>
            </div>
        </Card>
    )
}