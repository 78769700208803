/**
 * Checks if the given URL is a valid AliExpress URL.
 *
 * @param {string} url - The URL to be checked.
 * @return {boolean} Returns true if the URL is a valid AliExpress URL, otherwise false.
 */
function isValidAliexUrl(url) {
    // Format URL regex pattern
    var pattern = /^(?:https?:\/\/)?(?:\S+\.)?aliexpress\.com\/item\/\d+(\.html)?$/i;

    // Check if URL matches the pattern
    return pattern.test(url);
}

export default isValidAliexUrl
