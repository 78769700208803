import conf from "conf";
import MainAppContainer from "element/MainAppContainer";
import { Helmet } from "react-helmet";
import './index.scss'
import { Button, Divider, Empty, Typography } from "antd";
import { useEffect, useState } from "react";
import AddressItem from './AddressItem'
import { RequestQuoteOutlined } from "@mui/icons-material";
import request from "utils/request";
import AddAddressModal from "element/AddAddressModal";

export default function Address() {

    const [loading, setLoading] = useState(false)
    const [addresses, setAddresses] = useState([])
    const [addOpen, setAddOpen] = useState(false)

    const fetchAddresses = () => {
        setLoading(true)
        request('/user/addresses').then(data => {
            setAddresses(data)
        }).catch(err => {
            console.error(`Failed for load addresses: ${err.message || err}`)
        }).finally(() => {
            setLoading(false)
        })
    }

    useEffect(() => {
        fetchAddresses()
    }, [])

    return (
        <MainAppContainer name='address'>
            <AddAddressModal 
                open={addOpen}
                onClose={() => setAddOpen(false)}
                onAdded={fetchAddresses}            
            />
            <Helmet>
                <title>{conf.appName} - Alamat Saya</title>
            </Helmet>
            <div className="address-header">
                <Typography className="title">Alamat Saya</Typography>
                <div className="right-side">
                    <Button
                        danger
                        type='text'
                        className="add-address-btn"
                        onClick={() => setAddOpen(true)}
                    >Tambah Alamat</Button>
                </div>
            </div>
            <Divider className="divider" />
            {loading && <Typography color="error">Memuat ...</Typography>}
            {!loading && addresses.length < 1 && (
                <Empty 
                    description='Tidak ada alamat'
                    className="empty-container"
                />
            )}
            {!loading && addresses.length > 0 && (
                <div className="addresses">
                    {addresses.map((address, i) => (
                        <AddressItem 
                            key={i}
                            data={address}
                            onDelete={fetchAddresses}
                        />
                    ))}
                </div>
            )}
        </MainAppContainer>
    )
}