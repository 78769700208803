import { Alert, Modal, Typography } from "antd";
import { useEffect, useState } from "react";
import request from "utils/request";

export default function CancelDialog({orderId, open, onClose, onChanged}) {

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)

    const cancel = () => {
        setLoading(true)
        setError(false)
        request('/order/markAsDone', 'POST', {
            orderId,
            status: 'cancel'
        }).then(() => {
            onChanged()
            onClose()
            setError(false)
            window.location.reload()
        }).catch(err => {
            setError(err.message || err)
        }).finally(() => {
            setLoading(false)
        })
    }

    useEffect(() => {
        setError(false)
    }, [open])

    return (
        <Modal
            title='Batalkan'
            centered
            closable={!loading}
            cancelButtonProps={{
                disabled: loading
            }}
            okButtonProps={{
                loading
            }}
            width={350}
            open={open}
            onCancel={onClose}
            cancelText='Tutup'
            okText='Batalkan'
            onOk={cancel}
        >
            {error && (
                <Alert 
                    type='error'
                    showIcon
                    message={error}
                    style={{
                        marginBottom: '15px'
                    }}
                />
            )}
            <Typography>Anda yakin ingin membatalkan pesanan ini ?</Typography>
        </Modal>
    )
}