import { Modal, Typography } from "antd";

export default function SuccessAlert({open, onClose}) {
    return (
        <Modal
            title='Berhasil'
            centered
            width={350}
            open={open}
            onCancel={onClose}
            onOk={onClose}
            cancelText='Tutup'
        >
            <Typography>Profil Anda telah berhasil diubah</Typography>
        </Modal>
    )
}